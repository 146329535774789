import { isBoolean, isBooleanOrNull, isNull, isNumber, isNumberOrNull, isString, isStringOrNull } from '@evo/utils/types';
import { DivisionShortcut, DivisionShortcutStruct, Person, PersonStruct } from '@auth/models';
import { FlightType, FlightTypeStruct, DestinationType, DestinationTypeStruct } from '../../common';
import { LocationStruct, Location } from '@geodata/models';
import { Platform, PlatformStruct } from '@auth/models';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface SearchStruct {
  uuid: string;
  date_created: string;
  is_selected: boolean;
  offers_count: number;
  volume: number;

  user?: PersonStruct;
  division?: DivisionShortcutStruct;
  platform?: PlatformStruct;

  location?: LocationStruct;
  flight_type?: FlightTypeStruct;
  destination_type?: DestinationTypeStruct;

  location_id?: string;
  flight_type_id?: string;
  destination_type_id?: string;
}

export class Search extends Model<SearchStruct> {
  constructor (
    public uuid: string,
    public dateCreated: Moment,
    public isSelected: boolean,
    public offersCount: number,
    public volume: number,
    public location: Location,
    public flightType: FlightType,
    public destinationType: DestinationType,
    public user: Person,
    public division: DivisionShortcut,
    public platform: Platform
  ) {
    super();
  }

  public static create(data: SearchStruct) {
    return new Search(
      data.uuid || null,
      data.date_created ? moment(data.date_created) : null,
      isBoolean(data.is_selected) ? data.is_selected : null,
      data.offers_count || 0,
      isNumber(data.volume) ? data.volume : null,
      data.location ? Location.create(data.location) : null,
      data.flight_type ? FlightType.create(data.flight_type) : null,
      data.destination_type ? DestinationType.create(data.destination_type) : null,
      data.user ? Person.create(data.user) : null,
      data.division ? DivisionShortcut.create(data.division) : null,
      data.platform ? Platform.create(data.platform) : null
    );
  }

  public override toString(): string {
    return `${this.location.code} - ${this.offersCount}`;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
      is_selected: this.isSelected,
      offers_count: this.offersCount,
      volume: this.volume,
      location_id: this.location ? this.location.pk : null,
      flight_type_id: this.flightType ? this.flightType.pk : null,
      destination_type_id: this.destinationType ? this.destinationType.pk : null
    };
  }

  public update(data: SearchStruct) {
    this.uuid = isStringOrNull(data.uuid) ? data.uuid : this.uuid;
    this.dateCreated = isString(data.date_created) ? moment(data.date_created) : isNull(data.date_created) ? null : this.dateCreated;
    this.isSelected = isBooleanOrNull(data.is_selected) ? data.is_selected : this.isSelected;
    this.offersCount = isNumberOrNull(data.offers_count) ? data.offers_count : this.offersCount;
    this.volume = isNumberOrNull(data.volume) ? data.volume : this.volume;
    this.location = data.location ? Location.create(data.location) : this.location;
    this.flightType = data.flight_type ? FlightType.create(data.flight_type) : this.flightType;
    this.destinationType = data.destination_type ? DestinationType.create(data.destination_type) : this.destinationType;
    this.user = data.user ? Person.create(data.user) : this.user;
    this.division = data.division ? DivisionShortcut.create(data.division) : this.division;
    this.platform = data.platform ? Platform.create(data.platform) : this.platform;
    return this;
  }
}
