import { Model } from '@evo/models';

export interface TimezoneStruct {
  uuid: number;
  name: string;
}

export class Timezone extends Model<TimezoneStruct> {
  constructor (
    public uuid: number,
    public name: string,
  ) {
    super();
  }

  public static create(data: TimezoneStruct) {
    if (!data) {
      data = {} as TimezoneStruct;
    }
    return new Timezone(
      data.uuid || undefined,
      data.name || '',
    );
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      name: this.name,
    };
  }

  public update(data: TimezoneStruct) {
    this.uuid = data.uuid || this.uuid;
    this.name = data.name || this.name;
    return this;
  }
}
