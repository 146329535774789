import { environment } from '@environments/environment';
import { Batch, BatchStruct } from '@fuel/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OrderBatchesService {

  constructor (
    private http: HttpClient
  ) {
  }

  public cart() {
    return this.http.get(`${environment.URL.API}/fuel/admin/batches/cart/`).pipe(
      map((resp: BatchStruct) => {
        return Batch.create(resp);
      })
    );
  }
}
