import { Model } from '@evo/models';
import { Stats, StatsStruct } from '..';

export interface ClientStatsStruct {
  orders: StatsStruct;
  invoices: StatsStruct;
  conversion: StatsStruct;
  volume: StatsStruct;
  total: StatsStruct;
  rebate: StatsStruct;
}

export class ClientStats extends Model<ClientStatsStruct> {
  constructor (
    public orders: Stats,
    public invoices: Stats,
    public conversion: Stats,
    public volume: Stats,
    public total: Stats,
    public rebate: Stats
  ) {
    super();
  }

  public static create(data?: ClientStatsStruct) {
    if (!data) {
      data = {} as ClientStatsStruct;
    }
    return new ClientStats(
      data.orders ? Stats.create(data.orders) : null,
      data.invoices ? Stats.create(data.invoices) : null,
      data.conversion ? Stats.create(data.conversion) : null,
      data.volume ? Stats.create(data.volume) : null,
      data.total ? Stats.create(data.total) : null,
      data.rebate ? Stats.create(data.rebate) : null
    );
  }

  public toJSON(): ClientStatsStruct {
    return {
      orders: this.orders ? this.orders.toJSON() : null,
      invoices: this.invoices ? this.invoices.toJSON() : null,
      conversion: this.conversion ? this.conversion.toJSON() : null,
      volume: this.volume ? this.volume.toJSON() : null,
      total: this.total ? this.total.toJSON() : null,
      rebate: this.rebate ? this.rebate.toJSON() : null
    };
  }

  public update(data: any): this {
    return this;
  }
}
