import { environment } from '@environments/environment';
import { IPAgentStruct, IPAgent } from '@fuel/models';
import { toPrimaryKey } from '@evo/utils/helpers';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DivisionIpAgentsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public get(ipagent: IPAgent | string) {
    let uuid = toPrimaryKey(ipagent);
    return this.http.get(`${environment.URL.API}/fuel/ip-agents/${uuid}/`).pipe(
      map((resp: IPAgentStruct) => {
        return IPAgent.create(resp);
      }),
    );
  }

  public search(term: string) {
    let params = { term };
    return this.http.get(`${environment.URL.API}/fuel/ip-agents/search/`, { params }).pipe(
      map((resp: IPAgentStruct[]) => {
        return resp.map((a) => IPAgent.create(a));
      }),
    );
  }
}
