import { AfterViewInit, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { uniqueName } from '@evo/utils/helpers';

@Directive({
  selector: '[random-name]',
})
export class RandomNameDirective implements OnInit, AfterViewInit {

  public name: string;

  constructor (
    private ref: ElementRef,
    private renderer: Renderer2
  ) {
  }

  public ngOnInit() {
    this.name = uniqueName();
  }

  public ngAfterViewInit() {
    this.renderer.setAttribute(this.ref.nativeElement, 'name', this.name);
  }
}
