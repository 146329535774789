import { Model } from '@evo/models';
import { isString } from '@evo/utils/types';


export interface EmployeeTypeStruct {
  uuid: string;
  name: string;
  code: string;
  description: string;
}

export class EmployeeType extends Model<EmployeeTypeStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public code: string,
    public description: string
  ) {
    super();
  }

  public static create(data?: EmployeeTypeStruct) {
    if (!data) {
      data = {} as EmployeeTypeStruct;
    }
    return new EmployeeType(
      isString(data.uuid) ? data.uuid : null,
      isString(data.name) ? data.name : null,
      isString(data.code) ? data.code : null,
      isString(data.description) ? data.description : null,
    );
  }

  public override toString(): string {
    return this.code;
  }

  public toJSON(): EmployeeTypeStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      code: this.code,
      description: this.description
    }
  }

  public update(data: EmployeeTypeStruct): this {
    return this;
  }

}
