import { Money, MoneyStruct, Count, CountStruct } from '@common/models';
import { DivisionShortcut, DivisionShortcutStruct } from '@auth/models';
import { isNull, isNumberOrNull } from '@evo/utils/types';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface BatchStruct {
  uuid: string;
  name: string;
  date_created: string;
  date_updated: string;
  orders_count: number;
  volume: CountStruct;
  total: MoneyStruct;
  division: DivisionShortcutStruct;
}

export class Batch extends Model<BatchStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public dateCreated: Moment,
    public dateUpdated: Moment,
    public ordersCount: number,
    public volume: Count,
    public total: Money,
    public division: DivisionShortcut
  ) {
    super();
  }

  public static create(data?: BatchStruct) {
    if (!data) {
      data = {} as BatchStruct;
    }
    return new Batch(
      data.uuid || null,
      data.name || null,
      data.date_created ? moment(data.date_created) : null,
      data.date_updated ? moment(data.date_updated) : null,
      data.orders_count || 0,
      data.volume ? Count.create(data.volume) : null,
      data.total ? Money.create(data.total) : null,
      data.division ? DivisionShortcut.create(data.division) : null
    );
  }

  public get code() {
    return this.name ? this.name : this.uuid.slice(0, 8).toUpperCase();
  }

  public override toString(): string {
    return `${this.code}`;
  }

  public toJSON(): BatchStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      volume: this.volume ? this.volume.toJSON() : null,
      total: this.total ? this.total.toJSON() : null,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
      date_updated: this.dateUpdated ? this.dateUpdated.toISOString() : null,
      orders_count: this.ordersCount,
      division: this.division ? this.division.toJSON() : null
    };
  }

  public update(data: BatchStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.name = data.name || this.name;
    this.dateCreated = data.date_updated ? moment(data.date_updated) : this.dateCreated;
    this.dateUpdated = data.date_updated ? moment(data.date_updated) : this.dateUpdated;
    this.ordersCount = isNumberOrNull(data.orders_count) ? data.orders_count : this.ordersCount;
    this.volume = data.volume ? Count.create(data.volume) : isNull(data.volume) ? null : this.volume;
    this.total = data.total ? Money.create(data.total) : isNull(data.total) ? null : this.total;
    this.division = data.division ? DivisionShortcut.create(data.division) : isNull(data.division) ? null : this.division;
    return this;
  }

}
