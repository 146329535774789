import { ValidatorFn, ValidationErrors, Validators as BaseValidators } from '@angular/forms';
import { FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { dateFormat, dateTimeFormat } from '@evo/utils/libs/moment';
import { oneOfFieldsShouldEquals } from './one-field-should-equals';
import { oneOfFieldsIsRequired } from './one-field-is-required';
import { updateControlError } from '../utils';
import { isEmpty } from '@evo/utils/types';
import { phone, usaPhone } from './phone';
import { maxLength } from './max-length';
import { minLength } from './min-length';
import { mandatory } from './mandatory';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { equals } from './equals';
import { Moment } from 'moment';
import { mail } from './mail';
import { uuid } from './uuid';
import { ein } from './ein';
import { max } from './max';

export class Validators extends BaseValidators {

  static override max(value: number, message = 'Should be less than or equals to |max|') {
    message = (message + '').replace('|max|', value.toString());
    return max(value, message);
  }

  static mandatory(message = 'This field is required.') {
    return mandatory(message);
  }

  static mail(message = 'Enter a valid email address.') {
    return mail(message);
  }

  static uuid(message = 'Not valid UUID.') {
    return uuid(message);
  }

  static phone(message = 'Enter valid phone number.') {
    return phone(message);
  }

  static usaPhone(control: AbstractControl) {
    return usaPhone(control);
  }

  static override maxLength(max: number, message = 'Ensure this field has no more than |maxLength| characters.'): ValidatorFn {
    return maxLength(max, message);
  }

  static override minLength(min: number, message = 'Ensure this field has at least |minLength| characters.'): ValidatorFn {
    return minLength(min, message);
  }

  static ein(message = 'Not valid EIN') {
    return ein(message);
  }

  static oneOfFieldsIsRequired(fields: string[], message = 'One of fields is required: |FIELDS|') {
    return oneOfFieldsIsRequired(fields, message);
  }

  static oneOfFieldsShouldEquals(fields: string[], value: Model<any> | any, message = 'One of fields should equals to |VALUE|') {
    return oneOfFieldsShouldEquals(fields, value, message);
  }

  static equals(sourceControlName: string, controlName: string, message?: string) {
    if (!message) {
      message = 'Field "' + controlName + '" is not equal to "' + sourceControlName + '"';
    }
    return equals(sourceControlName, controlName, message);
  }

  static integer(control: AbstractControl) {
    if (!isEmpty(control.value) && !/^\d+$/.test(control.value)) {
      return { integer: 'Must be an integer' };
    }
    return null;
  }

  static decimal(maxDecimal: number, message = 'Invalid decimal value'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmpty(control.value)) {
        return null;
      }
      const regex = new RegExp(`^-?\\d+(|(\\.|,)\\d{1,${maxDecimal}})$`);
      return regex.test(control.value) ? null : { decimal: message };
    };
  }

  static range(from: number, to: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = parseInt(control.value, 10);
      if (value < from) {
        return { range: `Value must be greater then or equals to ${from}` };
      }
      if (value > to) {
        return { range: `Value must be less then or equals to ${to}` };
      }
      return null;
    };
  }

  static arrayRequired(control: FormArray) {
    let keys = control.value.filter((key) => !!key);
    if (!keys.length) {
      return { array: 'You need to select at leas one item' };
    }
    return null;
  }

  static moment(message = 'Invalid date string format.') {
    return (control: AbstractControl) => {
      let value = control.value as Moment;
      if (!(value instanceof moment)) {
        return { date: message };
      }
      return null;
    };
  }

  static checkTime(message = 'Time should be selected') {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value as Moment;
      return !value || (value && value['timeIsValid']) ? null : { time: message };
    };
  }

  static minDate(date: Moment, message = 'Date should be equal to or later than {}') {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value as Moment;
      if (!(value instanceof moment)) {
        return null;
      }
      return date.isSameOrBefore(value) ? null : { mindate: message.replace('{}', dateFormat(date)) };
    };
  }

  static maxDate(date: Moment) {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value as Moment;
      return date.isSameOrAfter(value) ? null : { date: { requiredBefore: date.toISOString() } };
    };
  }

  static gteDate(sourceControlName: string, controlName: string, message = 'Should be later than {}') {
    return (g: FormGroup): ValidationErrors | null => {
      let source = g.get(sourceControlName),
        control = g.get(controlName);

      let svalue = source.value as Moment,
        cvalue = control.value as Moment;

      if (!(svalue instanceof moment) || !(cvalue instanceof moment)) {
        return null;
      }

      let msg = cvalue ? message.replace('{}', dateTimeFormat(cvalue)) : '';

      updateControlError(source, 'gtedate', msg,
        !((!svalue || !svalue.toDate) || (!cvalue || !cvalue.toDate) || svalue.isSameOrAfter(cvalue)),
      );
      return null;
    };
  }

  /**
   * Validate US Social Security Number, last 4 digits also allowed
   */
  static ssn(control: AbstractControl) {
    let ssn = (control.value + ''),
      regex = /^\d{3}-\d{2}-\d{4}$/,
      last_4_digits_regex = /^\d{4}$/;

    if (regex.test(ssn) || last_4_digits_regex.test(ssn)) {
      return null;
    }
    return { ssn: 'Not valid SSN' };
  }

  /**
   * Validate USA zip code,  not all postal codes
   */
  static zipCode(control: AbstractControl) {
    let zip = (control.value + ''),
      regex = /^\d{5}(?:-\d{4})?$/;
    return regex.test(zip) ? null : { zip: 'Not valid zip code' };
  }

  /**
   * Check if string has at least one numeric char
   */
  static oneNumericCharRequired(control: AbstractControl) {
    let value = control.value ? control.value + '' : '';
    return /\d/.test(value) ? null : { requiredNumeric: 'One numeric char is required.' };
  }

  /**
   * Check if string has at least one uppercase char
   */
  static oneUpperCaseCharRequired(control: AbstractControl) {
    let value = control.value ? control.value + '' : '';
    return /[A-ZА-Я]/.test(value) ? null : { requiredUppercase: 'One UPPERCASE char is required.' };
  }

  /**
   * Check if string is valid pathname
   */
  static pathname(control: AbstractControl) {
    let value = control.value ? control.value + '' : '',
      url = new URL(value, window.location.origin);
    return url.pathname == value ? null : { pathname: 'Enter valid url.' };
  }

  /**
   * Check tripNumber (reference)
   */
  static tripNumber(control: AbstractControl) {
    let reference = (control.value + ''),
      regex = /^\d+[a-z]{3,4}$/gi;
    return regex.test(reference) ? null : { tripNumber: 'Not valid trip number.' };
  }
}
