import { Department, DepartmentStruct, EmployeeType, EmployeeTypeStruct } from '@hr/models';
import { Group, GroupStruct } from '../../permissions';
import { Person, PersonStruct } from '../person';
import { Salutation } from '@common/models';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface EmployeeStruct extends PersonStruct {
  group?: GroupStruct;
  group_id?: string;
  department?: DepartmentStruct;
  department_id: string;
  type?: EmployeeTypeStruct;
  type_id?: string;
  date_joined?: string;
  fma?: number;
}

export class Employee extends Person {

  constructor (
    uuid: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    avatar: string,
    isActive: boolean,
    salutation: Salutation,
    public group: Group,
    public department: Department,
    public type: EmployeeType,
    public dateJoined?: Moment,
    public fma?: number
  ) {
    super(uuid, firstName, lastName, email, phone, avatar, isActive, salutation);
  }

  public static override create(data?: Partial<EmployeeStruct>) {

    if (!data) {
      data = {} as any;
    }

    return new Employee(
      data.uuid || null,
      data.first_name || null,
      data.last_name || null,
      data.email || null,
      data.phone || null,
      data.avatar || '',
      'boolean' == typeof data.is_active ? data.is_active : false,
      data.salutation ? Salutation.create(data.salutation) : null,
      data.group ? Group.create(data.group) : null,
      data.department ? Department.create(data.department) : null,
      data.type ? EmployeeType.create(data.type) : null,
      data.date_joined ? moment(data.date_joined) : null,
      data.fma ? data.fma : null
    );
  }

  /**
   * Employee does not need a salutation
   */
  public override getFullName() {
    let firstName = this.firstName || '',
      lastName = this.lastName || '';
    return [firstName, lastName].join(' ');
  }

  public override toString(): string {
    return this.getFullName();
  }

  public override toJSON(): EmployeeStruct {
    return {
      uuid: this.uuid,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      phone: this.phone,
      avatar: this.avatar,
      is_active: this.isActive,
      salutation_id: this.salutation ? this.salutation.uuid : null,
      department_id: this.department ? this.department.uuid : null,
      type_id: this.type ? this.type.uuid : null,
      group_id: this.group ? this.group.uuid : null,
    };
  }

  public override update(data: EmployeeStruct): this {
    super.update(data);
    this.group = data.group ? Group.create(data.group) : this.group;
    this.department = data.department ? Department.create(data.department) : this.department;
    this.type = data.type ? EmployeeType.create(data.type) : this.type;
    this.dateJoined = data.date_joined ? moment(data.date_joined) : this.dateJoined;
    this.fma = data.fma ? data.fma : this.fma;
    return this;
  }

}
