export enum INVOICE_PERMISSIONS {
  CREATE = 'accounting.add_invoice',
  UPDATE = 'accounting.change_invoice',
  DELETE = 'accounting.delete_invoice',
  VIEW = 'accounting.view_invoice'
}

export enum SALES_INVOICE_PERMISSIONS {
  CREATE = 'accounting.add_sales_invoice',
  UPDATE = 'accounting.change_sales_invoice',
  DELETE = 'accounting.delete_sales_invoice',
  VIEW = 'accounting.view_sales_invoice'
}

export enum BANK_PERMISSIONS {
  CREATE = 'accounting.add_bank',
  UPDATE = 'accounting.change_bank',
  DELETE = 'accounting.delete_bank',
  VIEW = 'accounting.view_bank'
}

export enum BANK_ACCOUNT_PERMISSIONS {
  CREATE = 'accounting.add_bankaccount',
  UPDATE = 'accounting.change_bankaccount',
  DELETE = 'accounting.delete_bankaccount',
  VIEW = 'accounting.view_bankaccount'
}
