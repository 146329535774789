import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'numeric',
})
export class NumericPipe implements PipeTransform {

  public transform(value: number): number | null {
    return null;
  }
}
