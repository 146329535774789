import { environment } from '@environments/environment';
import { SentryErrorHandler, init, getClient, captureException, showReportDialog, httpClientIntegration } from '@sentry/angular';
import { Injectable, ErrorHandler, NgZone, FactoryProvider } from '@angular/core';

const BROWSER_OPTIONS = environment.SENTRY.BROWSER_OPTIONS;

init({
  integrations: [
    httpClientIntegration({ failedRequestStatusCodes: [500, 599] })
  ],
  ...BROWSER_OPTIONS
});

@Injectable()
export class SentryHandler extends SentryErrorHandler {

  constructor (
    private ngZone: NgZone
  ) {
    super(environment.SENTRY.ERROR_HANDLER_OPTIONS);
  }

  public override handleError(error: any): void {

    return super.handleError(error);

    const extractedError = this._extractError(error) || 'Handled unknown error';
    // Capture handled exception and send it to Sentry.
    const eventId = this.ngZone.runOutsideAngular(() => captureException(extractedError, {
      mechanism: { type: 'angular', handled: false },
    }));

    // When in development mode, log the error to console for immediate feedback.
    if (this._options.logErrors) {
      // eslint-disable-next-line no-console
      console.error(extractedError);
    }

    // Optionally show user dialog to provide details on what happened.
    if (this._options.showDialog) {
      this.ngZone.runOutsideAngular(() => {
        showReportDialog({ ...this._options.dialogOptions, eventId });
      });
    }
  }

}

let ERROR_HANDLER: SentryHandler;

export const SentryFactory = (zone: NgZone) => {
  if (!ERROR_HANDLER) {
    ERROR_HANDLER = new SentryHandler(zone);
  }
  return ERROR_HANDLER;
}

export const SentryProvider: FactoryProvider = {
  provide: ErrorHandler,
  useFactory: SentryFactory,
  deps: [NgZone]
}
