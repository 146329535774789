import { isBoolean, isNull, isNumberOrNull, isObject, isNumber, isString } from '@evo/utils/types';
import { DivisionShortcut, DivisionShortcutStruct } from '../../companies/division-shortcut';
import { CompanyShortcut, CompanyShortcutStruct } from '../../companies/company-shortcut';
import { Group, GroupStruct } from '../../permissions';
import { Salutation } from '@common/models';
import { Person, PersonStruct } from '../person';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface ClientStruct extends PersonStruct {
  company?: CompanyShortcutStruct;
  company_id?: string;
  division?: DivisionShortcutStruct;
  division_id?: string;
  last_login?: string;
  last_api_auth?: string;
  count_api_calls: number;
  count_logins: number;
  is_recepient?: string;
  is_admin?: string;
  group?: GroupStruct;
}

export class Client extends Person {

  constructor (
    uuid: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    avatar: string,
    isActive: boolean,
    salutation: Salutation,
    public isAdmin: boolean,
    public company?: CompanyShortcut,
    public division?: DivisionShortcut,
    public lastLogin?: Moment,
    public lastApiAuth?: Moment,
    public countLogins = 0,
    public countApiCalls = 0,
    public isRecepient?: boolean,
    public group?: Group
  ) {
    super(uuid, firstName, lastName, email, phone, avatar, isActive, salutation);
  }

  public static override create(data?: ClientStruct) {

    if (!data) {
      data = {} as any;
    }

    return new Client(
      data.uuid || null,
      data.first_name || null,
      data.last_name || null,
      data.email || null,
      data.phone || null,
      data.avatar || '',
      isBoolean(data.is_active) ? data.is_active : false,
      isObject(data.salutation) ? Salutation.create(data.salutation) : null,
      isBoolean(data.is_admin) ? data.is_admin : false,
      data.company ? CompanyShortcut.create(data.company) : null,
      data.division ? DivisionShortcut.create(data.division) : null,
      data.last_login ? moment(data.last_login) : null,
      data.last_api_auth ? moment(data.last_api_auth) : null,
      isNumber(data.count_logins) ? data.count_logins : null,
      isNumber(data.count_api_calls) ? data.count_api_calls : null,
      isBoolean(data.is_recepient) ? data.is_recepient : null,
      data.group ? Group.create(data.group) : null
    );
  }

  public override toString(): string {
    return this.getFullName();
  }

  public override toJSON(): ClientStruct {
    return {
      uuid: this.uuid,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      phone: this.phone,
      avatar: this.avatar,
      is_active: this.isActive,
      salutation_id: this.salutation ? this.salutation.uuid : null,
      division_id: this.division ? this.division.uuid : null,
      company_id: this.company ? this.company.uuid : null,
      last_login: this.lastLogin ? this.lastLogin.toISOString() : null,
      last_api_auth: this.lastApiAuth ? this.lastApiAuth.toISOString() : null,
      count_logins: this.countLogins,
      count_api_calls: this.countApiCalls
    };
  }

  public override update(data: ClientStruct): this {
    super.update(data);
    this.company = isNull(data.company) ? null : data.company ? CompanyShortcut.create(data.company) : this.company;
    this.division = isNull(data.division) ? null : data.division ? DivisionShortcut.create(data.division) : this.division;
    this.isRecepient = isBoolean(data.is_recepient) ? data.is_recepient : this.isRecepient;
    this.isAdmin = isBoolean(data.is_admin) ? data.is_admin : this.isAdmin;
    this.group = isNull(data.group) ? null : isObject(data.group) ? Group.create(data.group) : this.group;
    this.lastLogin = isNull(data.last_login) ? null : isString(data.last_login) ? moment(data.last_login) : this.lastLogin;
    this.lastApiAuth = isNull(data.last_api_auth) ? null : isString(data.last_api_auth) ? moment(data.last_api_auth) : this.lastLogin;
    this.countLogins = isNumberOrNull(data.count_logins) ? data.count_logins : this.countLogins;
    this.countApiCalls = isNumberOrNull(data.count_api_calls) ? data.count_api_calls : this.countApiCalls;
    return this;
  }

}
