import { Model } from '@evo/models';

export interface CompanyShortcutStruct {
  uuid: string;
  name: string;
  phone: string;
  website: string;
}

export class CompanyShortcut extends Model<CompanyShortcutStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public phone: string,
    public website: string,
  ) {
    super();
  }

  public static create(data: CompanyShortcutStruct) {
    if (!data) {
      data = {} as CompanyShortcutStruct;
    }
    return new CompanyShortcut(
      data.uuid || null,
      data.name || '',
      data.phone || '',
      data.website || '',
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): CompanyShortcutStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      phone: this.phone,
      website: this.website,
    };
  }

  public update(data: CompanyShortcutStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.name = data.name || '';
    this.phone = data.phone || '';
    this.website = data.website || '';
    return this;
  }
}
