import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Breadcrumbs } from '@main/providers/breadcrumbs';
import { LocalStorageService } from '../local-storage';
import { isNumber, isString } from '@evo/utils/types';
import { Title } from '@angular/platform-browser';
import { Tabs } from '@main/providers/tabs';
import { Params } from '@angular/router';
import { Link } from '@main/models';

@Injectable({ providedIn: 'root' })
export class LayoutService {

  private container: HTMLDivElement;

  public showEvoPaymentForm = false;
  public sideBarOpened = false;

  constructor (
    @Inject(DOCUMENT) private document: Document,
    private title: Title,
    private storage: LocalStorageService,
    private scroller: ViewportScroller,
    public breadcrumbs: Breadcrumbs,
    public tabs: Tabs,
  ) {
    this.sideBarOpened = this.storage.getItem('SIDEBAR_OPENED', false);
  }

  public setPageTitle(value: string) {
    this.title.setTitle(`${value} - EVO Apps`);
    return this;
  }

  public getPageTitle() {
    return this.title.getTitle();
  }

  public getContainer() {
    if (!this.container) {
      this.container = this.document.getElementById('container').parentElement as HTMLDivElement;
    }
    return this.container;
  }

  public removeContainer() {
    delete this.container;
  }

  public overflowYScroll(scroll = true) {
    if (scroll) {
      this.getContainer().classList.add('overflow-y-scroll');
    } else {
      this.getContainer().classList.remove('overflow-y-scroll');
    }
  }

  public breadcrumb(name: string): Link | null;
  public breadcrumb(name: string, route: string): LayoutService;
  public breadcrumb(name: string, route: string, disabled: boolean): LayoutService;
  public breadcrumb(name: string, route: string, disabled: boolean, queryParams: Params | URLSearchParams): LayoutService;
  public breadcrumb(name: string, route = '', disabled = false, queryParams?: Params | URLSearchParams): Link | LayoutService {
    if (arguments.length == 1) {
      return this.breadcrumbs.get(name);
    }
    this.breadcrumbs.append(Link.create(name, route, false, disabled, queryParams));
    return this;
  }

  public breadcrumbsClear() {
    this.breadcrumbs.clear();
    return this;
  }

  public tabsSet(tabs: Link[]) {
    this.tabs.set(tabs);
    return this;
  }

  public tab(name: string): Link | null;
  public tab(name: string, route: string): LayoutService;
  public tab(name: string, route: string, exact: boolean): LayoutService;
  public tab(name: string, route: string, exact: boolean, disabled: boolean): LayoutService;
  public tab(name: string, route: string, exact: boolean, disabled: boolean, queryParams: Params | URLSearchParams): LayoutService;
  public tab(name: string, route = '', exact = false, disabled = false, queryParams?: Params | URLSearchParams): Link | LayoutService {
    if (arguments.length == 1) {
      return this.tabs.get(name);
    }
    this.tabs.append(Link.create(name, route, exact, disabled, queryParams));
    return this;
  }

  public tabsClear() {
    this.tabs.clear();
    return this;
  }

  public toggleSidebar() {
    this.sideBarOpened = !this.sideBarOpened;
    this.storage.setItem('SIDEBAR_OPENED', this.sideBarOpened);
    return this;
  }

  public closeSidebar() {
    this.sideBarOpened = false;
    this.storage.setItem('SIDEBAR_OPENED', this.sideBarOpened);
    return this;
  }

  public scroll(x: string | number | [number, number], y?: number) {
    this.scroller.getScrollPosition();
    if (isString(x)) {
      this.scroller.scrollToAnchor(x);
    } else if (Array.isArray(x) && x.length == 2) {
      this.scroller.scrollToPosition(x);
    } else if (isNumber(x) && y) {
      this.scroller.scrollToPosition([x, y]);
    } else {
      this.scroller.scrollToPosition([0, 0]);
    }
    return this;
  }

  public setShowEvoPaymentForm(show: boolean) {
    this.showEvoPaymentForm = !!show;
    return this;
  }
}
