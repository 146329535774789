import { SalutationsCollection, SalutationStruct } from '@common/models';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SalutationsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch() {
    return this.http.get(`${environment.URL.API}/common/salutations/`)
      .pipe(map((data: SalutationStruct[]) => {
        return SalutationsCollection.create(data);
      }));
  }
}
