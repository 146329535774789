import { isNumber, toInteger } from '@evo/utils/types';
import { AbstractControl } from '@angular/forms';
import { empty } from '../../utils';

export function max(max: number, message = '') {
  return (control: AbstractControl) => {
    if (!empty(control.value)) {
      if (!isNumber(control.value)) {
        return { max: 'Numeric value required.' };
      }
      return toInteger(control.value) > max ? { max: message } : null;
    }
    return null;
  };
}
