import { Model } from '@evo/models';

export class AircraftTypeStruct {
  uuid: string;
  name: string;
}

export class AircraftType extends Model<AircraftTypeStruct> {

  constructor (
    public uuid: string,
    public name: string,
  ) {
    super();
  }

  public static create(data?: AircraftTypeStruct) {
    if (!data) {
      data = {} as AircraftTypeStruct;
    }
    return new AircraftType(
      data.uuid || null,
      data.name || null
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): AircraftTypeStruct {
    return {
      uuid: this.uuid,
      name: this.name
    };
  }

  public update(data: AircraftTypeStruct): this {
    this.uuid = 'string' == typeof data.uuid ? data.uuid : this.uuid;
    this.name = 'string' == typeof data.name ? data.name : this.name;
    return this;
  }

}
