import { ModelFormControl } from '../model-form-control';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { ModelControl } from '../model-control';
import { Model } from '@evo/models';
import { Count } from '../../../../app/common/models';
import { Moment } from 'moment';


export class ModelForm extends FormGroup {

  public validate() {
    this.markControlsAsDirty(this);
    return this.valid;
  }

  private markControlsAsDirty(group: FormGroup) {
    Object.keys(group.controls).map((name) => {
      let control = group.get(name) as FormControl;
      if (control instanceof FormGroup) {
        this.markControlsAsDirty(control);
      } else if (control.enabled) {
        control.markAsDirty();
        control.updateValueAndValidity({ emitEvent: true });
      }
    });
  }

  public processErrorResponse(response: HttpErrorResponse) {
    let status = response.status,
      errors = response.error;

    if (status >= 500 || Object.prototype.toString.call(errors) !== '[object Object]') {
      throw new Error('Not a validation error');
    }

    Object.keys(errors).forEach((controlName) => {
      let control = this.getControl(controlName),
        errs = errors[controlName];
      if (control) {
        control.markAsDirty();
        control.setErrors(Array.isArray(errs) ? errs : [errs]);
        control['_calculateStatus']();
      }
    });
  }

  public getControl(name: string) {
    let control = this.get(name);
    if (control) {
      return control;
    }
    let names = Object.keys(this.controls);

    for (let i = 0; i < names.length; i++) {
      let control = this.get(names[i]);
      if (control instanceof ModelFormControl) {
        if (control.getKeyName(names[i]) == name) {
          return control;
        }
      }
    }
    return control;
  }

  public toJSON() {
    return this._toJSON(this.getRawValue(), this);
  }

  private _toJSON(value: any, group: FormGroup): any {
    if (!value) {
      return null;
    }
    let result = {};
    Object.keys(value).forEach((key) => {
      let control = group.get(key),
        val = value[key];
      if (control instanceof Count) {
        result[key] = control.toJSON();
      } else if (control instanceof ModelControl) {
        result[key] = control.pk();
      } else if (control instanceof ModelFormControl) {
        result[control.getKeyName(key)] = (val instanceof Model) ? val.pk : null;
      } else if (val && val._isAMomentObject) {
        result[key] = (val as Moment).toISOString();
      } else if (Object.prototype.toString.call(val) == '[object Object]') {
        result[key] = this._toJSON(val, control as FormGroup);
      } else {
        result[key] = val;
      }
    });
    return result;
  }
}
