import { DivisionShortcut, DivisionShortcutStruct } from '@auth/models/companies/division-shortcut';
import { isBoolean, isNumber, isString } from '@evo/utils/types';
import { Person, PersonStruct } from '@auth/models/users';
import { Model } from '@evo/models';


export class AircraftStruct {
  uuid: string;
  name: string;
  registration: string;
  is_deleted?: boolean;
  division?: DivisionShortcutStruct;
  created_by?: PersonStruct;
  count_orders?: number;
}

export class Aircraft extends Model<AircraftStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public registration: string,
    public isDeleted: boolean,
    public division: DivisionShortcut,
    public createdBy: Person,
    public countOrders: number
  ) {
    super();
  }

  public static create(data?: AircraftStruct) {
    if (!data) {
      data = {} as AircraftStruct;
    }
    return new Aircraft(
      data.uuid || null,
      data.name || null,
      data.registration || null,
      isBoolean(data.is_deleted) ? data.is_deleted : false,
      data.division ? DivisionShortcut.create(data.division) : null,
      data.created_by ? Person.create(data.created_by) : null,
      isNumber(data.count_orders) ? data.count_orders : null,
    );
  }

  public longName() {
    let parts: string[] = [this.registration, this.name];
    if (isNumber(this.countOrders)) {
      parts.push(`O - ${this.countOrders}`);
    }
    if (this.createdBy) {
      parts.push(this.createdBy.email);
    }
    if (this.isDeleted) {
      parts.push('DELETED');
    }
    return parts.join(' / ');
  }

  public override toString(): string {
    return `${this.registration} (${this.name})`;
  }

  public toJSON(): AircraftStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      registration: this.registration,
    };
  }

  public update(data: AircraftStruct): this {
    this.uuid = isString(data.uuid) ? data.uuid : this.uuid;
    this.name = isString(data.name) ? data.name : this.name;
    this.registration = isString(data.registration) ? data.registration : this.registration;
    this.isDeleted = isBoolean(data.is_deleted) ? data.is_deleted : this.isDeleted;
    this.countOrders = isNumber(data.count_orders) ? data.count_orders : this.countOrders;
    this.createdBy = data.created_by ? Person.create(data.created_by) : this.createdBy;
    this.division = data.division ? DivisionShortcut.create(data.division) : this.division;
    return this;
  }

}
