<nav class="navbar  navbar-dark bg-primary">
  <a (click)="layout.toggleSidebar()" class="navbar-toggler border-0 p-0 mx-3 pointer">
    <span class="navbar-toggler-icon"></span>
  </a>
  <a class="navbar-brand me-auto" routerLink="/access/sign-in">
    <img class="navbar-logo" src="../../../../../../../assets/img/evo-logo-white-v3.png" alt="EVO Fuels">
  </a>
  <div class="navbar-expand">
    <ul class="navbar-nav ms-auto" *ngIf="!user.isAuthenticated()">
      <li class="nav-item">
        <a class="nav-link fw-normal" href="#" (click)="$event.preventDefault()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
           routerLink="/access/register">Sign Up</a>
      </li>
      <li class="nav-item">
        <a class="nav-link fw-normal" href="#" (click)="$event.preventDefault()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
           routerLink="/access/sign-in">Sign In</a>
      </li>
    </ul>
    <ul class="navbar-nav" *ngIf="user.isAuthenticated()">

      <li *ngIf="user.can(ORDER.CREATE)" class="nav-item fuel-cart">
        <a class="nav-link d-flex align-items-center" tabindex="0" routerLink="/fuel/admin/checkout">
          <div class="button-badge me-2">
            <div class="unread-count">{{batch ? batch.ordersCount : 0}}</div>
            <i class="navbar-icon fa-solid fa-cart-shopping"></i>
          </div>
          <div class="d-none d-sm-block">Fuel Cart</div>
        </a>
      </li>

      <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
        <a class="nav-link d-flex align-items-center" tabindex="0" ngbDropdownToggle id="navbarUserMenu" role="button">
          <i class="navbar-icon fa-regular fa-circle-user me-sm-2"></i>
          <span class="d-none d-sm-block">{{user.getFullName()}}</span>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarUserMenu" class="dropdown-menu">
          <a ngbDropdownItem routerLink="/settings/admin/profile" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">User Account</a>
          <a ngbDropdownItem routerLink="/settings/admin/change-password" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Security Settings</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem routerLink="/access/logout" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sign Out</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
