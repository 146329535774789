import { Model } from '@evo/models';
import { Person, PersonStruct } from '../../users';

export interface EmailStruct {
  uuid: string;
  email: string;
  is_active: boolean;
  order_number: number;
  user?: PersonStruct;
  user_id?: string;
}

export class Email extends Model<EmailStruct> {
  constructor (
    public uuid: string,
    public email: string,
    public isActive: boolean,
    public orderNumber: number,
    public user?: Person
  ) {
    super();
  }

  public static create(data?: EmailStruct) {
    if (!data) {
      data = {} as any;
    }
    return new Email(
      data.uuid || null,
      data.email || null,
      'boolean' == typeof data.is_active ? data.is_active : null,
      'number' == typeof data.order_number ? data.order_number : null,
      data.user ? Person.create(data.user) : null
    );
  }

  public override toString(): string {
    return this.email;
  }

  public toJSON(): EmailStruct {
    return {
      uuid: this.uuid,
      email: this.email,
      is_active: this.isActive,
      order_number: this.orderNumber,
      user_id: this.user ? this.user.uuid : null
    };
  }

  public update(data: EmailStruct): this {
    this.uuid = data.uuid ? data.uuid : this.uuid;
    this.email = data.email ? data.email : this.email;
    this.isActive = 'boolean' == typeof data.is_active ? data.is_active : this.isActive;
    this.orderNumber = 'number' == typeof data.order_number ? data.order_number : this.orderNumber;
    this.user = data.user ? Person.create(data.user) : this.user;
    return this;
  }
}
