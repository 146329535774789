import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbDateAdapter, NgbInputDatepicker, NgbTimepicker, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { controlErrors } from '@evo/utils/html';
import { isString } from '@evo/utils/types';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-datetime-control',
  templateUrl: './datetime-control.component.html',
  styleUrls: ['./datetime-control.component.scss'],
})
export class DatetimeControlComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {

  @ViewChild('inputRef', { static: true })
  public inputRef: ElementRef;

  @ViewChild('datepicker', { static: true })
  public datepicker: NgbInputDatepicker;

  @ViewChild('timepicker', { static: true })
  public timepicker: NgbTimepicker;

  @Input()
  public size: 'sm' | 'md' | 'lg' = 'md';

  @Input()
  public defaultHours = 0;

  @Input()
  public minDate: Moment;

  @Input()
  public placeholder = '';

  public date: Moment;
  public time: NgbTimeStruct;

  public isDisabled = false;
  public isInvalid = false;
  public isValid = false;

  public errors: string[] = [];
  private subscriptions: Subscription[] = [];
  private hourInput: HTMLInputElement;

  private _onTouched = () => {
  };
  private _onChange = (_: any) => {
  };

  constructor (
    private ngControl: NgControl,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    public ngbDateAdapter: NgbDateAdapter<Moment>,
  ) {
    ngControl.valueAccessor = this;
  }

  public ngOnInit() {
    let s = this.ngControl.statusChanges.subscribe((status) => {
      let data = controlErrors(this.ngControl);
      this.isInvalid = data[0];
      this.isValid = data[1];
      this.errors = data[2];
    });
    this.subscriptions.push(s);
    let el = (this.elementRef.nativeElement as HTMLDivElement);
    this.hourInput = el.querySelector('input.ngb-tp-input');
  }

  public ngAfterViewInit() {
    let $input = this.inputRef.nativeElement,
      $el = this.elementRef.nativeElement,
      id = $el.getAttribute('id');
    if (id) {
      this.renderer.removeAttribute($el, 'id');
      this.renderer.setAttribute($input, 'id', id);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public clickOutside($event) {
    if (this.datepicker.isOpen()) {
      this.datepicker.close();
    }
  }

  public onBlur($e: Event) {
    if (!this.date || isString(this.date)) {
      delete this.date;
    }
  }

  public dateTimeChange(focus = false) {
    if (this.datepicker.isOpen()) {
      this.datepicker.close();
    }
    let date = this.date;
    if (!date || !(date instanceof moment)) {
      delete this.time;
      this.timepicker.readonlyInputs = true;
      return this._onChange(null);
    }
    this.timepicker.readonlyInputs = false;
    this.time = this.time ? this.time : { hour: this.defaultHours, minute: 0, second: 0 };
    let value = moment.utc(Date.UTC(
      date.year(), date.month(), date.date(),
      this.time.hour || 0, this.time.minute || 0, this.time.second || 0,
    ));
    // trick for time validation
    value['timeIsValid'] = !!this.time;
    this._onChange(value);

    if (focus) {
      this.hourInput.focus();
      setTimeout(() => {
        this.hourInput.setSelectionRange(0, this.hourInput.value.length);
      }, 10);
    }
  }

  public registerOnChange(fn: (value: any) => any) {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => any) {
    this._onTouched = fn;
  }

  public writeValue(value: any) {
    if (value) {
      this.date = moment.utc(Date.UTC(value.year(), value.month(), value.date()));
      this.time = {
        hour: value.hours(),
        minute: value.minutes(),
        second: value.seconds(),
      };
      value['timeIsValid'] = !!this.time;
      this.timepicker.readonlyInputs = false;
    } else {
      this.timepicker.readonlyInputs = true;
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public getTimepickerSize() {
    switch (this.size) {
      case 'lg':
        return 'large';
      case 'md':
        return 'medium';
      case 'sm':
        return 'small';
    }
    return 'medium';
  }

}
