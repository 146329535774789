import { environment } from '@environments/environment';
import { CeleryTask, CeleryTaskStruct } from '../../models';
import { HttpClient } from '@angular/common/http';
import { switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { timer, of } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class CeleryService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public observe(task: CeleryTask) {
    return this.checkTask(task).pipe(
      switchMap((res: CeleryTask) => {
        if (res.isCompleted()) {
          return of(res);
        }
        return timer(1000).pipe(
          switchMap(() => {
            return this.observe(task);
          }),
        );
      }),
    );
  }

  public checkTask(task: CeleryTask) {
    return this.http.get(`${environment.URL.API}/common/tasks/${task.uuid}/`).pipe(
      map((data: CeleryTaskStruct) => {
        return task.update(data);
      })
    );
  }
}
