import { Money, MoneyStruct, Count, CountStruct, Percent, Time } from '@common/models';
import { isNumber, isNumberOrNull, hasOwnProperty, isString } from '@evo/utils/types';
import { formatNumber } from '@angular/common';
import { Model } from '@evo/models';

export interface StatsStruct {
  value: null | number | string | MoneyStruct | CountStruct | Time;
  percent: number;
  change: number;
  decimals: number | string;
  label: string;
}

export class Stats extends Model<StatsStruct> {
  constructor (
    public value: null | number | string | Money | Count | Time,
    public percent: Percent,
    public change: Percent,
    public decimals: number | string,
    public label: string
  ) {
    super();
  }

  public static create(data: Partial<StatsStruct>) {
    if (!data) {
      data = {} as StatsStruct;
    }
    return new Stats(
      Stats.prepareValue(data.value),
      isNumber(data.percent) ? Percent.create(data.percent) : null,
      isNumber(data.change) ? Percent.create(data.change) : null,
      isNumber(data.decimals) || isString(data.decimals) ? data.decimals : 2,
      isString(data.label) ? data.label : null
    );
  }

  private static prepareValue(value: null | number | string | MoneyStruct | CountStruct | Time) {
    if (isString(value) || isNumberOrNull(value)) {
      return value as number | null;
    }
    if (hasOwnProperty(value, 'currency')) {
      return Money.create(value as MoneyStruct);
    }
    if (hasOwnProperty(value, 'units')) {
      return Count.create(value as CountStruct);
    }
    return null;
  }

  public override toString(): string {
    if (isString(this.value)) {
      return this.value;
    }
    if (this.value instanceof Time) {
      return this.value.format();
    }
    let info = `1.${this.decimals}-${this.decimals}`;
    if (this.value instanceof Money) {
      return this.value.format(info);
    }
    if (this.value instanceof Count) {
      return this.value.format(info);
    }
    if (isNumber(this.value)) {
      if (this.decimals == '%') {
        return formatNumber(this.value * 100, 'en', '1.0-0') + ' %';
      } else {
        return formatNumber(this.value, 'en', info);
      }
    }
    return this.value ? this.value + '' : '---';
  }

  public toJSON() {
    return {
      value: this.value instanceof Count || this.value instanceof Money ? this.value.toJSON() : this.value,
      percent: this.percent ? this.percent.toJSON() : null,
      change: this.change ? this.change.toJSON() : null,
      decimals: this.decimals,
      label: this.label
    };
  }

  public update(data: StatsStruct) {
    return this;
  }
}
