import { EventEmitter, Injectable } from '@angular/core';
import { DateRange, DateRangeStruct } from '@main/models';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

export enum DATE_RANGES_URLS {
  ANALYTICS = '/common/analytics-date-ranges/',
  VONAGE = '/common/date-ranges/'
}

@Injectable({ providedIn: 'root' })
export class DateRangesService {

  public dateRangeChange = new EventEmitter<DateRange>();
  private dateRanges: DateRange[] = [];
  private dateRange: DateRange;

  private url: string;

  constructor (
    private http: HttpClient,
    private location: Location
  ) {
  }

  public fetch(url: DATE_RANGES_URLS = DATE_RANGES_URLS.ANALYTICS) {
    if (this.dateRanges && this.dateRanges.length && url == this.url) {
      return of(this.dateRanges);
    }
    this.url = url;
    return this.http.get(`${environment.URL.API}${url}`).pipe(
      map((data: DateRangeStruct[]) => {
        return data.map((s) => DateRange.create(s));
      }),
      tap((ranges: DateRange[]) => {
        this.dateRanges = ranges;
        if (!this.dateRange) {
          this.selectFromQueryParams();
        }
      })
    );
  }

  private selectFromQueryParams() {
    let path = this.location.path(false),
      parts = path.split('?'),
      search = parts[parts.length - 1],
      params = new URLSearchParams(search);
    let from = params.get('date_from'),
      to = params.get('date_to');
    this.selectFromDates(from, to);
  }

  private selectFromDates(dateFrom: string, dateTo: string) {
    let re = /^(\d{4})([\/-])(\d{1,2})\2(\d{1,2})$/;
    if (dateFrom && dateTo && re.test(dateFrom) && re.test(dateTo)) {
      let custom = DateRange.create({
        name: 'Custom',
        date_from: dateFrom,
        date_to: dateTo,
        is_default: false,
        is_custom: true
      });
      this.dateRange = this.dateRanges.find((r) => {
        return r.equals(custom);
      }) || custom;
    } else {
      this.dateRange = this.getDefaultRange();
    }
  }

  public setDateRange(range: DateRange) {
    this.dateRange = range;
    this.dateRangeChange.emit(range);
  }

  public getDateRange() {
    return this.dateRange;
  }

  public getDateRanges() {
    return this.dateRanges;
  }

  public getDefaultRange() {
    return this.dateRanges.find((r) => r.isDefault) || this.dateRanges[0];
  }
}
