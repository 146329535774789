<div class="input-group" [class.is-invalid]="isInvalid">
  <input class="form-control datepicker"
         [class.is-invalid]="isInvalid"
         [class.is-valid]="isValid"
         ngbDatepicker
         #datepicker="ngbDatepicker"
         (click)="datepicker.toggle()"
         [disabled]="isDisabled"
         [(ngModel)]="value"
         (ngModelChange)="writeValue(this.value)"
         [placeholder]="placeholder"
         [minDate]="ngbDateAdapter.fromModel(minDate)"
         [maxDate]="ngbDateAdapter.fromModel(maxDate)">
  <button class="btn btn-outline-primary" (click)="datepicker.toggle()" type="button" [disabled]="isDisabled">
    <i class="fa fa-calendar"></i>
  </button>
</div>
<small class="invalid-feedback">{{errors[0]}}</small>
