<ng-sidebar-container>
  <ng-sidebar (onCloseStart)="events.sidebar.closeStart.emit($event)" (onClosed)="events.sidebar.closed.emit($event)"
              (onOpenStart)="events.sidebar.openStart.emit($event)" (onOpened)="events.sidebar.opened.emit($event)" [mode]="sideBarMode"
              [(opened)]="layout.sideBarOpened" [showBackdrop]="sideBarShowBackdrop" [animate]="sideBarAnimate"
              [closeOnClickOutside]="sideBarCloseOnClickOutside" [closeOnClickBackdrop]="sideBarCloseOnClickBackdrop">
    <app-sidebar-admin></app-sidebar-admin>
  </ng-sidebar>

  <div #container id="container" ng-sidebar-content>
    <ngx-loading-bar></ngx-loading-bar>
    <app-navbar-admin></app-navbar-admin>
    <app-navigation></app-navigation>
    <router-outlet></router-outlet>
  </div>
</ng-sidebar-container>