import { isArray, isArrayOrNull, isBoolean, isBooleanOrNull, isNull, isNumber, isNumberOrNull, isObject, isString, isStringOrNull } from '@evo/utils/types';
import { Timezone, TimezoneStruct } from '../timezone';
import { Location, LocationStruct } from './location';
import { Country, CountryStruct } from '../country';
import { State, StateStruct } from '../state';
import { Count } from '@common/models';
import * as moment from 'moment';
import { Point } from '../point';
import { Moment } from 'moment';

export interface AirportStruct extends LocationStruct {
  fss: string;

  geonameid: string;
  geoname: string;
  geocodes: string[];
  geoaltitude: number;
  geoelevation: number[];

  url_faa: string;
  url_wikipedia: string;
  url_acukwik: string;
  url_world_airport_codes: string;

  is_international: boolean;
  is_closed: boolean;
  is_used: boolean;

  date_activation: string;
  date_last_inspection: string;

  ownership_type: string;
  usage_type: string;
  fuel_types: string;
}

export class Airport extends Location {
  constructor (
    uuid: string,
    public fss: string,
    public geonameid: string,
    public geoname: string,
    public geocodes: string[],
    public geoaltitude: number,
    public geoelevation: number[],
    icao: string,
    iata: string,
    faa: string,
    extra: string,
    name: string,
    city: string,
    latitude: number,
    longitude: number,
    altitude: number,
    coordinates: Point,
    public urlFaa: string,
    public urlWikipedia: string,
    public urlAcukwik: string,
    public urlWorldAirportCodes: string,
    public isInternational: boolean,
    public isClosed: boolean,
    public isUsed: boolean,
    dispatcherNotes: string,
    type?: string,
    state?: State,
    country?: Country,
    timezone?: Timezone,
    distance?: Count,
    countSearches?: number,
    countOffers?: number,
    public dateActivation?: Moment,
    public dateLastInspection?: Moment,
    public ownershipType?: string,
    public usageType?: string,
    public fuelTypes?: string
  ) {
    super(uuid, icao, iata, faa, extra, name, city, latitude, longitude, altitude, coordinates, dispatcherNotes, type, state, country, timezone, distance, countSearches, countOffers);
  }

  public static override create(data: Partial<AirportStruct>) {
    if (!data) {
      data = {} as AirportStruct;
    }
    return new Airport(
      isString(data.uuid) ? data.uuid : null,
      isString(data.fss) ? data.fss : null,
      isString(data.geonameid) ? data.geonameid : null,
      isString(data.geoname) ? data.geoname : null,
      isArray(data.geocodes) ? data.geocodes : [],
      isNumber(data.geoaltitude) ? data.geoaltitude : null,
      isArray(data.geoelevation) ? data.geoelevation : [],
      isString(data.icao) ? data.icao : null,
      isString(data.iata) ? data.iata : null,
      isString(data.faa) ? data.faa : null,
      isString(data.extra) ? data.extra : null,
      isString(data.name) ? data.name : null,
      isString(data.city) ? data.city : null,
      isNumber(data.latitude) ? data.latitude : null,
      isNumber(data.longitude) ? data.longitude : null,
      isNumber(data.altitude) ? data.altitude : null,
      isArray(data.coordinates) ? Point.create(data.coordinates) : null,
      isString(data.url_faa) ? data.url_faa : null,
      isString(data.url_wikipedia) ? data.url_wikipedia : null,
      isString(data.url_acukwik) ? data.url_acukwik : null,
      isString(data.url_world_airport_codes) ? data.url_world_airport_codes : null,
      isBoolean(data.is_international) ? data.is_international : false,
      isBoolean(data.is_closed) ? data.is_closed : false,
      isBoolean(data.is_used) ? data.is_used : false,
      isString(data.dispatcher_notes) ? data.dispatcher_notes : null,
      isString(data.type) ? data.type : null,
      isObject(data.state) ? State.create(data.state as StateStruct) : null,
      isObject(data.country) ? Country.create(data.country as CountryStruct) : null,
      isObject(data.timezone) ? Timezone.create(data.timezone as TimezoneStruct) : null,
      isObject(data.distance) ? Count.create(data.distance) : null,
      isNumber(data.count_searches) ? data.count_searches : null,
      isNumber(data.count_offers) ? data.count_offers : null,
      isString(data.date_activation) ? moment.utc(data.date_activation) : null,
      isString(data.date_last_inspection) ? moment.utc(data.date_last_inspection) : null,
      isString(data.ownership_type) ? data.ownership_type : null,
      isString(data.usage_type) ? data.usage_type : null,
      isString(data.fuel_types) ? data.fuel_types : null
    );
  }

  public override toJSON(): AirportStruct {
    return Object.assign(super.toJSON(), {
      fss: this.fss,
      geonameid: this.geonameid,
      geoname: this.geoname,
      geocodes: this.geocodes,
      geoaltitude: this.geoaltitude,
      geoelevation: this.geoelevation,
      url_faa: this.urlFaa,
      url_wikipedia: this.urlWikipedia,
      url_acukwik: this.urlAcukwik,
      url_world_airport_codes: this.urlWorldAirportCodes,
      is_international: this.isInternational,
      is_closed: this.isClosed,
      is_used: this.isUsed,
      date_activation: this.dateActivation ? this.dateActivation.toISOString() : null,
      date_last_inspection: this.dateLastInspection ? this.dateLastInspection.toISOString() : null,
      ownership_type: this.ownershipType,
      usage_type: this.usageType,
      fuel_types: this.fuelTypes
    }) as AirportStruct;
  }

  public override update(data: Partial<AirportStruct>): this {
    super.update(data);
    this.fss = isStringOrNull(data.fss) ? data.fss : this.fss;
    this.geonameid = isStringOrNull(data.geonameid) ? data.geonameid : this.geonameid;
    this.geoname = isStringOrNull(data.geoname) ? data.geoname : this.geoname;
    this.geocodes = isArrayOrNull(data.geocodes) ? data.geocodes : this.geocodes;
    this.geoaltitude = isNumberOrNull(data.geoaltitude) ? data.geoaltitude : this.geoaltitude;
    this.geoelevation = isArrayOrNull(data.geoelevation) ? data.geoelevation : this.geoelevation;
    this.urlFaa = isStringOrNull(data.url_faa) ? data.url_faa : this.urlFaa;
    this.urlWikipedia = isStringOrNull(data.url_wikipedia) ? data.url_wikipedia : this.urlWikipedia;
    this.urlAcukwik = isStringOrNull(data.url_acukwik) ? data.url_acukwik : this.urlAcukwik;
    this.urlWorldAirportCodes = isStringOrNull(data.url_world_airport_codes) ? data.url_world_airport_codes : this.urlWorldAirportCodes;
    this.isInternational = isBooleanOrNull(data.is_international) ? data.is_international : this.isInternational;
    this.isClosed = isBooleanOrNull(data.is_closed) ? data.is_closed : this.isClosed;
    this.isUsed = isBooleanOrNull(data.is_used) ? data.is_used : this.isUsed;
    this.dateActivation = isString(data.date_activation) ? moment.utc(data.date_activation) : isNull(data.date_activation) ? null : this.dateActivation;
    this.dateLastInspection = isString(data.date_last_inspection) ? moment.utc(data.date_last_inspection) : isNull(data.date_last_inspection) ? null : this.dateLastInspection;
    this.ownershipType = isStringOrNull(data.ownership_type) ? data.ownership_type : this.ownershipType;
    this.usageType = isStringOrNull(data.usage_type) ? data.usage_type : this.usageType;
    this.fuelTypes = isStringOrNull(data.fuel_types) ? data.fuel_types : this.fuelTypes;
    return this;
  }
}
