import { Model } from '@evo/models';
import { Manufacturer, ManufacturerStruct } from "../manufacturer";
import { Designator, DesignatorStruct } from "../designator";

export class AircraftModelStruct {
  uuid: string;
  name: string;
  synonims: string[];
  is_verified: boolean;

  manufacturer?: ManufacturerStruct;
  designator?: DesignatorStruct;
}

export class AircraftModel extends Model<AircraftModelStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public synonims: string[],
    public isVerified: boolean,
    public manufacturer: Manufacturer,
    public designator: Designator
  ) {
    super();
  }

  public static create(data?: AircraftModelStruct) {
    if (!data) {
      data = {} as AircraftModelStruct;
    }
    return new AircraftModel(
      data.uuid || null,
      data.name || null,
      data.synonims ? data.synonims : [] as string[],
      'boolean' == typeof data.is_verified ? data.is_verified : false,
      data.manufacturer ? Manufacturer.create(data.manufacturer) : null,
      data.designator ? Designator.create(data.designator) : null
    );
  }

  public override toString(): string {
    return `${this.manufacturer.name} - ${this.name}`;
  }

  public toJSON(): AircraftModelStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      synonims: this.synonims,
      is_verified: this.isVerified
    };
  }

  public update(data: AircraftModelStruct): this {
    this.uuid = 'string' == typeof data.uuid ? data.uuid : this.uuid;
    this.name = 'string' == typeof data.name ? data.name : this.name;
    this.synonims = data.synonims ? data.synonims : this.synonims;
    this.isVerified = 'boolean' == typeof data.is_verified ? data.is_verified : this.isVerified;
    return this;
  }

}
