<nav class="navbar navbar-dark bg-primary">
  <a (click)="layout.toggleSidebar()" class="navbar-toggler border-0 p-0 mx-3 pointer">
    <span class="navbar-toggler-icon"></span>
  </a>
  <a class="navbar-brand me-auto" routerLink="/access/sign-in">
    <img class="navbar-logo" src="../../../../../assets/img/evo-logo-white-v3.png" alt="EVO Fuels">
  </a>
  <div class="navbar-expand">
    <ul class="navbar-nav ms-auto" *ngIf="!user.isAuthenticated()">
      <li class="nav-item">
        <a class="nav-link fw-normal"
           routerLink="/access/register"
           routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true}">Sign Up</a>
      </li>
      <li class="nav-item">
        <a class="nav-link fw-normal"
           routerLink="/access/sign-in"
           routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true}">Sign In</a>
      </li>
    </ul>
    <ul class="navbar-nav d-flex align-items-center" *ngIf="user.isAuthenticated()">

      <li *ngIf="division.isClient()" class="nav-item fuel-cart">
        <a class="nav-link d-flex align-items-center" tabindex="0" routerLink="/fuel/checkout">
          <div class="button-badge me-2">
            <div class="unread-count">{{batch ? batch.ordersCount : 0}}</div>
            <div class="navbar-icon fa-solid fa-cart-shopping"></div>
          </div>
          <div class="d-none d-sm-block">Fuel Cart</div>
        </a>
      </li>

      <!--
      <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
        <a class="nav-link d-flex align-items-center" tabindex="0" ngbDropdownToggle id="navbarUserMenu" role="button">
          <div class="notification-badge"></div><div class="d-none d-md-block">Notifications</div>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarUserMenu" class="dropdown-menu">
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Invoice Due 12 Dec 2021</a>
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Fuel Release Available</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">View All</a>
        </div>
      </li>
      -->
      <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
        <a class="nav-link d-flex align-items-center" tabindex="0" ngbDropdownToggle id="navbarUserMenu" role="button">
          <img *ngIf="user.avatar" [src]="user.avatar" alt="{{user.abbr()}}" class="me-sm-2 user-img rounded-circle avatar-32 border-white">
          <i *ngIf="!user.avatar" class="navbar-icon fa-regular fa-circle-user me-sm-2"></i>
          <span class="d-none d-md-block">{{user.getFullName()}}</span>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarUserMenu" class="dropdown-menu">
          <a ngbDropdownItem routerLinkActive="active"
             routerLink="/settings/profile"
             [routerLinkActiveOptions]="{exact: true}">User Account</a>
          <a ngbDropdownItem routerLinkActive="active"
             routerLink="/settings/change-password"
             [routerLinkActiveOptions]="{exact: true}">Security Settings</a>
          <a *ngIf="division.isClient()" ngbDropdownItem routerLinkActive="active"
             routerLink="/settings/emails"
             [routerLinkActiveOptions]="{exact: true}">Notification Settings</a>
          <a *ngIf="division.isClient()" ngbDropdownItem routerLinkActive="active"
             routerLink="/settings/evo-api"
             [routerLinkActiveOptions]="{exact: true}">EVO API Keys</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem routerLinkActive="active"
             routerLink="/access/logout"
             [routerLinkActiveOptions]="{exact: true}">Sign Out</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
