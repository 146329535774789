import {Model} from './model';

export abstract class Collection<T extends Model<any>> {

  constructor(
    protected items: T[] = [],
  ) {

  }

  public push(item: T) {
    this.items.push(item);
    return this;
  }

  public remove(item: T) {
    let index = this.items.findIndex((i) => {
      return i.pk == item.pk;
    });
    this.items.splice(index, 1);
    return this;
  }

  get length() {
    return this.items.length;
  }

  public at(index: number) {
    return this.items[index];
  }

  public getAll() {
    return this.items;
  }

  public deleteAll() {
    this.items = [];
    return this;
  }

  public getById(id: number | string): T | undefined {
    return this.items.find((item: T) => {
      return item.pk == id;
    });
  }

  public setItems(items: T[]) {
    this.items = items;
    return this;
  }

  public addOrUpdate(item: T) {
    let res = this.items.find((i: T) => {
      return item.pk == i.pk;
    });

    if (res) {
      res.update(item.toJSON());
    } else {
      this.items.push(item);
    }
  }
}

