<div class="mb-3">
  <div class="chips-container form-control"
       #containerRef
       [class.disabled]="isDisabled"
       [ngClass]="inputNgClass"
       [class.is-invalid]="!isDisabled && isInvalid"
       [class.is-valid]="!isDisabled && isValid"
       (click)="onClick($event)">

    <span *ngFor="let item of items;" class="chip badge border rounded-pill bg-primary">
      {{inputFormatter(item)}} <i class="fa fa-times-circle" (click)="removeItem(item)"></i>
    </span>

    <input type="text"
           class="chips-input"
           autocomplete="off"
           random-name
           #inputRef
           #instance="ngbTypeahead"
           [placement]="'bottom-left'"
           [disabled]="isDisabled"
           (focus)="onInputFocus($event)"
           (blur)="onInputBlur($event)"
           (keydown)="onKeydown($event)"
           [placeholder]="placeholder"
           [inputFormatter]="inputFormatter"
           [resultFormatter]="resultFormatter"
           [ngbTypeahead]="search"
           [focusFirst]="true"
           [(ngModel)]="value"
           [ngModelOptions]="{standalone: true}"
           (selectItem)="selectItem($event)"
           (ngModelChange)="change($event)">
  </div>
  <small class="invalid-feedback" *ngIf="error">{{error}}</small>
</div>

