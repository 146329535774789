import { Collection } from '@evo/models';
import { Timezone, TimezoneStruct } from './timezone';

export class TimezonesCollection extends Collection<Timezone> {

    public static create(data: TimezoneStruct[]) {
        return new TimezonesCollection(data.map((item) => Timezone.create(item)));
    }

    public search(term: string) {
        term = (term + '').toLowerCase();
        return this.items.filter((timezone: Timezone) => {
            return timezone.name.toLowerCase().indexOf(term.toLowerCase()) > -1;
        }).slice(0, 10);
    }

    public exists(name: string) {
        return this.items.findIndex((timezone: Timezone) => {
            return timezone.name == name;
        }) > -1;
    }
}
