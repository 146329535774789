import { formatNumber } from '@angular/common';
import { Units, UnitsStruct } from '../units';
import { Model } from '@evo/models';

export interface CountStruct {
  value: number;
  units: UnitsStruct;
}

export class Count extends Model<CountStruct> {
  constructor (
    public value: number,
    public units: Units,
  ) {
    super();
  }

  public static create(data?: CountStruct) {
    if (!data) {
      data = {} as CountStruct;
    }
    return new Count(
      data.value || 0,
      data.units ? data.units instanceof Units ? data.units : Units.create(data.units) : null,
    );
  }

  public override toString(): string {
    return this.format();
  }

  public format(digitsInfo = '1.2-2', locale = 'en') {
    return formatNumber(this.value, locale, digitsInfo) + ' ' + (this.units ? this.units.code : '');
  }

  public toJSON(): CountStruct {
    return {
      value: this.value,
      units: this.units ? this.units.pk : null
    };
  }

  public update(data: CountStruct) {
    this.value = (data.value as any) instanceof Number ? data.value : this.value;
    this.units = data.units ? Units.create(data.units) : this.units;
    return this;
  }
}
