import { FuelVariation, FuelVariationStruct } from '../fuel-variation';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface PriceInfoStruct {
  date_from: string;
  date_to: string;
  notes: string;
  variations: FuelVariationStruct[];
}

export class PriceInfo extends Model<PriceInfoStruct> {
  constructor (
    public dateFrom: Moment,
    public dateTo: Moment,
    public notes: string,
    public variations: FuelVariation[] = [],
  ) {
    super();
  }

  public static create(data: PriceInfoStruct) {
    return new PriceInfo(
      data.date_from ? moment(data.date_from) : null,
      data.date_to ? moment(data.date_to) : null,
      data.notes || '',
      data.variations ? data.variations.map((v) => FuelVariation.create(v)) : [],
    );
  }

  public toJSON() {
    return {
      date_from: this.dateFrom ? this.dateFrom.toISOString() : null,
      date_to: this.dateTo ? this.dateTo.toISOString() : null,
      notes: this.notes,
      variations: this.variations.map((v) => v.toJSON()),
    };
  }

  public update(data: PriceInfoStruct) {
    return this;
  }
}
