import { TemplateRef } from '@angular/core';
import { Model } from '@evo/models';
import { TOAST_STATUSES } from './toast-statuses';
import { HttpErrorResponse } from '@angular/common/http';


export interface ToastStruct {
  title: string;
  message: string;
  delay: number;
  status: TOAST_STATUSES;
}

export class Toast extends Model<ToastStruct> {
  constructor (
    public title: string,
    public message: string,
    public status: TOAST_STATUSES,
    public delay: number,
    public classlist = [],
    public template: TemplateRef<any> = null,
  ) {
    super();
    this.classlist.push(`bg-${status}`);
  }

  public static create(message: string | string[] | { [key: string]: string | string[] }, status: TOAST_STATUSES, title = '', delay = 5000, template?: TemplateRef<any>) {
    let toasts: Toast[] = [];
    if (Array.isArray(message)) {
      message = message.join('<br />') as string;
      toasts.push(new Toast(title, message, status, delay, [], template));
    } else if (Object.prototype.toString.call(message) == '[object Object]') {
      let keys = Object.keys(message);
      keys.forEach((key) => {
        let t = Toast.makeTitle(key),
          m = message[key];
        if (Array.isArray(m)) {
          m = m.join('<br />') as string;
        }
        toasts.push(new Toast(t, m, status, delay));
      });
    } else {
      toasts.push(new Toast(title, message as string, status, delay, [], template));
    }
    return toasts;
  }

  public static fromErrorResponse(response: HttpErrorResponse, title = '', delay = 5000, template?: TemplateRef<any>) {
    let toasts: Toast[] = [],
      error = response.error;
    if (response.status >= 500) {
      title = 'Internal Server Error';
      toasts.push(new Toast(title, error, TOAST_STATUSES.ERROR, delay, [], template));
      return toasts;
    }
    if (Object.prototype.toString.call(error) == '[object Object]') {
      Object.keys(error).forEach((key) => {
        let t = Toast.makeTitle(key);
        let message = error[key];
        if (Array.isArray(message)) {
          message = message.join('<br />');
        } else if (Object.prototype.toString.call(message) == '[object Object]') {
          let results = [];
          Object.keys(message).forEach((k) => {
            results.push(message[k]);
          });
          message = results;
        }
        toasts.push(new Toast(t, message, TOAST_STATUSES.ERROR, delay, [], template));
      });
    } else {
      toasts.push(new Toast(response.name, response.statusText, TOAST_STATUSES.ERROR, delay, [], template));
    }
    return toasts;
  }

  private static makeTitle(title: string) {
    return title.split('_')
      .map((part) => {
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
      }).join(' ');
  }

  public toJSON() {
    return {
      title: this.title,
      message: this.message,
      delay: this.delay,
      status: this.status,
    };
  }

  public update(data: ToastStruct) {
    return this;
  }
}
