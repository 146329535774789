import { OrderStruct, Order, LocationOrdersStruct, LocationOrders } from '../../../models';
import { environment } from '@environments/environment';
import { EventsService } from '@main/services';
import { toPrimaryKey } from '@evo/utils/helpers';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class CartOrdersService {

  constructor (
    private http: HttpClient,
    private events: EventsService,
  ) {
  }

  public fetch() {
    return this.http.get(`${environment.URL.API}/fuel/admin/cart/`).pipe(
      map((data: OrderStruct[]) => {
        return data.map((o) => Order.create(o));
      }),
    );
  }

  public get(order: Order | string) {
    let uuid = toPrimaryKey(order);
    return this.http.get(`${environment.URL.API}/fuel/admin/cart/${uuid}`).pipe(
      map((data: OrderStruct) => {
        return Order.create(data);
      }),
    );
  }

  public getByLocations() {
    return this.http.get(`${environment.URL.API}/fuel/admin/cart/by-locations/`).pipe(
      map((data: LocationOrdersStruct[]) => {
        return data.map((o) => LocationOrders.create(o));
      }),
    );
  }

  public create(data: any) {
    return this.http.post(`${environment.URL.API}/fuel/admin/cart/`, data).pipe(
      map((resp: OrderStruct) => {
        return Order.create(resp);
      }),
      tap((o: Order) => {
        this.events.cart.add.emit(o);
      })
    );
  }

  public update(order: Order, data: any) {
    return this.http.put(`${environment.URL.API}/fuel/admin/cart/${order.uuid}/`, data).pipe(
      tap(() => {
        this.events.cart.update.emit(order);
      })
    );
  }

  public remove(order: Order) {
    return this.http.delete(`${environment.URL.API}/fuel/admin/cart/${order.uuid}/`).pipe(
      tap(() => {
        this.events.cart.remove.emit(order);
      })
    );
  }

  public submit() {
    return this.http.post(`${environment.URL.API}/fuel/admin/cart/submit/`, {}).pipe(
      tap(() => {
        this.events.cart.submit.emit();
      })
    );
  }

  public confirmOfferChanges(order: Order) {
    return this.http.put(`${environment.URL.API}/fuel/admin/cart/${order.uuid}/confirm-offer-changes/`, {}).pipe(
      map((resp: OrderStruct) => {
        return order.update(resp);
      }),
    );
  }
}
