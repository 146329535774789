import { LocationStruct, Location } from '@geodata/models';
import { Proposal, ProposalStruct } from '../proposal';
import { Model } from '@evo/models';
import { IPAgent, IPAgentStruct } from '../../common';
import { Margin, MarginStruct } from '../margin';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface OfferStruct {
  date_from: string;
  date_to: string;
  timestamp: string;
  notes: string;
  margin: MarginStruct;
  ip_agent: IPAgentStruct;
  location: LocationStruct;
  proposal: ProposalStruct;
}

export class Offer extends Model<OfferStruct> {
  constructor (
    public dateFrom: Moment,
    public dateTo: Moment,
    public timestamp: Moment,
    public notes: string,
    public intoPlaneAgent: IPAgent,
    public location: Location,
    public margin: Margin,
    public proposal: Proposal,
  ) {
    super();
  }

  public static create(data: OfferStruct) {
    return new Offer(
      data.date_from ? moment(data.date_from) : null,
      data.date_to ? moment(data.date_to) : null,
      data.timestamp ? moment(data.timestamp) : null,
      data.notes || '',
      data.ip_agent ? IPAgent.create(data.ip_agent) : null,
      data.location ? Location.create(data.location) : null,
      data.margin ? Margin.create(data.margin) : null,
      data.proposal ? Proposal.create(data.proposal) : null,
    );
  }

  public override toString(): string {
    return `${this.intoPlaneAgent.name} - ${this.location.codes}`;
  }

  public toJSON() {
    return {
      date_from: this.dateFrom ? this.dateFrom.toISOString() : null,
      date_to: this.dateTo ? this.dateTo.toISOString() : null,
      timestamp: this.timestamp ? this.timestamp.toISOString() : null,
      margin: this.margin ? this.margin.toJSON() : null,
      notes: this.notes,
      ip_agent: this.intoPlaneAgent ? this.intoPlaneAgent.toJSON() : null,
      location: this.location ? this.location.toJSON() : null,
      proposal: this.proposal ? this.proposal.toJSON() : null
    };
  }

  public update(data: OfferStruct) {
    return this;
  }
}
