import { isNumber, isString } from '@evo/utils/types';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Model } from '@evo/models';

export interface MonthStruct {
  date_start: string;
  date_end: string;
  days: number;
  name: string;
  short: string;
  label: string;
  year: number;
  month: number;
}

export class Month extends Model<MonthStruct> {
  constructor (
    public dateStart: Moment,
    public dateEnd: Moment,
    public days: number,
    public name: string,
    public short: string,
    public label: string,
    public year: number,
    public month: number,
  ) {
    super();
  }

  public static create(data: MonthStruct) {
    return new Month(
      data.date_start ? moment(data.date_start) : null,
      data.date_end ? moment(data.date_end) : null,
      isNumber(data.days) ? data.days : null,
      isString(data.name) ? data.name : null,
      isString(data.short) ? data.short : null,
      isString(data.label) ? data.label : null,
      isNumber(data.year) ? data.year : null,
      isNumber(data.month) ? data.month : null
    );
  }

  public override get pk() {
    return this.dateStart.format('YYYY-MM-DD');
  }

  public override toString(): string {
    return this.label;
  }

  public toJSON() {
    return {
      date_start: this.dateStart ? this.dateStart.format('YYYY-MM-DD') : null,
      date_end: this.dateEnd ? this.dateEnd.format('YYYY-MM-DD') : null,
      days: this.days,
      name: this.name,
      short: this.short,
      label: this.label,
      year: this.year,
      month: this.month
    };
  }

  public update(data: MonthStruct) {
    this.dateStart = data.date_start ? moment(data.date_start) : this.dateStart;
    this.dateEnd = data.date_end ? moment(data.date_end) : this.dateEnd;
    this.days = isNumber(data.days) ? data.days : this.days;
    this.name = isString(data.name) ? data.name : this.name;
    this.short = isString(data.short) ? data.short : this.short;
    this.label = isString(data.label) ? data.label : this.label;
    this.year = isNumber(data.year) ? data.year : this.year;
    this.month = isNumber(data.month) ? data.month : this.month;
    return this;
  }
}
