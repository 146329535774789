import {PhoneNumber, PhoneNumberUtil} from 'google-libphonenumber';
import {AbstractControl} from '@angular/forms';

export function phone(message) {
  return (control: AbstractControl) => {
    let util = PhoneNumberUtil.getInstance(),
      value = control.value,
      number: PhoneNumber = null;

    if (!value) {
      return null;
    }

    try {
      number = util.parseAndKeepRawInput(value)
    } catch (e) {
      return {phone: message};
    }

    return util.isValidNumber(number) ? null : {phone: message};
  };
}


export function usaPhone(control: AbstractControl) {
  let phone = (control.value + ''),
    regex = /^\d{10}$/;
  return regex.test(phone) ? null : {phone: 'Enter valid phone number.'};
}
