<ngb-toast
  *ngFor="let toast of toast.toasts"
  [autohide]="false"
  [header]="toast.title"
  [ngClass]="toast.classlist"
  (hidden)="remove(toast)"
  (click)="remove(toast)">

  <ng-template [ngIf]="toast.template">
    <ng-template [ngTemplateOutlet]="toast.template"></ng-template>
  </ng-template>

  <ng-template [ngIf]="!toast.template">
    <div [innerHTML]="toast.message"></div>
  </ng-template>

</ngb-toast>
