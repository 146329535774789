import { PlatformMonthlyStats, PlatformMonthlyStatsStruct } from '@fuel/models';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { Month } from '@common/models/month/month';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class PlatformsMonthlyStatsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(month?: Month | string) {
    let params = month ? { month: toPrimaryKey(month) } : {};
    return this.http.get(`${environment.URL.API}/fuel/admin/platform-monthly-stats/`, { params }).pipe(
      map((resp: PlatformMonthlyStatsStruct[]) => {
        return resp.map((s) => PlatformMonthlyStats.create(s));
      }),
    );
  }
}
