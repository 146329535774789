export enum CODES_FILTER_MODES {
  NOT_VERIFIED = 'NOT_VERIFIED',
  NOT_FULL_MATCH = 'NOT_FULL_MATCH',
  LOCATION_DUPLICATES = 'LOCATION_DUPLICATES',
  LOCATION_NOT_SET = 'LOCATION_NOT_SET'
}

export const CODES_FILTER_MODES_LIST = [{
  name: 'Not Verified',
  code: CODES_FILTER_MODES.NOT_VERIFIED,
}, {
  name: 'Not Full Match',
  code: CODES_FILTER_MODES.NOT_FULL_MATCH,
}, {
  name: 'Location Duplicates',
  code: CODES_FILTER_MODES.LOCATION_DUPLICATES,
}, {
  name: 'Location Not Set',
  code: CODES_FILTER_MODES.LOCATION_NOT_SET
}];
