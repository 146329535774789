import {Permission, PermissionStruct} from '../permission';

export type PermissionSets = { [key: string]: PermissionSet };

export class PermissionSet {

  constructor(
    public name: string,
    public permissions: Permission[],
  ) {

  }

  public static create(name: string, perms: PermissionStruct[]) {
    return new PermissionSet(name, perms.map((i) => Permission.create(i)));
  }

  public toJSON() {
    return this.permissions.map((i) => i.toJSON());
  }

  public get length() {
    return this.permissions.length;
  }

  public isFull() {
    return this.length == 4;
  }

  public has(perm: Permission) {
    return !!this.permissions.find((p) => {
      return p.pk == perm.pk;
    });
  }

  public add(permission: Permission) {
    this.permissions.push(permission);
  }

  public remove(permission: Permission) {
    let index = this.permissions.findIndex((p) => {
      return p.id == permission.id;
    });
    if (index > -1) {
      this.permissions.splice(index, 1);
    }
  }
}
