import { Model } from '@evo/models';
import { PermissionStruct } from '../permission';
import { PermissionSet } from '../permissions-set';

export interface ContentTypeStruct {
  id: number;
  module: string;
  model: string;
  is_used: boolean;
  permissions: { [key: string]: PermissionStruct[] };
}

export class ContentType extends Model<ContentTypeStruct> {

  constructor (
    public id: number,
    public module: string,
    public model: string,
    public isUsed: boolean,
    public sets: PermissionSet[] = [],
  ) {
    super();
  }

  public static create(data?: ContentTypeStruct) {
    if (!data) {
      data = {} as any;
    }

    return new ContentType(
      data.id || null,
      data.module || '',
      data.model || '',
      !!data.is_used,
      data.permissions ? Object.keys(data.permissions).map((name) => {
        let perms = data.permissions[name];
        return PermissionSet.create(name, perms);
      }) : [],
    );
  }

  public toJSON(): ContentTypeStruct {

    let perms = {};
    this.sets.forEach((set) => {
      perms[set.name] = set.toJSON();
    });

    return {
      id: this.id,
      module: this.module,
      model: this.model,
      is_used: this.isUsed,
      permissions: perms,
    };
  }

  public update(data: ContentTypeStruct): this {
    this.id = data.id || this.id;
    this.module = data.module || this.module;
    this.model = data.model || this.model;
    this.isUsed = 'boolean' == typeof data.is_used ? data.is_used : this.isUsed;
    this.sets = data.permissions ? Object.keys(data.permissions).map((name) => {
      let perms = data.permissions[name];
      return PermissionSet.create(name, perms);
    }) : this.sets;
    return this;
  }

}
