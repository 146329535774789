import { AutocompleteControlComponent } from '../autocomplete-control/autocomplete.control.component';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AbstractControl, NgControl } from '@angular/forms';
import { isUndefinedOrNull } from '@evo/utils/types';
import { Observable, of } from 'rxjs';


@Component({
  selector: 'app-dependent-autocomplete-control',
  templateUrl: './dependent-autocomplete-control.component.html',
  styleUrls: ['./dependent-autocomplete-control.component.scss'],
})
export class DependentAutocompleteControlComponent extends AutocompleteControlComponent implements OnInit {

  @Input()
  public hostControl: AbstractControl;

  @Input()
  public override callback: any | ((term: string, value: any) => any);

  @Input()
  public forceDisable: (v: any) => boolean;

  constructor (
    public override ngControl: NgControl,
    protected override elementRef: ElementRef,
    protected override renderer: Renderer2,
    protected override cdr: ChangeDetectorRef
  ) {
    super(ngControl, elementRef, renderer, cdr);
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.subscriptions = [
      this.hostControl.valueChanges.subscribe((value) => {
        this.onLeadingValueChange(value);
      }),
    ];
    this.onLeadingValueChange(this.hostControl.value);
  }

  private onLeadingValueChange(value) {
    let force = false;
    if (this.forceDisable) {
      force = this.forceDisable(value);
    }
    let disable = force || !value;
    this.setDisabledState(disable);
    if (disable && (this.value || this.ngControl.value)) {
      this.value = null;
    }
    let emitEvent = !(isUndefinedOrNull(this.value) && isUndefinedOrNull(this.ngControl.value));
    this.ngControl.control.setValue(this.value, { emitEvent: emitEvent, emitModelToViewChange: emitEvent });
  }

  public override search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        let value = this.hostControl.value;
        let results = this.callback(term, value);
        return results instanceof Observable ? results : of(results);
      }),
    );
  };

  public override setDisabledState(isDisabled: boolean): void {
    this.isDisabled = this.ngControl.disabled ? true : isDisabled;
  }
}
