import { Model } from '@evo/models';

export interface MarginStruct {
  id: number;
  value: number;
  type: string;
}

export class Margin extends Model<MarginStruct> {
  constructor (
    public id: number,
    public value: number,
    public type: string,
  ) {
    super();
  }

  public static create(data: MarginStruct) {
    return new Margin(
      data.id || null,
      data.value || 0,
      data.type || '',
    );
  }

  public override toString() {
    return `${this.value} ${this.type}`;
  }

  public toJSON() {
    return {
      id: this.id,
      value: this.value,
      type: this.type,
    };
  }

  public update(data: MarginStruct) {
    this.id = data.id || this.id;
    this.value = data.value || this.value;
    this.type = data.type || this.type;
    return this;
  }
}
