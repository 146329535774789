import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isNumber, toInteger } from '@evo/utils/types';
import { FactoryProvider } from '@angular/core';

export const MONTHS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export class NgbDateMonthParserFormatter extends NgbDateParserFormatter {

  public parse(value: string) {
    if (!value) {
      return null;
    }
    const parts = value.trim().split(' '),
      day = parts[0],
      year = parts[2],
      month = parts[1] ? (parts[1] + '').toLowerCase() : '',
      index = MONTHS.indexOf(month);

    if (parts.length != 3 || !isNumber(day) || !isNumber(year) || index == -1) {
      return null;
    }

    return {
      year: toInteger(year),
      month: index + 1,
      day: toInteger(day)
    };
  }

  public format(date: NgbDateStruct | null) {
    if (!date) {
      return '';
    }
    if (date.year && date.month && date.day) {
      let m = MONTHS[date.month - 1],
        n = m[0].toUpperCase() + m.slice(1);
      return `${date.day} ${n} ${date.year}`;
    }
    return '';
  }
}

export function dateParserFormatterFactory() {
  return new NgbDateMonthParserFormatter();
}

export const NgbDateParserFormatterProvider: FactoryProvider = {
  provide: NgbDateParserFormatter,
  useFactory: dateParserFormatterFactory,
};
