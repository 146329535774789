import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import {empty} from '../../utils';

export function oneOfFieldsIsRequired(fields: string[], message: string) {
  return (g: FormGroup): ValidatorFn => {
    let valid = false;

    for (let i = 0; i < fields.length; i++) {
      let c = g.get(fields[i]) as AbstractControl;
      if (!empty(c.value)) {
        valid = true;
        break;
      }
    }

    let errors = valid ? null : {required: message.replace('|FIELDS|', fields.join(','))};

    fields.forEach((name) => {
      let c = g.get(name) as AbstractControl;
      c.setErrors(errors);
    });

    return null;
  };
}
