import { isNumber, isNumberOrNull } from '@evo/utils/types';
import { formatNumber } from '@angular/common';
import { Model } from '@evo/models';

export class Percent extends Model<number> {
  constructor (
    public value: number,
  ) {
    super();
  }

  public static create(value?: number) {
    return new Percent(isNumber(value) ? value : null);
  }

  public override toString(): string {
    return this.format();
  }

  public format(digitsInfo = '1.3-3', locale = 'en') {
    return formatNumber(this.value * 100, locale, digitsInfo) + ' %';
  }

  public toJSON() {
    return this.value;
  }

  public update(value: number) {
    this.value = isNumberOrNull(value) ? value : null;
    return this;
  }
}
