import { Collection } from '@evo/models';
import { Salutation, SalutationStruct } from './salutation';

export class SalutationsCollection extends Collection<Salutation> {

  public static create(data: SalutationStruct[]) {
    return new SalutationsCollection(data.map((item) => Salutation.create(item)));
  }

  public search(term: string) {
    term = term.toLowerCase();
    return this.items.filter((item: Salutation) => {
      return item.name.toLowerCase().indexOf(term) > -1;
    }).slice(0, 10);
  }
}
