import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from './environments/environment';
import {MainModule} from './app/main/main.module';
import {enableProdMode} from '@angular/core';

if (environment.production) {
  enableProdMode();
}

let platform = platformBrowserDynamic();

platform.bootstrapModule(MainModule)
  .catch((e) => {
    console.error(e);
  });
