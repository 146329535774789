import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface JwtStruct {
  token?: string;
  delta?: number;
  now?: string;
  exp?: string;
}

export class Jwt extends Model<JwtStruct> {
  constructor (
    public token: string,
    public delta: number,
    public now?: Moment,
    public exp?: Moment,
  ) {
    super();
  }

  public static create(data: JwtStruct = {}) {
    return new Jwt(
      data.token || '',
      data.delta || 0,
      data.now ? moment(data.now) : undefined,
      data.exp ? moment(data.exp) : undefined,
    );
  }

  public getRefreshTimeout() {
    if (!this.now || !this.exp) {
      throw Error('Token now or exp not set.');
    }
    let serverNow = this.now.toDate(),
      serverExp = this.exp.toDate();
    return (serverExp.getTime() - serverNow.getTime());
  }

  public toJSON(): JwtStruct {
    return {
      token: this.token,
      delta: this.delta,
      now: this.now ? this.now.toISOString() : null,
      exp: this.exp ? this.exp.toISOString() : null,
    };
  }

  public update(data: JwtStruct) {
    this.token = data.token || this.token;
    this.delta = data.delta || this.delta;
    this.exp = data.exp ? moment(data.exp) : this.exp;
    this.now = data.now ? moment(data.now) : this.now;
    return this;
  }

}
