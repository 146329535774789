import { Pagination, PaginationStruct } from '@common/models';
import { environment } from '@environments/environment';
import { IPAgentStruct, IPAgent } from '../../../models';
import { toPrimaryKey } from '@evo/utils/helpers';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Moment } from 'moment';


@Injectable({
  providedIn: 'root',
})
export class IPAgentsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(page: number, orderBy = 'name', pagination?: Pagination<IPAgentStruct, IPAgent>) {
    return this.http.get(`${environment.URL.API}/fuel/admin/ip-agents/`).pipe(
      map((resp: PaginationStruct<IPAgentStruct>) => {
        return pagination ? pagination.update(resp) : Pagination.create(resp, IPAgent);
      }),
    );
  }

  public get(ipagent: IPAgent | string) {
    let uuid = toPrimaryKey(ipagent);
    return this.http.get(`${environment.URL.API}/fuel/admin/ip-agents/${uuid}/`).pipe(
      map((resp: IPAgentStruct) => {
        return IPAgent.create(resp);
      }),
    );
  }

  public search(term: string) {
    let params = { term };
    return this.http.get(`${environment.URL.API}/fuel/admin/ip-agents/search/`, { params }).pipe(
      map((resp: IPAgentStruct[]) => {
        return resp.map((a) => IPAgent.create(a));
      }),
    );
  }
}
