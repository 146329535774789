import {Observable} from 'rxjs';

declare type CallBack = () => Observable<any>;

export class NgbConfirmConfig {

  constructor(
    public title: string,
    public message: string,
    public reject: string,
    public resolve: string,
    public callback: CallBack,
    public showError: boolean
  ) {
  }

  public static create(
    title: string,
    message: string,
    reject = 'Cancel',
    resolve = 'Delete',
    callback: CallBack,
    showError = true
  ) {
    return new NgbConfirmConfig(title, message, reject, resolve, callback, showError);
  }
}
