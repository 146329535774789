import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const hours: number = Math.floor(minutes / 60);


    if (hours) {
      let m: string | number = minutes % 60;
      m = m > 9 ? m : '0' + m;
      return hours + ':' + m;
    } else {
      let s: string | number = seconds % 60;
      s = s > 9 ? s : '0' + s;
      return minutes + '.' + s;
    }
  }
}
