import { environment } from '@environments/environment';
import { OfferStruct, Offer } from '../../../models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class CurrentFuelPricesService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(orderBy = 'ip_agent', uplift?: number) {
    let params = {
      order_by: orderBy
    };
    if (uplift) {
      params['uplift'] = uplift;
    }
    return this.http.get(`${environment.URL.API}/fuel/admin/offers/`, { params }).pipe(
      map((resp: OfferStruct[]) => {
        return resp.map((item) => Offer.create(item));
      })
    );
  }

  public getOrderFuelPrice(uuid: string) {
    return this.http.get(`${environment.URL.API}/fuel/admin/offers/get-by-data/${uuid}/`).pipe(
      map((resp: OfferStruct) => {
        return Offer.create(resp);
      })
    );
  }
}
