import {toInteger} from './cast';

export function isObject(value: any): value is Object {
  return typeof value === 'object' && !(value == null) && !Array.isArray(value);
}

export function isFunction(value): value is Function {
  return typeof value === 'function';
}

export function isEmpty(value: any): boolean {
  return value == null || value.length === 0;
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isNull(value: any): value is null {
  return typeof value === 'object' && !value; // || value === null;
}

export function isUndefined(value: any): value is undefined {
  return 'undefined' == typeof value;
}

export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean';
}

export function isArray(value: any) {
  if (Array.isArray) {
    return Array.isArray(value);
  }
  return Object.prototype.toString.call(value) === '[object Array]';
}

/**************************************************************************************************/
export function isDefined(v: any) {
  return !isUndefined(v);
}

export function isUndefinedOrNull(v: any) {
  return isUndefined(v) ? true : isNull(v);
}

export function isStringOrNull(v: any) {
  return isString(v) ? true : isNull(v);
}

export function isNumberOrNull(v: any) {
  return isNumber(v) ? true : isNull(v);
}

export function isBooleanOrNull(v: any) {
  return isBoolean(v) ? true : isNull(v);
}

export function isArrayOrNull(v: any){
  return isArray(v) ? true : isNull(v);
}
