<div [class.is-invalid]="!this.formGroup.disabled && isInvalid">
  <div ngbDropdown
       [autoClose]="false"
       #dropdown="ngbDropdown"
       class="input-group"
       [class.input-group-sm]="size == 'sm'"
       [class.input-group-lg]="size == 'lg'">
    <input type="text" class="form-control"
           #input random-name
           [empty]="null"
           (input)="onInput($event)"                           
           [placeholder]="placeholder"
           [class.is-invalid]="!this.formGroup.disabled && isInvalid"
           [class.is-valid]="!this.formGroup.disabled && isValid">
    <button type="button" class="btn" #toogle
            [class.btn-outline-danger]="!this.formGroup.disabled && isInvalid"
            [class.btn-outline-success]="!this.formGroup.disabled && isValid"
            [class.btn-outline-dark]="this.formGroup.pristine || this.formGroup.disabled"
            style="width: 80px !important;" ngbDropdownToggle
            [disabled]="this.formGroup.disabled">
      {{this.unitsControl.value ? this.unitsControl.value.code : 'Select...'}}
    </button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem *ngFor="let unit of units.getAll();" type="button"
              (keydown.enter)="unitsSelection($event, unit)"
              (click)="unitsSelection($event, unit)">{{unit.code}}</button>
    </div>
  </div>
</div>
<small *ngIf="errors.length" class="invalid-feedback">{{errors[0]}}</small>
