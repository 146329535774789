export enum NOTE_PERMISSIONS {
  CREATE = 'hr.add_note',
  UPDATE = 'hr.change_note',
  DELETE = 'hr.delete_note',
  VIEW = 'hr.view_note'
}

export enum EVALUATION_PERMISSIONS {
  CREATE = 'hr.add_evaluation',
  UPDATE = 'hr.change_evaluation',
  DELETE = 'hr.delete_evaluation',
  VIEW = 'hr.view_evaluation'
}

export enum DEPARTMENT_PERMISSIONS {
  CREATE = 'hr.add_department',
  UPDATE = 'hr.change_department',
  DELETE = 'hr.delete_department',
  VIEW = 'hr.view_department'
}

export enum FACTOR_PERMISSIONS {
  CREATE = 'hr.add_factor',
  UPDATE = 'hr.change_factor',
  DELETE = 'hr.delete_factor',
  VIEW = 'hr.view_factor'
}

export enum EMPLOYEE_PERMISSIONS {
  CREATE = 'authentication.add_employee',
  UPDATE = 'authentication.change_employee',
  DELETE = 'authentication.delete_employee',
  VIEW = 'authentication.view_employee'
}

