import { Observer } from 'rxjs';

export function observer<T>(next?: (value: T) => void, error?: (err: any) => void, complete?: () => void): Partial<Observer<T>> {
  let observer = {};
  next && (observer['next'] = next);
  error && (observer['error'] = error);
  complete && (observer['complete'] = complete);
  return observer;
}

export const o = observer;