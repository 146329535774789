import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '@environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

declare var gtag: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {

  public static SCRIPT_ID = 'ga_gtag';
  private renderer: Renderer2;
  public isEnabled = false;

  constructor (
    private factory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platform,
  ) {
    this.renderer = factory.createRenderer(document, null);
  }

  public startTracking() {
    if (!isPlatformBrowser(this.platform) || !environment.GA_TRACKING_CODE) {
      return;
    }

    if (this.isEnabled || document.getElementById(GoogleAnalyticsService.SCRIPT_ID)) {
      return;
    }

    const script = this.renderer.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GA_TRACKING_CODE}`;
    script.type = 'text/javascript';
    script.id = GoogleAnalyticsService.SCRIPT_ID;
    script.async = true;

    this.renderer.appendChild(this.document.head, script);

    const data = this.renderer.createElement('script');
    const text = this.renderer.createText(
      `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}`
    );

    this.renderer.appendChild(data, text);
    this.renderer.appendChild(this.document.head, data);

    this.isEnabled = true;

    gtag('js', new Date());
    gtag('config', environment.GA_TRACKING_CODE);
  }

  public toKeyValue(map) {
    return (map.size > 0)
      ? Array.from(map).reduce((obj, [key, value]) => Object.defineProperty(obj, key, { value, enumerable: true }), {})
      : undefined;
  }

  public event(action, category, label, value, interaction) {
    if (!this.isEnabled) {
      return;
    }
    const opt = new Map();
    if (category) {
      opt.set('event_category', category);
    }
    if (label) {
      opt.set('event_label', label);
    }
    if (value) {
      opt.set('value', value);
    }
    if (interaction !== undefined) {
      opt.set('interaction', interaction);
    }
    const params = this.toKeyValue(opt);
    if (params) {
      gtag('event', action, params);
    } else {
      gtag('event', action);
    }
  }

  public pageView(path: string, title: string, location: string = null, options: any = null) {
    if (!this.isEnabled) {
      return;
    }
    const opt = new Map([['page_path', path]]);
    if (title) {
      opt.set('page_title', title);
    }
    if (location || this.document) {
      opt.set('page_location', (location || this.document.location.href));
    }
    if (options) {
      Object.entries<any>(options).map(([key, value]) => opt.set(key, value));
    }
    gtag('config', environment.GA_TRACKING_CODE, this.toKeyValue(opt));
  }


  public appView(screen, appName, appId, appVersion, installerId) {
    if (!this.isEnabled) {
      return;
    }
    const opt = new Map([['screen_name', screen], ['app_name', appName]]);
    if (appId) {
      opt.set('app_id', appId);
    }
    if (appVersion) {
      opt.set('app_version', appVersion);
    }
    if (installerId) {
      opt.set('app_installer_id', installerId);
    }
    gtag('event', 'screen_view', this.toKeyValue(opt));
  }

  public set(...options) {
    if (!this.isEnabled) {
      return;
    }
    gtag('set', ...options);
  }

  public exception(description, fatal) {
    if (!this.isEnabled) {
      return;
    }
    const opt = new Map();
    if (description) {
      opt.set('description', description);
    }
    if (fatal) {
      opt.set('fatal', fatal);
    }
    const params = this.toKeyValue(opt);
    if (params) {
      gtag('event', 'exception', params);
    } else {
      gtag('event', 'exception');
    }
  }
}
