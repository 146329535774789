import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { CountControl, ModelControl } from '../../../../../evo/forms';
import { Units, UnitsCollection } from '../../../models';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { controlErrors } from '@evo/utils/html';
import { NgxMaskService } from 'ngx-mask';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-count-control',
  templateUrl: './count-control.component.html',
  styleUrls: ['./count-control.component.scss'],
})
export class CountControlComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('input', { static: true })
  public input: ElementRef;

  @ViewChild('dropdown', { static: true })
  public dropdown: NgbDropdown;

  @ViewChild('toogle', { static: true })
  public toogle: ElementRef;

  @Input()
  public size: undefined | 'lg' | 'sm' | 'xl';

  @Input()
  public pattern = '9999999';

  @Input()
  public units: UnitsCollection;

  @Input()
  public placeholder = '';

  public formGroup: CountControl;

  public valueControl: FormControl;
  public unitsControl: ModelControl;

  public isValid = false;
  public isInvalid = false;
  public isDisabled = false;

  public errors: string[] = [];

  private subscriptions: Subscription[] = [];

  constructor (
    public controlContainer: ControlContainer,
    private ngxMask: NgxMaskService,
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
  }

  public ngOnInit() {
    this.formGroup = this.controlContainer.control as CountControl;
    if (!(this.formGroup instanceof CountControl)) {
      throw new TypeError('For CountControlComponent FromGroup should be an instance of CountControl');
    }
    this.valueControl = this.formGroup.valueControl;
    this.unitsControl = this.formGroup.unitsControl;

    this.subscriptions = [
      this.formGroup.valueChanges.subscribe(() => {
        let data = controlErrors(this.formGroup);
        this.isInvalid = data[0];
        this.isValid = data[1];
        this.errors = data[2];
      })
    ];
  }

  public ngAfterViewInit() {
    let $input = this.input.nativeElement,
      $el = this.element.nativeElement,
      id = $el.getAttribute('id');
    if (id) {
      this.renderer.removeAttribute($el, 'id');
      this.renderer.setAttribute($input, 'id', id);
    }
    this.input.nativeElement.value = this.valueControl.value;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onInput($e: Event) {
    let v: any = this.ngxMask.applyMask(($e.target as HTMLInputElement).value, this.pattern);
    v = parseInt(v);
    if (isNaN(v)) {
      v = null;
    }
    ($e.target as HTMLInputElement).value = v;
    if (v != this.valueControl.value) {
      this.valueControl.pristine && this.valueControl.markAsDirty({ emitEvent: false });
      this.valueControl.setValue(v);
    }
  }

  public unitsSelection($e: Event, unit: Units) {
    $e.preventDefault();
    $e.stopPropagation();
    this.unitsControl.setValue(unit);
    if (this.dropdown.isOpen()) {
      this.dropdown.close();
    }
    (this.toogle?.nativeElement as HTMLButtonElement).focus();
  }
}
