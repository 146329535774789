import { Model } from '@evo/models';
import { AircraftType, AircraftTypeStruct } from "../aircraft-type";
import { AircraftEngineType, AircraftEngineTypeStruct } from "../aircraft-engine-type";
import { WakeTurbulenceCategory, WakeTurbulenceCategoryStruct } from "../wake-turbulence-category";

export class DesignatorStruct {
  uuid: string;
  name: string;

  engine_count: string;
  is_military: boolean;
  wtg: string;

  wtc?: WakeTurbulenceCategoryStruct;
  type?: AircraftTypeStruct;
  engine_type?: AircraftEngineTypeStruct;

  wtc_id?: string;
  type_id?: string;
  engine_type_id?: string;
}

export class Designator extends Model<DesignatorStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public engineCount: string,
    public isMilitary: boolean,
    public wtg: string,
    public wtc: WakeTurbulenceCategory,
    public type: AircraftType,
    public engineType: AircraftEngineType
  ) {
    super();
  }

  public static create(data?: DesignatorStruct) {
    if (!data) {
      data = {} as DesignatorStruct;
    }
    return new Designator(
      data.uuid || null,
      data.name || null,
      data.engine_count || null,
      'boolean' == typeof data.engine_count ? data.engine_count : false,
      data.wtg || null,
      data.wtc ? WakeTurbulenceCategory.create(data.wtc) : null,
      data.type ? AircraftType.create(data.type) : null,
      data.engine_type ? AircraftEngineType.create(data.engine_type) : null
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): DesignatorStruct {
    let json: DesignatorStruct = {
      uuid: this.uuid,
      name: this.name,
      engine_count: this.engineCount,
      is_military: this.isMilitary,
      wtg: this.wtg
    };
    if (this.type) {
      json.type_id = this.type.uuid;
    }
    if (this.engineType) {
      json.engine_type_id = this.engineType.uuid;
    }
    if (this.wtc) {
      json.wtc_id = this.wtc.uuid;
    }
    return json
  }

  public update(data: DesignatorStruct): this {
    this.uuid = 'string' == typeof data.uuid ? data.uuid : this.uuid;
    this.name = 'string' == typeof data.name ? data.name : this.name;
    return this;
  }

}
