import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface DisclaimerStruct {
  uuid: string;
  text: string;
  date_created: string;
}

export class Disclaimer extends Model<DisclaimerStruct> {
  constructor (
    public uuid: string,
    public text: string,
    public dateCreated: Moment,
  ) {
    super();
  }

  public static create(data?: DisclaimerStruct) {
    if (!data) {
      data = {} as DisclaimerStruct;
    }
    return new Disclaimer(
      data.uuid || null,
      data.text || null,
      data.date_created ? moment(data.date_created) : null,
    );
  }

  public override toString(): string {
    return `Disclaimer (${this.uuid})`;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      text: this.text,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
    };
  }

  public update(data: DisclaimerStruct) {
    this.uuid = data.uuid || this.uuid;
    this.text = data.text || this.text;
    this.dateCreated = data.date_created ? moment(data.date_created) : this.dateCreated;
    return this;
  }
}
