import {DefaultValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Directive, forwardRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'input[empty],textarea[empty]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmptyValueDirective),
    multi: true
  }]
})
export class EmptyValueDirective extends DefaultValueAccessor {

  @Input()
  public empty = null;

  @HostListener('input', ['$event'])
  public input($e: Event) {
    let $el = $e.target as HTMLInputElement | HTMLTextAreaElement;
    if (!$el.value) {
      this.onChange(this.empty);
    }
  }

  public override writeValue(value: any) {
    if (!value && !(value === this.empty)) {
      value = this.empty;
      this.onChange(value);
    }
    super.writeValue(value);
  }
}
