import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { CountriesCollection } from '@geodata/models';
import * as phonenumbers from 'google-libphonenumber';
import { PhoneCountryCode } from '@common/models';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PhonenumbersService {

  public codes: PhoneCountryCode[] = [];
  public empty: PhoneCountryCode[] = [];
  public util = PhoneNumberUtil.getInstance();

  constructor () {
    let meta = phonenumbers['metadata']['countryToMetadata'];
    let countryCodes = Object.keys((meta)).filter((key: string) => /^[A-Z]{2}$/.test(key));
    this.codes = countryCodes.map((code) => {
      let v = meta[code],
        result = PhoneCountryCode.create({ code: code, number: v[10] });
      if (CountriesCollection.TOP.indexOf(code) > -1) {
        this.empty.push(result);
      }
      return result;
    });
    this.empty.sort(PhonenumbersService.sort);
    this.codes.sort(PhonenumbersService.sort);
  }

  private static sort(a, b) {
    let anum = a.number + '',
      bnum = b.number + '';
    if (anum > bnum) {
      return 1;
    } else if (anum < bnum) {
      return -1;
    }
    return 0;
  }

  public search(term: string | number) {
    term = term ? (term + '').toString().toUpperCase() : '';
    if (term.charAt(0) == '+') {
      if (term.charAt(1) && /^[0-9]$/.test(term.charAt(1))) {
        term = term.slice(1);
      } else {
        term = '';
      }
    }
    if (!term) {
      return this.empty.slice(0, 10);
    }
    let regex = new RegExp('^' + term),
      codeRegex = new RegExp('^' + term);
    return this.codes.filter((code) => {
      return regex.test(code.number + '') || codeRegex.test(code.code.toUpperCase());
    }).slice(0, 10);
  }

  public getMaxLength(prefix: PhoneCountryCode | string, number: string) {
    if (prefix instanceof PhoneCountryCode) {
      let phone;

      try {
        phone = this.util.parseAndKeepRawInput(prefix.number + number, prefix.code);
      } catch (e) {
        return 15 - (prefix.code + '').length;
      }
    } else {
      prefix = (prefix + '').charAt(0) == '+' ? prefix.slice(1) : prefix + '';
      return 15 - prefix.length;
    }

    let type = PhoneNumberType.UNKNOWN,
      meta = this.util['getMetadataForRegion'](prefix.code),
      desc = PhoneNumberUtil['getNumberDescByType_'](meta, type);

    return Math.max(...desc.possibleLengthArray());

  }

  public getPrefix(phone: string) {
    phone = phone ? (phone + '').trim().toUpperCase() : '';
    if (phone[0] == '+') {
      phone = phone.slice(1);
    }
    return this.codes.find((c) => {
      let regex = new RegExp('^' + c.number);
      return regex.test(phone) || phone == c.code;
    });
  }

  public getPrefixForce(prefix: string | number) {
    prefix = prefix ? (prefix + '').trim() : '';
    if (!prefix) {
      return this.empty[0];
    }
    let regex = new RegExp('^' + prefix);
    return this.codes.find((c) => {
      return /^[A-Z]*$/i.test(prefix) ? regex.test(c.code) : regex.test(c.number + '');
    });
  }

}
