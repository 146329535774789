import { Model } from '@evo/models';

export interface PhoneCountryCodeStruct {
  code: string;
  number: number;
}

export class PhoneCountryCode extends Model<PhoneCountryCodeStruct> {
  constructor (
    public code: string,
    public number: number,
  ) {
    super();
  }

  public static create(data: PhoneCountryCodeStruct) {
    return new PhoneCountryCode(
      data.code || null,
      data.number || null,
    );
  }

  public toJSON() {
    return {
      code: this.code,
      number: this.number,
    };
  }

  public override toString(): string {
    return `+${this.number} (${this.code})`;
  }

  public toPrefix(): string {
    return `+${this.number}`;
  }

  public update(data: PhoneCountryCodeStruct) {
    this.code = data.code || this.code;
    this.number = data.number || this.number;
    return this;
  }
}
