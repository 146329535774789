import { Model } from '@evo/models';
import { Stats, StatsStruct } from '..';

export interface ClientInvoicesStatsStruct {
  open_amount: StatsStruct;
  overdue_amount: StatsStruct;
  delta_due: StatsStruct;
}

export class ClientInvoicesStats extends Model<ClientInvoicesStatsStruct> {
  constructor (
    public openAmount: Stats,
    public overdueAmount: Stats,
    public deltaDue: Stats,
  ) {
    super();
  }

  public static create(data?: ClientInvoicesStatsStruct) {
    if (!data) {
      data = {} as ClientInvoicesStatsStruct;
    }
    return new ClientInvoicesStats(
      data.open_amount ? Stats.create(data.open_amount) : null,
      data.overdue_amount ? Stats.create(data.overdue_amount) : null,
      data.delta_due ? Stats.create(data.delta_due) : null,
    );
  }

  public static empty() {
    return ClientInvoicesStats.create();
  }

  public toJSON(): ClientInvoicesStatsStruct {
    return {
      open_amount: this.openAmount ? this.openAmount.toJSON() : null,
      overdue_amount: this.overdueAmount ? this.overdueAmount.toJSON() : null,
      delta_due: this.deltaDue ? this.deltaDue.toJSON() : null
    };
  }

  public update(data: any): this {
    return this;
  }
}
