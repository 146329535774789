import { ClientStats, ClientStatsStruct, Report, ReportItem, ReportItemStruct, ReportStruct } from '@fuel/models';
import { Pagination, PaginationStruct } from '@common/models';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class ReportsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(params: URLSearchParams, pagination?: Pagination<ReportStruct, Report>) {
    return this.http.get(`${environment.URL.API}/fuel/reports/?${params.toString()}`).pipe(
      map((data: PaginationStruct<ReportStruct>) => {
        return pagination ? pagination.update(data) : Pagination.create<ReportStruct, Report>(data, Report);
      }),
      catchError((e) => {
        return of(Pagination.empty<ReportStruct, Report>(Report));
      })
    );
  }

  public stats(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/reports/stats/?${params.toString()}`).pipe(
      map((resp: ClientStatsStruct) => {
        return ClientStats.create(resp);
      })
    );
  }

  public topStats(params: URLSearchParams, pagination?: Pagination<ReportItemStruct, ReportItem<any>>) {
    return this.http.get(`${environment.URL.API}/fuel/reports/top-stats/?${params.toString()}`).pipe(
      map((resp: PaginationStruct<ReportItemStruct>) => {
        return pagination ? pagination.update(resp) : Pagination.create<ReportItemStruct, ReportItem<any>>(resp, ReportItem);
      })
    );
  }

  public demoStats(param?: string): Observable<any> {
    let url = param ? `${environment.URL.API}/fuel/demo/stats/${param}/` : `${environment.URL.API}/fuel/demo/stats/`
    return this.http.get(url).pipe(
      map((resp: any) => {
        return param ? Pagination.create<ReportItemStruct, ReportItem<any>>(resp, ReportItem) : ClientStats.create(resp);
      })
    );
  }
}
