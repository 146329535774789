import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Model } from '@evo/models';

export function oneOfFieldsShouldEquals(fields: string[], value: Model<any> | any, message: string) {
  return (g: FormGroup): ValidatorFn => {
    let results: boolean[] = [];

    for (let i = 0; i < fields.length; i++) {
      let c = g.get(fields[i]) as AbstractControl;
      if (value instanceof Model) {
        results.push(value.equals(c.value));
      } else {
        results.push(value == c.value);
      }
    }
    let valid = results.indexOf(true) > -1;
    let errors = valid ? null : { required: (message + '').replace('|VALUE|', value) };

    fields.forEach((name) => {
      let c = g.get(name) as AbstractControl;
      c.setErrors(errors);
    });

    return null;
  };
}
