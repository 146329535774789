import { DestinationType, FlightType, SearchBatch, SearchBatchStruct } from '@fuel/models';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@main/services';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FuelSearchBatchesService {

  public current: SearchBatch;
  private subscriptions: Subscription[];

  constructor (
    private http: HttpClient,
    private events: EventsService
  ) {
    this.subscriptions = [
      this.events.logout.subscribe(() => {
        this.clearCurrentCache();
      }),
      this.events.fuel.currentBatchData.subscribe((data) => {
        this.current && this.current.update(data);
      })
    ];
  }

  public getCurrent() {
    if (this.current) {
      return of(this.current);
    }

    return this.http.get(`${environment.URL.API}/fuel/search-batches/current/`).pipe(
      map((resp: SearchBatchStruct) => {
        return SearchBatch.create(resp);
      }),
      tap((resp: SearchBatch) => {
        this.current = resp;
      })
    );
  }

  public removeCurrent() {
    this.clearCurrentCache();
    return this.http.delete(`${environment.URL.API}/fuel/search-batches/current/`).pipe(
      tap(() => {
        this.events.cart.clear.emit();
      })
    );
  }

  public clearCurrentCache() {
    delete this.current;
  }

  public setCurrentDivision(data: any) {
    return this.http.put(`${environment.URL.API}/fuel/search-batches/current/division/`, data).pipe(
      map((resp: SearchBatchStruct) => {
        return this.current.update(resp);
      })
    );
  }

  public removeCurrentDivision() {
    return this.http.delete(`${environment.URL.API}/fuel/search-batches/current/division/`).pipe(
      map((resp: any) => {
        return this.current.update(resp);
      })
    );
  }

  public setCurrentFlightType(type: FlightType) {
    let data = { flight_type_id: toPrimaryKey(type) };
    return this.http.put(`${environment.URL.API}/fuel/search-batches/current/flight-type/`, data).pipe(
      map((resp: SearchBatchStruct) => {
        return this.current.update(resp);
      })
    );
  }

  public setCurrentDestinationType(type: DestinationType) {
    let data = { destination_type_id: toPrimaryKey(type) };
    return this.http.put(`${environment.URL.API}/fuel/search-batches/current/destination-type/`, data).pipe(
      map((resp: SearchBatchStruct) => {
        return this.current.update(resp);
      })
    );
  }

  public activateCurrent() {
    return this.http.put(`${environment.URL.API}/fuel/search-batches/current/activate/`, {}).pipe(
      map((resp: SearchBatchStruct) => {
        return this.current.update(resp);
      })
    );
  }
}
