import { CurrencyStruct, Currency } from '../currency';
import { formatNumber } from '@angular/common';
import { Model } from '@evo/models';

export interface MoneyStruct {
  value: number;
  currency: CurrencyStruct;
}

export class Money extends Model<MoneyStruct> {
  constructor (
    public value: number,
    public currency: Currency,
  ) {
    super();
  }

  public static create(data?: MoneyStruct) {
    if (!data) {
      data = {} as MoneyStruct;
    }
    return new Money(
      data.value || 0,
      data.currency ? Currency.create(data.currency) : null,
    );
  }

  public override get pk() {
    return this.toString();
  }

  public override toString(): string {
    return this.format();
  }

  public format(digitsInfo = '1.2-2', locale = 'en') {
    let c = this.currency;
    if (!c) {
      return formatNumber(this.value, locale, digitsInfo);
    }
    let symbol = c.symbol ? c.symbol : c.code,
      value = formatNumber(this.value, locale, digitsInfo);

    if (c.symbolIsFirst) {
      return symbol + (c.symbolHasSpace ? ' ' : '') + value;
    } else {
      return value + (c.symbolHasSpace ? ' ' : '') + symbol;
    }
  }

  public toJSON() {
    return {
      value: this.value,
      currency: this.currency ? this.currency.toJSON() : null,
    };
  }

  public update(data: MoneyStruct) {
    this.value = data.value && (data.value as any) instanceof Number ? data.value : this.value;
    this.currency = data.currency ? Currency.create(data.currency) : this.currency;
    return this;
  }
}
