<ng-sidebar-container>
  <ng-sidebar [(opened)]="layout.sideBarOpened" [showBackdrop]="sideBarShowBackdrop" [animate]="sideBarAnimate"
              [closeOnClickOutside]="sideBarCloseOnClickOutside" [closeOnClickBackdrop]="sideBarCloseOnClickBackdrop" [mode]="sideBarMode">
    <app-sidebar-client></app-sidebar-client>
  </ng-sidebar>

  <div #container id="container" ng-sidebar-content>
    <ngx-loading-bar></ngx-loading-bar>
    <app-navbar-client></app-navbar-client>

    <div *ngIf="division.ordersDisabled" class="container-fluid bg-white py-2 border-bottom">

      <div *ngIf="division.orderStatus == ORDER.CREDIT_LIMIT_EXCEEDED" class="alert alert-danger m-0">
        <p><strong>Credit Limit Exceeded:</strong></p>
        <p class="mb-0">
          Your account is temporarily on hold, as we are awaiting payment on overdue invoices.<br />
          Please know that your business is very important to us and we look forward to continuing our business as fast as possible.
        </p>
      </div>

      <div *ngIf="division.orderStatus == ORDER.NO_SIGNUP_FORMS" class="alert alert-info m-0">
        <p><strong>Welcome to EVO Fuels!</strong></p>
        <p>
          You are now able to view EVO's pricing at over 3000 locations worldwide.<br />
          To start placing fuel orders, please take a few minutes to complete our sign up form.
        </p>
        <span>Did not receive a sign up form? Click <a [href]="SIGNUP_URL" target="_blank" class="text-primary">here</a> to request or email us at
          <a href="mailto:signup&#64;flyevo.com" class="text-primary">signup&#64;flyevo.com</a></span>
      </div>

    </div>

    <app-navigation></app-navigation>
    <router-outlet></router-outlet>
  </div>
</ng-sidebar-container>