import { Model } from '@evo/models';

export interface FlightTypeStruct {
  uuid: string;
  name: string;
  short: string;
  code: string;
}

export class FlightType extends Model<FlightTypeStruct> {
  constructor (
    public uuid: string,
    public name: string,
    public short: string,
    public code: string,
  ) {
    super();
  }

  public static create(data: FlightTypeStruct) {
    return new FlightType(
      data.uuid || null,
      data.name || null,
      data.short || null,
      data.code || null,
    );
  }

  public override toString(): string {
    return this.short;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      code: this.code,
      name: this.name,
      short: this.short,
    };
  }

  public update(data: FlightTypeStruct) {
    this.uuid = data.uuid || this.uuid;
    this.name = data.name || this.name;
    this.short = data.short || this.short;
    this.code = data.code || this.code;
    return this;
  }
}
