<div class="btn-group">
  <ng-container *ngFor="let item of choises; let last = last; let index = index;">
    <input type="radio" class="btn-check" autocomplete="off"
           [disabled]="isDisabled"
           [name]="uuid"
           [(ngModel)]="value"
           (ngModelChange)="writeValue($event)"
           [id]="index+'_'+uuid"
           [value]="valueFormatter(item)">
    <label [for]="index+'_'+uuid" class="btn btn-outline-primary">{{nameFormatter(item)}}</label>
  </ng-container>
</div>
