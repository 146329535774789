import { Pagination, PaginationStruct } from '@common/models';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { OrderStruct, Order } from '../../../models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CeleryTask, CeleryTaskStruct, LogEntry, LogEntryStruct } from '@main/models';


@Injectable({
  providedIn: 'root',
})
export class OrdersService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(params: URLSearchParams, pagination?: Pagination<OrderStruct, Order>) {
    return this.http.get(`${environment.URL.API}/fuel/admin/orders/?${params.toString()}`).pipe(
      map((data: PaginationStruct<OrderStruct>) => {
        return pagination ? pagination.update(data) : Pagination.create<OrderStruct, Order>(data, Order);
      })
    );
  }

  public get(order: Order | string) {
    let uuid = toPrimaryKey(order);
    return this.http.get(`${environment.URL.API}/fuel/admin/orders/${uuid}/`).pipe(
      map((data: OrderStruct) => {
        return order instanceof Order ? order.update(data) : Order.create(data);
      }),
    );
  }

  public create(data) {
    return this.http.post(`${environment.URL.API}/fuel/admin/orders/`, data).pipe(
      map((resp: OrderStruct) => {
        return Order.create(resp);
      })
    );
  }

  public cancel(order: Order) {
    let uuid = toPrimaryKey(order);
    return this.http.put(`${environment.URL.API}/fuel/admin/orders/${uuid}/cancel/`, {}).pipe(
      map((resp: OrderStruct) => {
        return order.update(resp);
      }),
    );
  }

  public setFMA(order: Order, data: any) {
    let uuid = toPrimaryKey(order);
    return this.http.put(`${environment.URL.API}/fuel/admin/orders/${uuid}/fma/`, data).pipe(
      map((resp: OrderStruct) => {
        return order.update(resp);
      }),
    );
  }

  public toFMA(order: Order) {
    let uuid = toPrimaryKey(order);
    return this.http.put(`${environment.URL.API}/fuel/admin/orders/${uuid}/to-fma/`, {}).pipe(
      map((resp: OrderStruct) => {
        return order.update(resp);
      }),
    );
  }

  public reloadRelease(order: Order) {
    let uuid = toPrimaryKey(order);
    return this.http.put(`${environment.URL.API}/fuel/admin/orders/${uuid}/release/`, {}).pipe(
      map((resp: OrderStruct) => {
        return order.update(resp);
      }),
    );
  }

  public reloadReport(body: { trip_number: string }) {
    return this.http.put<CeleryTaskStruct>(`${environment.URL.API}/fuel/admin/orders/report/`, body).pipe(
      map((resp: CeleryTaskStruct) => {
        return CeleryTask.create(resp);
      }),
    );
  }

  public logs(order: Order | string, params: URLSearchParams, pagination?: Pagination<LogEntryStruct, LogEntry>) {
    let uuid = toPrimaryKey(order);
    return this.http.get(`${environment.URL.API}/fuel/admin/orders/${uuid}/logs/?${params.toString()}`, {}).pipe(
      map((resp: PaginationStruct<LogEntryStruct>) => {
        return pagination ? pagination.update(resp) : Pagination.create<LogEntryStruct, LogEntry>(resp, LogEntry);
      }),
    );
  }
}
