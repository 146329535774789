import {AbstractControl} from '@angular/forms';
import {empty} from '../../utils';

export function mandatory(message = 'This field is required.') {
  return (control: AbstractControl) => {
    if (empty(control.value)) {
      return {required: message};
    }
    return null;
  };
}
