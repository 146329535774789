import { DivisionShortcut, DivisionShortcutStruct, Person, PersonStruct, Platform, PlatformStruct } from '@auth/models';
import { DestinationType, DestinationTypeStruct, FlightType, FlightTypeStruct, IPAgent, IPAgentStruct } from '../../common';
import { isBoolean, isBooleanOrNull, isString, isStringOrNull } from '@evo/utils/types';
import { ORDER_STATUSES, ORDER_STATUSES_INVOICED, ORDER_REFUEL, ORDER_PIPELINE, Statuses } from './meta';
import { Money, MoneyStruct, Count, CountStruct } from '@common/models';
import { Aircraft, AircraftStruct } from '../../../../aviation/models';
import { Location, LocationStruct } from '@geodata/models';
import { PriceInfo, PriceInfoStruct } from '../../prices';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface OrderStruct {
  uuid: string;
  batch_uuid: string;
  batch_name: string;

  flight_number: string;
  trip_number: string;
  fma: string;
  report_fma: string;

  status: ORDER_STATUSES;
  pipeline: ORDER_PIPELINE;
  refuel: ORDER_REFUEL;

  location: LocationStruct;
  destination: LocationStruct;

  aircraft: AircraftStruct;
  volume: CountStruct;
  user_volume: CountStruct;
  total: MoneyStruct;

  date_created: string;
  date_updated: string;
  date_uplift: string;
  date_arrival: string;
  date_departure: string;
  date_arrival_is_utc: boolean;
  date_departure_is_utc: boolean;

  handler: string;
  comment: string;

  flight_type: FlightTypeStruct;
  destination_type: DestinationTypeStruct;

  emails: string[];
  price_info: PriceInfoStruct;
  ip_agent: IPAgentStruct;

  user?: PersonStruct;
  division?: DivisionShortcutStruct;
  platform?: PlatformStruct;

  paid_by?: DivisionShortcutStruct;
  operator?: DivisionShortcutStruct;
  requested_by?: DivisionShortcutStruct;

  has_release?: boolean;
  has_invoices?: boolean;
}

export class Order extends Model<OrderStruct> {

  public static Status = Statuses;

  public isOpened = false;

  constructor (
    public uuid: string,
    public batchUuid: string,
    public batchName: string,
    public flightNumber: string,
    public tripNumber: string,
    public fma: string,
    public reportFma: string,
    public status: ORDER_STATUSES,
    public pipeline: ORDER_PIPELINE,
    public refuel: ORDER_REFUEL,
    public location: Location,
    public destination: Location,
    public aircraft: Aircraft,
    public volume: Count,
    public userVolume: Count,
    public total: Money,
    public dateCreated: Moment,
    public dateUpdated: Moment,
    public dateUplift: Moment,
    public dateArrival: Moment,
    public dateDeparture: Moment,
    public dateArrivalIsUtc: boolean,
    public dateDepartureIsUtc: boolean,
    public handler: string,
    public comment: string,
    public flightType: FlightType,
    public destinationType: DestinationType,
    public emails: string[],
    public priceInfo: PriceInfo,
    public intoPlaneAgent: IPAgent,
    public user: Person,
    public division: DivisionShortcut,
    public paidBy?: DivisionShortcut,
    public operator?: DivisionShortcut,
    public requestedBy?: DivisionShortcut,
    public platform?: Platform,
    public hasRelease = false,
    public hasInvoices = false
  ) {
    super();
  }

  public static create(data?: OrderStruct) {
    if (!data) {
      data = {} as OrderStruct;
    }
    return new Order(
      data.uuid || null,
      data.batch_uuid || null,
      data.batch_name || null,
      data.flight_number || null,
      data.trip_number || null,
      data.fma || null,
      data.report_fma || null,
      data.status || null,
      isString(data.pipeline) ? data.pipeline as ORDER_PIPELINE : null as any,
      data.refuel || null,
      data.location ? Location.create(data.location) : null,
      data.destination ? Location.create(data.destination) : null,
      data.aircraft ? Aircraft.create(data.aircraft) : null,
      data.volume ? Count.create(data.volume) : null,
      data.user_volume ? Count.create(data.user_volume) : null,
      data.total ? Money.create(data.total) : null,
      data.date_created ? moment.utc(data.date_created) : null,
      data.date_updated ? moment.utc(data.date_updated) : null,
      data.date_uplift ? moment.utc(data.date_uplift) : null,
      data.date_arrival ? moment.utc(data.date_arrival) : null,
      data.date_departure ? moment.utc(data.date_departure) : null,
      !!data.date_arrival_is_utc,
      !!data.date_departure_is_utc,
      data.handler || null,
      data.comment || null,
      data.flight_type ? FlightType.create(data.flight_type) : null,
      data.destination_type ? DestinationType.create(data.destination_type) : null,
      data.emails || [],
      data.price_info ? PriceInfo.create(data.price_info) : null,
      data.ip_agent ? IPAgent.create(data.ip_agent) : null,
      data.user ? Person.create(data.user) : null,
      data.division ? DivisionShortcut.create(data.division) : null,
      data.paid_by ? DivisionShortcut.create(data.paid_by) : null,
      data.operator ? DivisionShortcut.create(data.operator) : null,
      data.requested_by ? DivisionShortcut.create(data.requested_by) : null,
      data.platform ? Platform.create(data.platform) : null,
      isBoolean(data.has_release) ? data.has_release : false,
      isBoolean(data.has_invoices) ? data.has_invoices : false
    );
  }

  public get code() {
    return this.uuid.slice(0, 8).toUpperCase();
  }

  public get batchCode() {
    return this.batchName || this.batchUuid.slice(0, 8).toUpperCase();
  }

  public isCancellable() {
    return this.status == ORDER_STATUSES.CREATED;
  }

  public isCancelled() {
    return this.status == ORDER_STATUSES.CANCELLED || this.status == ORDER_STATUSES.EXPIRED;
  }

  public isConfirmed() {
    return this.status == ORDER_STATUSES.CREATED;
  }

  public isChanged() {
    return this.status == ORDER_STATUSES.CHANGED;
  }

  public isErrored() {
    return this.status == ORDER_STATUSES.ERRORED;
  }

  public isInvoiced() {
    return ORDER_STATUSES_INVOICED.indexOf(this.status) > -1;
  }

  public override toString(): string {
    let parts: string[] = [];
    this.tripNumber && parts.push(this.tripNumber);
    this.location && parts.push(this.location.code);
    this.intoPlaneAgent && parts.push(this.intoPlaneAgent.name);
    return parts.join(' / ');
  }

  public toJSON(): OrderStruct {
    return {
      uuid: this.uuid,
      batch_uuid: this.batchUuid,
      batch_name: this.batchName,
      flight_number: this.flightNumber,
      trip_number: this.tripNumber,
      fma: this.fma,
      report_fma: this.reportFma,
      status: this.status,
      pipeline: this.pipeline,
      refuel: this.refuel,
      location: this.location ? this.location.toJSON() : null,
      destination: this.destination ? this.destination.toJSON() : null,
      aircraft: this.aircraft ? this.aircraft.toJSON() : null,
      volume: this.volume ? this.volume.toJSON() : null,
      user_volume: this.userVolume ? this.userVolume.toJSON() : null,
      total: this.total ? this.total.toJSON() : null,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
      date_updated: this.dateUpdated ? this.dateUpdated.toISOString() : null,
      date_uplift: this.dateUplift ? this.dateUplift.toISOString() : null,
      date_arrival: this.dateArrival ? this.dateArrival.toISOString() : null,
      date_departure: this.dateDeparture ? this.dateDeparture.toISOString() : null,
      date_arrival_is_utc: this.dateArrivalIsUtc,
      date_departure_is_utc: this.dateDepartureIsUtc,
      handler: this.handler,
      comment: this.comment,
      flight_type: this.flightType ? this.flightType.toJSON() : null,
      destination_type: this.destinationType ? this.destinationType.toJSON() : null,
      emails: this.emails,
      price_info: this.priceInfo ? this.priceInfo.toJSON() : null,
      ip_agent: this.intoPlaneAgent ? this.intoPlaneAgent.toJSON() : null,
      has_release: this.hasRelease,
      has_invoices: this.hasInvoices
    };
  }

  public update(data: OrderStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.flightNumber = data.flight_number || this.flightNumber;
    this.status = isStringOrNull(data.status) ? data.status as ORDER_STATUSES : this.status;
    this.pipeline = isStringOrNull(data.pipeline) ? data.pipeline as ORDER_PIPELINE : this.pipeline;
    this.refuel = isStringOrNull(data.refuel) ? data.refuel as ORDER_REFUEL : this.refuel;
    this.hasRelease = isBooleanOrNull(data.has_release) ? data.has_release as boolean : this.hasRelease;
    this.hasInvoices = isBooleanOrNull(data.has_invoices) ? data.has_invoices as boolean : this.hasInvoices;
    this.fma = isString(data.fma) ? data.fma : this.fma;
    this.reportFma = isStringOrNull(data.report_fma) ? data.report_fma : this.reportFma;
    this.tripNumber = isString(data.trip_number) ? data.trip_number : this.tripNumber;
    this.volume = data.volume ? Count.create(data.volume) : this.volume;
    this.dateUplift = data.date_uplift ? moment(data.date_uplift) : this.dateUplift;
    return this;
  }

}
