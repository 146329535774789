import {AbstractControl, ValidatorFn, Validators as BaseValidators} from '@angular/forms';

export function maxLength(maxLength: number, message: string): ValidatorFn {
  return (control: AbstractControl) => {
    let res = BaseValidators.maxLength(maxLength)(control);
    if (!res) {
      return null;
    }
    return {
      maxlength: message.replace('|maxLength|', maxLength.toString()),
    };
  };
}
