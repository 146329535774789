import { OnInit, AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, Renderer2, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ValidatorFn } from '@angular/forms';
import { ViewChild, ViewContainerRef, EventEmitter } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, switchMap } from 'rxjs/operators';
import { positionElements } from '@evo/utils/html';
import { Observable, of, Subscription } from 'rxjs';
import { EventLike } from '@main/models';
import { Model } from '@evo/models';

@Component({
  selector: 'app-chips-autocomplete-control',
  templateUrl: './chips-autocomplete-control.component.html',
  styleUrls: ['./chips-autocomplete-control.component.scss'],
})
export class ChipsAutocompleteControlComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {

  @Output()
  public itemAdd = new EventEmitter();

  @Output()
  public itemRemove = new EventEmitter();

  @ViewChild('inputRef', { static: true })
  public inputRef: ElementRef;

  @ViewChild('instance', { static: true })
  public ngbTypeahead: NgbTypeahead;

  @Input()
  public addFromAutocompleteOnly = false;

  @Input()
  public validators: ValidatorFn[] = [];

  @Input()
  public inputNgClass: any;

  public isDisabled = false;

  @Input()
  public config: any;

  @Input()
  public callback: (term: string) => any;

  @Input()
  public placeholder = '';

  public items: any[] = [];
  public value: Model<any> | string | null;
  public error: string;

  public isValid: boolean;
  public isInvalid: boolean;

  private subscriptions: Subscription[] = [];

  @Input()
  public itemFormatter = (item: any) => {
    return item ? item + '' : '';
  };

  @Input()
  public inputFormatter = (item: any) => {
    if (item instanceof Model) {
      return item.toString();
    }
    return item ? item + '' : '';
  };

  @Input()
  public resultFormatter = (item: any) => {
    if (item instanceof Model) {
      return item.toString();
    }
    return item ? item + '' : '';
  };

  private _onTouched = () => {
  };
  private _onChange = (_: any) => {
  };

  constructor (
    public ngControl: NgControl,
    public viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    ngControl.valueAccessor = this;
  }

  public ngOnInit(): void {
    let s = this.ngControl.statusChanges.subscribe((status) => {
      this.validate();
    });
    this.subscriptions.push(s);
    this.hackTypehead();
  }

  public ngAfterViewInit() {
    let $input = this.inputRef.nativeElement,
      $el = this.elementRef.nativeElement,
      id = $el.getAttribute('id');
    if (id) {
      this.renderer.removeAttribute($el, 'id');
      this.renderer.setAttribute($input, 'id', id);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private hackTypehead() {
    this.ngbTypeahead['_popupService']['_viewContainerRef'] = this.viewContainerRef;
    this.ngbTypeahead['_zoneSubscription'] = this.ngZone.onStable.subscribe(() => {
      if (this.ngbTypeahead.isPopupOpen()) {
        positionElements(
          this.elementRef.nativeElement,
          this.ngbTypeahead['_windowRef'].location.nativeElement,
          this.ngbTypeahead.placement,
          false
        );
      }
    });
  }

  public onClick(e: Event) {
    if ((e.target as HTMLElement).classList.contains('chips-container')) {
      (this.inputRef.nativeElement as HTMLInputElement).focus();
    }
  }

  public selectItem(event) {
    event.preventDefault();
    this.value = event.item;
    this.validate() && this.addItem();
  }

  public onKeydown(e: KeyboardEvent) {
    let code = e.keyCode || e.which || e.key || e.code;

    switch (code) {
      case 'Enter':
      case 13:
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        !this.addFromAutocompleteOnly && this.validate() && this.addItem();
        break;
      case 'Backspace':
      case 8:
        if (!this.value && this.items.length) {
          this.removeItem(this.items[this.items.length - 1]);
        }
        break;
    }
  }

  public onInputBlur(e: Event) {
    if (this.validate()) {
      this.addItem();
    } else {
      delete this.value;
      this.validate();
    }
  }

  public onInputFocus(e: Event): void {
    if (!this.value) {
      e.stopPropagation();
      this.dispatchInputEvent();
    }
  }

  private dispatchInputEvent() {
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      (this.inputRef.nativeElement).dispatchEvent(inputEvent);
    }, 5);
  }

  public search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      switchMap((term) => {
        let res = this.callback(term || '');
        return res instanceof Observable ? res : of(res);
      }),
    );
  };

  public change(value: Model<any> | string) {
    this.validate();
    this.ngControl.control.markAsDirty();
  }

  public addItem() {
    if (this.isDisabled) {
      return;
    }
    if (this.error || !this.value) {
      return;
    }
    let item = this.itemFormatter(this.value),
      event = EventLike.create(item);
    this.itemAdd.emit(event);
    if (!event.defaultPrevented) {
      this.items.push(item);
      this._onChange(this.items);
    }
    delete this.value;
    this.dispatchInputEvent();
  }

  public removeItem(item: any) {
    if (this.isDisabled) {
      return;
    }
    let event = EventLike.create(item);
    this.itemRemove.emit(event);
    if (!event.defaultPrevented) {
      let index = this.items.indexOf(item);
      this.items.splice(index, 1);
      this._onChange(this.items);
      this.dispatchInputEvent();
    }
  }

  private validate() {
    if (!this.validators.length) {
      return true;
    }
    let error: string = null;
    if (this.value) {
      for (let i = 0; i < this.validators.length; i++) {
        let v = this.validators[i];
        let result = v(new FormControl(this.value));
        if (result) {
          error = result[Object.keys(result)[0]];
          break;
        }
      }
    }
    this.error = error;
    this.isValid = this.ngControl.dirty && !error;
    this.isInvalid = this.ngControl.dirty && !!error;
    return !error;
  }

  public clear() {
    delete this.value;
    this.items = [];
    this._onChange(this.items);
  }

  /***************************************** Control Value Accessor ************************************/
  public registerOnChange(fn: (value: any) => any) {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => any) {
    this._onTouched = fn;
  }

  public writeValue(items: Array<Model<any> | any>) {
    this.items = items;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
