import { DivisionShortcut, DivisionShortcutStruct, Person, PersonStruct } from '@auth/models';
import { Aircraft, AircraftStruct } from '../../../../aviation/models';
import { Model } from '@evo/models';

export interface OperatorStruct {
  division: DivisionShortcutStruct;
  aircraft: AircraftStruct;
  user: PersonStruct;
}

export class Operator extends Model<OperatorStruct> {
  constructor (
    public division: DivisionShortcut,
    public aircraft?: Aircraft,
    public user?: Person
  ) {
    super();
  }

  public static create(data: OperatorStruct) {
    if (!data) {
      data = {} as OperatorStruct;
    }
    return new Operator(
      data.division ? DivisionShortcut.create(data.division) : null,
      data.aircraft ? Aircraft.create(data.aircraft) : null,
      data.user ? Person.create(data.user) : null
    );
  }

  public override get pk() {
    return this.division.pk;
  }

  public override toString(): string {
    let parts: string[] = [this.division.ecp ? this.division.ecp : '-----', this.division.name];
    if (this.aircraft && this.aircraft.registration) {
      parts.unshift(this.aircraft.registration);
    } else if (this.user && this.user.email) {
      parts.unshift(this.user.email);
    }
    return parts.join(' / ');
  }

  public toJSON() {
    return {
      division: this.division ? this.division.toJSON() : null,
      aircraft: this.aircraft ? this.aircraft.toJSON() : null,
      user: this.user ? this.user.toJSON() : null
    };
  }

  public update(data: OperatorStruct) {
    this.division = data.division ? DivisionShortcut.create(data.division) : this.division;
    this.aircraft = data.aircraft ? Aircraft.create(data.aircraft) : this.aircraft;
    this.user = data.user ? Person.create(data.user) : this.user;
    return this;
  }
}
