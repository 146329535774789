<div class="input-group"
     [class.is-invalid]="isInvalid"
     [class.input-group-sm]="size == 'sm'"
     [class.input-group-lg]="size == 'lg'">
  <input type="text"
         class="form-control"
         autocomplete="off"
         random-name trim
         #inputRef
         #typehead="ngbTypeahead"
         [class.is-invalid]="isInvalid"
         [class.is-valid]="isValid"
         [disabled]="isDisabled"
         (focus)="onInputFocus($event)"
         (blur)="onInputBlur($event)"
         [placeholder]="placeholder"
         [inputFormatter]="inputFormatter"
         [resultFormatter]="resultFormatter"
         [ngbTypeahead]="search"
         [focusFirst]="true"
         [(ngModel)]="value"
         [popperOptions]="popperOptions"
         [ngModelOptions]="{standalone: true}"
         (ngModelChange)="typeHeadChange($event)">
  <button class="btn" type="button" tabindex="-1"
          [class.btn-outline-dark]="isDisabled || isStandAlone || (!isValid && !isInvalid)"
          [class.btn-outline-danger]="!isDisabled && isInvalid"
          [class.btn-outline-success]="!isDisabled && isValid"
          [disabled]="!value || isDisabled"
          (click)="clear()">
    <i class="fa fa-times"></i>
  </button>
</div>
<small class="invalid-feedback" *ngIf="this.isInvalid"> {{errors[0]}}</small>
