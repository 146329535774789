import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastService } from '@main/services';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NotFoundGuard implements CanActivate {
  constructor (
    // private user: User,
    private router: Router,
    private toast: ToastService,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // let redirectTo = this.user.isAuthenticated() ? '/dashboard' : '/access/sign-in';
    // this.toast.error('Page not found', `Redirected to "${redirectTo}"`);
    // this.router.navigateByUrl(redirectTo);
    return true;
  }
}
