<ngx-loading-bar></ngx-loading-bar>
<main role="main" class="main">
  <div class="d-none d-lg-block">
    <nav class="navbar navbar-expand navbar-dark bg-primary py-0 navbar-top">
      <div class="container">
        <div class="navbar-collapse collapse">
          <ul class="nav justify-content-center mx-auto">
            <li class="nav-item text-center">
              <span class="nav-link navbar-text fw-normal" aria-disabled="true">
                24 / 7 Fuel
              </span>
            </li>
            <li class="nav-item text-center">
              <a class="nav-link fw-normal text-white" href="tel:+14154046133">+1 415 404 6133</a>
            </li>
            <li class="nav-item text-center">
              <a class="nav-link fw-normal text-white" href="mailto:orderfuel&#64;flyevo.com">orderfuel&#64;flyevo.com</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <router-outlet></router-outlet>
  <div class="contact-link-wrapper d-lg-none">
    <a class="contact-link-icon" target="_blank" href="https://flyevo.com/contact">24/7</a>
    <a class="contact-link-text" target="_blank" href="https://flyevo.com/contact">Contact</a>
  </div>
</main>