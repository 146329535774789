import { isBoolean, isBooleanOrNull, isNull, isObject, isStringOrNull } from '@evo/utils/types';
import { State, StateStruct, Country, CountryStruct } from '@geodata/models';
import { Model } from '@evo/models';
import { Client, ClientStruct } from '../../users';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface CompanyStruct {
  uuid: string;
  name: string;

  is_new: boolean;
  is_active: boolean;
  is_enabled_view_invoice: boolean;

  has_divisions: boolean;
  date_created: string;

  admin: ClientStruct;
  country: CountryStruct;
  state: StateStruct;

  counts?: any;
}

export class Company extends Model<CompanyStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public isNew: boolean,
    public isActive: boolean,
    public isEnabledViewInvoice: boolean,
    public hasDivisions: boolean,
    public dateCreated: Moment,
    public admin: Client,
    public country: Country,
    public state: State,
    public counts?: any
  ) {
    super();
  }

  public static create(data?: CompanyStruct) {
    if (!data) {
      data = {} as CompanyStruct;
    }
    return new Company(
      data.uuid || null,
      data.name || '',
      isBoolean(data.is_new) ? data.is_new : true,
      isBoolean(data.is_active) ? data.is_active : false,
      isBoolean(data.is_enabled_view_invoice) ? data.is_enabled_view_invoice : true,
      isBoolean(data.has_divisions) ? data.has_divisions : false,
      data.date_created ? moment(data.date_created) : null,
      isObject(data.admin) ? Client.create(data.admin) : null,
      isObject(data.country) ? Country.create(data.country) : null,
      isObject(data.state) ? State.create(data.state) : null,
      data.counts || null
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): CompanyStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      is_new: this.isNew,
      is_active: this.isActive,
      is_enabled_view_invoice: this.isEnabledViewInvoice,
      has_divisions: this.hasDivisions,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
      admin: this.admin ? this.admin.toJSON() : null,
      country: this.country ? this.country.toJSON() : null,
      state: this.state ? this.state.toJSON() : null
    };
  }

  public update(data: CompanyStruct): this {
    this.uuid = isStringOrNull(data.uuid) ? data.uuid : this.uuid;
    this.name = isStringOrNull(data.name) ? data.name : this.name;
    this.isNew = isBooleanOrNull(data.is_new) ? data.is_new : this.isNew;
    this.isActive = isBooleanOrNull(data.is_active) ? data.is_active : this.isActive;
    this.isEnabledViewInvoice = isBooleanOrNull(data.is_enabled_view_invoice) ? data.is_enabled_view_invoice : this.isEnabledViewInvoice;
    this.hasDivisions = isBooleanOrNull(data.has_divisions) ? data.has_divisions : this.hasDivisions;
    this.dateCreated = isStringOrNull(data.date_created) ? moment(data.date_created) : this.dateCreated;
    this.admin = isNull(data.admin) ? null : isObject(data.admin) ? Client.create(data.admin) : this.admin;
    this.country = isNull(data.country) ? null : isObject(data.country) ? Country.create(data.country) : this.country;
    this.state = isNull(data.state) ? null : isObject(data.state) ? State.create(data.state) : this.state;
    return this;
  }

  public override clear() {
    this.uuid = null;
    this.name = null;
    this.isNew = true;
    this.isActive = false;
    this.isEnabledViewInvoice = true;
    this.dateCreated = null;
    this.admin = null;
    this.country = null;
    this.state = null;
    return this;
  }
}
