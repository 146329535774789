import { isArray, isNumber, isNumberOrNull } from '@evo/utils/types';
import { Model } from '@evo/models';

export interface PaginationStruct<S> {
  count: number;
  page: number;
  per_page: number;
  total_pages: number;
  results: S[];
}

export class Pagination<S, M extends Model<S>> extends Model<PaginationStruct<S>> {

  public numLinks = 5;
  public disabled = false;

  constructor (
    public page: number,
    public perPage: number,
    public totalPages: number,
    public count: number,
    public results: M[] = [],
    public modelClass: any,
  ) {
    super();
  }

  public static create<S, M extends Model<S>>(data: PaginationStruct<S>, modelClass: any) {
    return new Pagination<S, M>(
      isNumber(data.page) ? data.page : 1,
      isNumber(data.per_page) ? data.per_page : 20,
      isNumber(data.total_pages) ? data.total_pages : 1,
      isNumber(data.count) ? data.count : 0,
      isArray(data.results) ? data.results.map((item) => modelClass.create(item)) : [],
      modelClass,
    );
  }

  public static empty<S, M extends Model<S>>(modelClass: any) {
    return new Pagination<S, M>(1, 10, 1, 0, [], modelClass);
  }

  public disable() {
    this.disabled = true;
    return this;
  }

  public enable() {
    this.disabled = false;
    return this;
  }

  public getLinks() {
    let start = 1, end = 1,
      results = [];

    if (!this.hasPrev()) {
      start = 1;
      end = start + this.numLinks - 1;
    } else if (!this.hasNext()) {
      end = this.totalPages;
      start = end - this.numLinks;
    } else {
      let half = Math.floor(this.numLinks / 2);
      start = this.page - half;
      end = start + this.numLinks - 1;
    }

    if (start < 1) {
      start = 1;
    }

    if (end > this.totalPages) {
      end = this.totalPages;
    }

    let prev = this.page - 1,
      next = this.page + 1;

    results.push(this.createLink(1, '<<'));
    results.push(this.createLink(prev > 0 ? prev : 1, '<'));
    for (let page = start; page <= end; page++) {
      let link = this.createLink(page, page);
      results.push(link);
    }
    results.push(this.createLink(next <= this.totalPages ? next : this.totalPages, '>'));
    results.push(this.createLink(this.totalPages, '>>'));

    return results;
  }

  public links() {
    let links = [],
      prev = this.page - 1,
      next = this.page + 1,
      from = this.results.length ? ((this.page - 1) * this.perPage) + 1 : 0,
      to = this.results.length ? (from + this.results.length) - 1 : 0;
    links.push(this.createLink(1, '<<'));
    links.push(this.createLink(prev > 0 ? prev : 1, '<'));
    links.push(this.createLink(this.page, `${from} - ${to} of ${this.count}`));
    links.push(this.createLink(next <= this.totalPages ? next : this.totalPages, '>'));
    links.push(this.createLink(this.totalPages, '>>'));
    return links;
  }

  private createLink(page, name) {
    return {
      number: page,
      name: name,
      current: page == this.page,
      disabled: page == this.page
    };
  }

  public hasNext() {
    return this.page < this.totalPages;
  }

  public hasPrev() {
    return this.page > 1;
  }

  get length() {
    return this.results.length;
  }

  public toJSON(): PaginationStruct<S> {
    return {
      page: this.page,
      per_page: this.perPage,
      total_pages: this.totalPages,
      count: this.count,
      results: [],
    };
  }

  public update(data: PaginationStruct<S>): this {
    this.page = isNumberOrNull(data.page) ? data.page : this.page;
    this.perPage = isNumberOrNull(data.per_page) ? data.per_page : this.perPage;
    this.totalPages = isNumberOrNull(data.total_pages) ? data.total_pages : this.totalPages;
    this.count = isNumberOrNull(data.count) ? data.count : this.count;
    this.results = isArray(data.results) ? data.results.map((item) => this.modelClass.create(item)) : this.results;
    return this;
  }

  public pageAfterRemoval() {
    let page = (this.length == 1) ? this.totalPages - 1 : this.page;
    if (page <= 0) {
      page = 1;
    }
    return page;
  }

}
