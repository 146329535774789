import { BrowserOptions, ErrorHandlerOptions } from "@sentry/angular";

export const environment = {
  production: true,
  PLAID_PUBLIC_KEY: 'aa93bd14d85ea766b47e60430da7b7',
  PLAID_ENVIRONMENT: 'sandbox',
  GA_TRACKING_CODE: 'G-W3LR8QJ6Z3',
  URL: {
    API: 'https://api.staging.evo-jet.com',
    SIGNUP: 'https://signup.staging.evo-jet.com'
  },
  SENTRY: {
    BROWSER_OPTIONS: {
      dsn: 'https://b8ee92493b5c49c58b8a1ad4d0447fe6@sentry.zitemedia.com/51',
      debug: true,
    } as BrowserOptions,
    ERROR_HANDLER_OPTIONS: {
      showDialog: true,
      logErrors: true
    } as ErrorHandlerOptions
  },
};
