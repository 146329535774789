import { isNumber, isNumberOrNull } from '@evo/utils/types';
import { Model } from '@evo/models';

export interface TimeStruct {
  seconds: number;
}

export class Time extends Model<TimeStruct> {
  constructor (
    public seconds: number,
  ) {
    super();
  }

  public static create(data?: TimeStruct | number) {
    if (isNumber(data)) {
      return new Time(data);
    }
    if (!data) {
      data = {} as TimeStruct;
    }
    return new Time(
      data.seconds || null as any
    );
  }

  public override toString(): string {
    return this.format();
  }

  public format() {
    let m: string | number = Math.floor(this.seconds / 60),
      h: string | number = Math.floor(m / 60),
      s: string | number = this.seconds % 60;

    m = m % 60;
    m = m > 9 ? m : '0' + m;
    s = s > 9 ? s : '0' + s;

    return [h, m, s].join(':');
  }

  public toJSON(): TimeStruct {
    return {
      seconds: this.seconds
    };
  }

  public update(data: Partial<TimeStruct> | number) {
    if (isNumberOrNull(data)) {
      this.seconds = data as number;
    } else {
      this.seconds = isNumberOrNull((data as TimeStruct).seconds) ? (data as TimeStruct).seconds : this.seconds;
    }
    return this;
  }
}
