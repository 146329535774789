import { Collection } from '@evo/models';
import { Aircraft, AircraftStruct } from './aircraft';

export class AircraftsCollection extends Collection<Aircraft> {
  public static create(data: AircraftStruct[] = []) {
    return new AircraftsCollection(data.map((item) => Aircraft.create(item)));
  }

  public search(term: string) {
    term = (term + '').toUpperCase();
    return this.items.filter((a) => {
      return ((a.name + '').toUpperCase().indexOf(term) > -1)
        || ((a.registration + '').toUpperCase().indexOf(term) > -1);
    });
  }
}
