import { Model } from '@evo/models';

export class ManufacturerStruct {
  uuid: string;
  name: string;
}

export class Manufacturer extends Model<ManufacturerStruct> {

  constructor (
    public uuid: string,
    public name: string,
  ) {
    super();
  }

  public static create(data?: ManufacturerStruct) {
    if (!data) {
      data = {} as ManufacturerStruct;
    }
    return new Manufacturer(
      data.uuid || null,
      data.name || null
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): ManufacturerStruct {
    return {
      uuid: this.uuid,
      name: this.name
    };
  }

  public update(data: ManufacturerStruct): this {
    this.uuid = 'string' == typeof data.uuid ? data.uuid : this.uuid;
    this.name = 'string' == typeof data.name ? data.name : this.name;
    return this;
  }

}
