import { Person, PersonStruct } from '@auth/models/users/person';
import { Mark, MarkStruct } from '../evaluation-item';
import { Model } from '@evo/models';
import { Quarter, QuarterStruct } from '../quarter';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface EvaluationStruct {
  uuid: string;
  mark: number;
  score: number;
  quarterly_trend: number;
  overall_trend: number;
  quarterly_score_trend: number;
  overall_score_trend: number;
  overall_score: number;
  date_created: string;
  is_done: boolean;
  created_by: PersonStruct;
  quarter: QuarterStruct;
  highlights: MarkStruct[];
}

export class Evaluation extends Model<EvaluationStruct> {

  constructor (
    public uuid: string,
    public mark: number,
    public score: number,
    public quarterlyTrend: number,
    public overallTrend: number,
    public quarterlyScoreTrend: number,
    public overallScoreTrend: number,
    public overallScore: number,
    public isDone: boolean,
    public dateCreated: Moment,
    public createdBy: Person,
    public quarter: Quarter,
    public highlights: Mark[] = [],
  ) {
    super();
  }

  public static create(data?: EvaluationStruct) {
    if (!data) {
      data = {} as any;
    }
    return new Evaluation(
      data.uuid || null,
      ('undefined' == typeof data.mark) ? null : data.mark,
      ('undefined' == typeof data.score) ? null : data.score,
      ('undefined' == typeof data.quarterly_trend) ? null : data.quarterly_trend,
      ('undefined' == typeof data.overall_trend) ? null : data.overall_trend,
      ('undefined' == typeof data.quarterly_score_trend) ? null : data.quarterly_score_trend,
      ('undefined' == typeof data.overall_score_trend) ? null : data.overall_score_trend,
      ('undefined' == typeof data.overall_score) ? null : data.overall_score,
      'boolean' == typeof data.is_done ? data.is_done : false,
      data.date_created ? moment(data.date_created) : null,
      data.created_by ? Person.create(data.created_by) : null,
      data.quarter ? Quarter.create(data.quarter) : null,
      data.highlights ? data.highlights.map((h) => Mark.create(h)) : [],
    );
  }

  public isNull() {
    return !this.uuid;
  }

  public getHighLightsLabel() {
    return this.highlights.map((h) => h.factor.name).join(', ');
  }

  public toJSON(): EvaluationStruct {
    return {
      uuid: this.uuid,
      mark: this.mark,
      score: this.score,
      quarterly_trend: this.quarterlyTrend,
      overall_trend: this.overallTrend,
      quarterly_score_trend: this.quarterlyScoreTrend,
      overall_score_trend: this.overallScoreTrend,
      overall_score: this.overallScore,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
      created_by: this.createdBy ? this.createdBy.toJSON() : null,
      quarter: this.quarter ? this.quarter.toJSON() : null,
      highlights: this.highlights ? this.highlights.map((h) => h.toJSON()) : [],
      is_done: this.isDone
    };
  }

  public update(data: EvaluationStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.mark = ('undefined' == typeof data.mark) ? this.mark : data.mark;
    this.score = ('undefined' == typeof data.score) ? this.score : data.score;
    this.quarterlyTrend = ('undefined' == typeof data.quarterly_trend) ? this.quarterlyTrend : data.quarterly_trend;
    this.overallTrend = ('undefined' == typeof data.overall_trend) ? this.overallTrend : data.overall_trend;
    this.overallScore = ('undefined' == typeof data.overall_score) ? this.overallScore : data.overall_score;

    this.quarterlyScoreTrend = ('undefined' == typeof data.quarterly_score_trend) ?
      this.quarterlyScoreTrend : data.quarterly_score_trend;

    this.overallScoreTrend = ('undefined' == typeof data.overall_score_trend) ?
      this.overallScoreTrend : data.overall_score_trend;

    this.isDone = 'boolean' == typeof data.is_done ? data.is_done : this.isDone;
    this.dateCreated = data.date_created ? moment(data.date_created) : this.dateCreated;
    this.createdBy = data.created_by ? Person.create(data.created_by) : this.createdBy;
    this.quarter = data.quarter ? Quarter.create(data.quarter) : this.quarter;
    this.highlights = data.highlights ? data.highlights.map((h) => Mark.create(h)) : this.highlights;
    return this;
  }
}
