import { DivisionShortcut, DivisionShortcutStruct } from '../../companies/division-shortcut';
import { isNumber, isString } from '@evo/utils/types';
import { Model } from '@evo/models';

export interface PlatformStruct {
  uuid: string;
  fma: number;
  name: string;
  slug: string;
  label: string;
  token: string;
  division: DivisionShortcutStruct;
}

export class Platform extends Model<PlatformStruct> {
  constructor (
    public uuid: string,
    public fma: number,
    public name: string,
    public slug: string,
    public label: string,
    public token: string,
    public division: DivisionShortcut
  ) {
    super();
  }

  public static create(data?: PlatformStruct) {
    if (!data) {
      data = {} as PlatformStruct;
    }
    return new Platform(
      isString(data.uuid) ? data.uuid : null,
      isNumber(data.fma) ? data.fma : null,
      isString(data.name) ? data.name : null,
      isString(data.slug) ? data.slug : null,
      isString(data.label) ? data.label : null,
      isString(data.token) ? data.token : null,
      data.division ? DivisionShortcut.create(data.division) : null
    );
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      fma: this.fma,
      name: this.name,
      slug: this.slug,
      label: this.label,
      token: this.token,
      division: this.division ? this.division.toJSON() : null
    };
  }

  public override toString(): string {
    return this.name;
  }

  public update(data: PlatformStruct) {
    this.uuid = isString(data.uuid) ? data.uuid : this.uuid;
    this.fma = isNumber(data.fma) ? data.fma : this.fma;
    this.name = isString(data.name) ? data.name : this.name;
    this.slug = isString(data.slug) ? data.slug : this.slug;
    this.label = isString(data.label) ? data.label : this.label
    this.token = isString(data.token) ? data.token : this.token;
    this.division = data.division ? DivisionShortcut.create(data.division) : this.division;
    return this;
  }
}
