import { Model } from '@evo/models';
import { Stats, StatsStruct } from '..';

export interface AdminStatsStruct {
  uplifts: StatsStruct;
  customers: StatsStruct;
  invoice_volume: StatsStruct;
  invoice_total: StatsStruct;
  vendor_total: StatsStruct;
  invoice_profit: StatsStruct;
  gross_profit: StatsStruct;
  margin: StatsStruct;
  margin_percent: StatsStruct;
}

export class AdminStats extends Model<AdminStatsStruct> {
  constructor (
    public uplifts: Stats,
    public customers: Stats,
    public invoiceVolume: Stats,
    public invoiceTotal: Stats,
    public vendorTotal: Stats,
    public invoiceProfit: Stats,
    public grossProfit: Stats,
    public margin: Stats,
    public marginPercent: Stats
  ) {
    super();
  }

  public static create(data: AdminStatsStruct) {
    if (!data) {
      data = {} as AdminStatsStruct;
    }
    return new AdminStats(
      data.uplifts ? Stats.create(data.uplifts) : null,
      data.customers ? Stats.create(data.customers) : null,
      data.invoice_volume ? Stats.create(data.invoice_volume) : null,
      data.invoice_total ? Stats.create(data.invoice_total) : null,
      data.vendor_total ? Stats.create(data.vendor_total) : null,
      data.invoice_profit ? Stats.create(data.invoice_profit) : null,
      data.gross_profit ? Stats.create(data.gross_profit) : null,
      data.margin ? Stats.create(data.margin) : null,
      data.margin_percent ? Stats.create(data.margin_percent) : null
    );
  }

  public toJSON(): AdminStatsStruct {
    return {
      uplifts: this.uplifts ? this.uplifts.toJSON() : null,
      customers: this.customers ? this.customers.toJSON() : null,
      invoice_volume: this.invoiceVolume ? this.invoiceVolume.toJSON() : null,
      invoice_total: this.invoiceTotal ? this.invoiceTotal.toJSON() : null,
      vendor_total: this.vendorTotal ? this.vendorTotal.toJSON() : null,
      invoice_profit: this.invoiceProfit ? this.invoiceProfit.toJSON() : null,
      gross_profit: this.grossProfit ? this.grossProfit.toJSON() : null,
      margin: this.margin ? this.margin.toJSON() : null,
      margin_percent: this.marginPercent ? this.marginPercent.toJSON() : null,
    };
  }

  public update(data: any): this {
    return this;
  }
}
