import { FactoryProvider } from '@angular/core';
import { Link } from '../../models';

export class Breadcrumbs {

  private crumbs: Link[] = [];

  constructor () {
  }

  public get length() {
    return this.crumbs.length;
  }

  public append(crumb: Link) {
    this.crumbs.push(crumb);
    return this;
  }

  public remove(index: number) {
    this.crumbs.splice(index, 1);
    return this;
  }

  public clear() {
    delete this.crumbs;
    this.crumbs = [];
    return this;
  }

  public get(name: string) {
    return this.crumbs.find((c) => {
      return (c.name + '').toLowerCase() == (name + '').toLowerCase();
    })
  }

  public at(index: number) {
    return this.crumbs[index];
  }

  public all() {
    return this.crumbs;
  }
}

export let BREADCRUMBS: Breadcrumbs;

export function breadcrumbsFactory() {
  if (!BREADCRUMBS) {
    BREADCRUMBS = new Breadcrumbs();
  }
  return BREADCRUMBS;
}

export const BreadcrumbsProvider: FactoryProvider = {
  provide: Breadcrumbs,
  useFactory: breadcrumbsFactory
};
