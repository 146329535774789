import { Division, DivisionStruct, Person, PersonStruct, Platform, PlatformStruct } from '@auth/models';
import { Money, MoneyStruct, Count, CountStruct } from '@common/models';
import { IPAgent, IPAgentStruct, Vendor, VendorStruct } from '../../common';
import { Aircraft, AircraftStruct } from '../../../../aviation/models';
import { Location, LocationStruct } from '@geodata/models';
import { isNumber, isObject } from '@evo/utils/types';
import { Model } from '@evo/models';

export type ReportItemType = 'division' | 'aircraft' | 'location' | 'sales_agent' | 'ip_agent' | 'vendor' | 'platform';

export interface ReportItemStruct {
  type: ReportItemType;
  item: DivisionStruct | AircraftStruct | LocationStruct | PersonStruct | IPAgentStruct | VendorStruct | PlatformStruct;
  invoice_volume: CountStruct;
  invoice_total: MoneyStruct;
  cost_total: MoneyStruct;
  profit_total: MoneyStruct;
  margin_total: MoneyStruct;
  price_per_usg: MoneyStruct;
  margin_per_usg: MoneyStruct;
  orders: number;
  invoices: number;
  conversion: number;
  customers: number;
  previous: ReportItemStruct;
}

export class ReportItem<T extends Model<any>> extends Model<ReportItemStruct> {

  constructor (
    public type: ReportItemType,
    public item: T,
    public invoiceVolume: Count,
    public invoiceTotal: Money,
    public costTotal: Money,
    public profitTotal: Money,
    public marginTotal: Money,
    public pricePerUsg: Money,
    public marginPerUsg: Money,
    public orders: number,
    public invoices: number,
    public conversion: number,
    public customers?: number,
    public previous?: ReportItem<T>
  ) {
    super();
  }

  public static create(data?: ReportItemStruct) {
    if (!data) {
      data = {} as ReportItemStruct;
    }
    let item: any = null;
    if (data.item) {
      switch (data.type) {
        case 'division':
          item = Division.create(data.item as DivisionStruct);
          break;
        case 'aircraft':
          item = Aircraft.create(data.item as AircraftStruct);
          break;
        case 'location':
          item = Location.create(data.item as LocationStruct);
          break;
        case 'sales_agent':
          item = Person.create(data.item as PersonStruct);
          break;
        case 'ip_agent':
          item = IPAgent.create(data.item as IPAgentStruct);
          break;
        case 'vendor':
          item = Vendor.create(data.item as VendorStruct);
          break;
        case 'platform':
          item = Platform.create(data.item as PlatformStruct);
          break;
      }
    }
    return new ReportItem(
      data.type || null,
      item || null,
      data.invoice_volume ? Count.create(data.invoice_volume) : null,
      data.invoice_total ? Money.create(data.invoice_total) : null,
      data.cost_total ? Money.create(data.cost_total) : null,
      data.profit_total ? Money.create(data.profit_total) : null,
      data.margin_total ? Money.create(data.margin_total) : null,
      data.price_per_usg ? Money.create(data.price_per_usg) : null,
      data.margin_per_usg ? Money.create(data.margin_per_usg) : null,
      isNumber(data.orders) ? data.orders : null,
      isNumber(data.invoices) ? data.invoices : null,
      isNumber(data.conversion) ? data.conversion : null,
      isNumber(data.customers) ? data.customers : null,
      isObject(data.previous) ? ReportItem.create(data.previous) : null
    );
  }

  public override get pk() {
    return this.item ? this.item.pk : null;
  }

  public override toString(): string {
    return this.item ? this.item.toString() : '';
  }

  public toJSON(): ReportItemStruct {
    return {
      type: this.type,
      item: this.item ? this.item.toJSON() : null,
      invoice_volume: this.invoiceVolume ? this.invoiceVolume.toJSON() : null,
      invoice_total: this.invoiceTotal ? this.invoiceTotal.toJSON() : null,
      cost_total: this.costTotal ? this.costTotal.toJSON() : null,
      profit_total: this.profitTotal ? this.profitTotal.toJSON() : null,
      margin_total: this.marginTotal ? this.marginTotal.toJSON() : null,
      price_per_usg: this.pricePerUsg ? this.pricePerUsg.toJSON() : null,
      margin_per_usg: this.marginPerUsg ? this.marginPerUsg.toJSON() : null,
      orders: this.orders,
      invoices: this.invoices,
      conversion: this.conversion,
      customers: this.customers,
      previous: this.previous ? this.previous.toJSON() : null
    };
  }

  public update(data: ReportItemStruct): this {
    return this;
  }
}
