import { isString, isStringOrNull } from '@evo/utils/types';
import { Model } from '@evo/models';

export interface VendorStruct {
  uuid: string;
  name: string;
}

export class Vendor extends Model<VendorStruct> {
  constructor (
    public uuid: string,
    public name: string
  ) {
    super();
  }

  public static create(data: VendorStruct) {
    return new Vendor(
      isString(data.uuid) ? data.uuid : null,
      isString(data.name) ? data.name : null
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      name: this.name
    };
  }

  public update(data: VendorStruct) {
    this.uuid = isStringOrNull(data.uuid) ? data.uuid : this.uuid;
    this.name = isStringOrNull(data.name) ? data.name : this.name;
    return this;
  }
}
