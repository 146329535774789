import { Model } from '@evo/models';

export interface DestinationTypeStruct {
  uuid: string;
  name: string;
  code: string;
}

export class DestinationType extends Model<DestinationTypeStruct> {
  constructor (
    public uuid: string,
    public name: string,
    public code: string,
  ) {
    super();
  }

  public static create(data: DestinationTypeStruct) {
    return new DestinationType(
      data.uuid || null,
      data.name || null,
      data.code || null,
    );
  }

  public isDomestic() {
    return 'D' == this.code;
  }

  public isInternational() {
    return 'I' == this.code;
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      code: this.code,
      name: this.name
    };
  }

  public update(data: DestinationTypeStruct) {
    this.uuid = data.uuid || this.uuid;
    this.name = data.name || this.name;
    this.code = data.code || this.code;
    return this;
  }
}
