import { Param } from './param';


export const CHOISES_ORDER_STATUS: Param<number>[] = [
  Param.create({ name: 'Allowed', value: 0, label: 'allowed' }),
  Param.create({ name: 'Credit Issues', value: 1, label: 'credit issues' }),
  Param.create({ name: 'New User (Signup Forms)', value: 2, label: 'signup forms' }),
];

export const CHOISES_HAS_REBATE: Param<boolean>[] = [
  Param.create({ name: 'Has rebate', value: true, label: 'enabled' }),
  Param.create({ name: 'Hasn\'t rebate', value: false, label: 'disabled' }),
];

export const CHOISES_SENDMAIL: Param<boolean>[] = [
  Param.create({ name: 'Send email', value: true, label: 'send' }),
  Param.create({ name: 'Do not send email', value: false, label: 'don\'t send' }),
];

export const CHOISES_ACTIVE: Param<boolean>[] = [
  Param.create({ name: 'Active', value: true, label: 'active' }),
  Param.create({ name: 'Inactive', value: false, label: 'inactive' }),
];

export const CHOISES_VERIFIED: Param<boolean>[] = [
  Param.create({ name: 'Verified', value: true, label: 'verified' }),
  Param.create({ name: 'Unverified', value: false, label: 'unverified' }),
];

export const CHOISES_PERMISSION: Param<boolean>[] = [
  Param.create({ name: 'Default - on', value: true, label: 'on' }),
  Param.create({ name: 'Default - off', value: false, label: 'off' }),
];

export const CHOISES_SHOW: Param<boolean>[] = [
  Param.create({ name: 'Show', value: true, label: 'show' }),
  Param.create({ name: 'Do not show', value: false, label: 'hide' }),
];
