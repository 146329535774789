<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>
<div class="modal-body" #body>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light"
          [disabled]="isDisabled"
          (click)="close()">
    {{reject}}
  </button>
  <button type="button" class="btn btn-primary"
          [disabled]="isDisabled"
          (click)="submit()"
          ngbAutofocus>
    {{resolve}}
  </button>
</div>
