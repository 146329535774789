import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { controlErrors } from '@evo/utils/html';
import { uuid } from '@evo/utils/helpers';
import { Model } from '@evo/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-buttons-set-control',
  templateUrl: './buttons-set-control.component.html',
  styleUrls: ['./buttons-set-control.component.scss'],
})
export class ButtonsSetControlComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input()
  public choises: any[] = [];

  public value: any;
  public uuid: string;
  public width: number;
  public lastWidth: number;

  public isDisabled = false;
  public isInvalid = false;
  public isValid = false;

  public errors: string[] = [];
  private subscriptions: Subscription[] = [];

  @Input()
  public nameFormatter = (item: any) => {
    if (item instanceof Model) {
      return item.toString();
    }
    return item;
  };

  @Input()
  public valueFormatter = (item: any) => {
    if (item instanceof Model) {
      return item.toString();
    }
    return item;
  };

  private _onTouched = () => {
  };
  private _onChange = (_: any) => {
  };

  constructor (
    private ngControl: NgControl,
  ) {
    ngControl.valueAccessor = this;
    this.uuid = uuid();
  }

  public ngOnInit() {
    this.width = Math.floor(100 / this.choises.length);
    this.lastWidth = this.width + (100 % this.width);
    this.value = this.ngControl.value;
    let s = this.ngControl.statusChanges.subscribe((status) => {
      let data = controlErrors(this.ngControl);
      this.isInvalid = data[0];
      this.isValid = data[1];
      this.errors = data[2];
    });
    this.subscriptions.push(s);
    s = this.ngControl.valueChanges.subscribe((v) => {
      this.value = v;
    });
    this.subscriptions.push(s);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public registerOnChange(fn: (value: any) => any) {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => any) {
    this._onTouched = fn;
  }

  public writeValue(value: any) {
    this._onChange(value);
    this._onTouched();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
