import { isString } from '@evo/utils/types';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface QuarterStruct {
  uuid: number;
  name: string;
  number: number;
  year: number;
  label: string;
  slug: string;
  date_from: string;
  date_to: string;
}

export class Quarter extends Model<QuarterStruct> {

  constructor (
    public uuid: number,
    public name: string,
    public number: number,
    public year: number,
    public label: string,
    public slug: string,
    public dateFrom: Moment,
    public dateTo: Moment,
  ) {
    super();
  }

  public static create(data?: QuarterStruct) {
    if (!data) {
      data = {} as any;
    }
    return new Quarter(
      data.uuid || null,
      data.name || '',
      data.number || null,
      data.year || null,
      isString(data.label) ? data.label : null,
      isString(data.slug) ? data.slug : null,
      data.date_from ? moment(data.date_from) : null,
      data.date_to ? moment(data.date_to) : null,
    );
  }

  public override get pk() {
    return this.slug;
  }

  public override toString(): string {
    return this.label;
  }

  public toJSON(): QuarterStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      number: this.number,
      year: this.year,
      label: this.label,
      slug: this.slug,
      date_from: this.dateFrom ? this.dateFrom.toISOString() : null,
      date_to: this.dateTo ? this.dateTo.toISOString() : null,
    };
  }

  public update(data: QuarterStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.name = data.name || this.name;
    this.number = data.number || this.number;
    this.year = data.year || this.year;
    this.label = isString(data.label) ? data.label : this.label;
    this.slug = isString(data.slug) ? data.slug : this.slug;
    this.dateFrom = data.date_from ? moment(data.date_from) : this.dateFrom;
    this.dateTo = data.date_to ? moment(data.date_to) : this.dateTo;
    return this;
  }
}
