import { Collection } from '@evo/models';
import { Country, CountryStruct } from './country';

export class CountriesCollection extends Collection<Country> {

  public static TOP = ['US', 'JP', 'FR', 'MX', 'IT', 'DE', 'GB', 'ES', 'CN', 'RU'];

  public static create(data: CountryStruct[] = []) {
    return new CountriesCollection(data.map((item) => Country.create(item)));
  }

  public static empty() {
    return CountriesCollection.create([]);
  }

  public search(term: string) {
    term = 'string' == typeof term ? term : term ? (term + '') : '';
    term = term.toLowerCase().trim();
    return term ? this.items.filter((country) => {
      let name = (country.name).toLowerCase(),
        iso = country.iso.toLowerCase(),
        iso3 = country.iso3.toLowerCase();
      return (name.indexOf(term) > -1) || (iso3.indexOf(term) > -1) || (iso == term);
    }).slice(0, 10) : this.topten();
  }

  public topten() {
    let results: Country[] = [];

    CountriesCollection.TOP.forEach((iso) => {
      let country = this.items.find((c) => c.iso == iso);
      country && results.push(country);
    });

    return results;
  }
}
