import { DivisionShortcut, DivisionShortcutStruct, Platform, PlatformStruct } from '@auth/models';
import { Money, MoneyStruct, Count, CountStruct, Percent } from '@common/models';
import { Vendor, VendorStruct, IPAgentStruct, IPAgent } from '../../common';
import { Aircraft, AircraftStruct } from '../../../../aviation/models';
import { Location, LocationStruct } from '@geodata/models';
import { isNumber } from '@evo/utils/types';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface ReportStruct {
  uuid: string;
  trip_number: string;
  date_uplift: string;
  date_billed: string;

  invoice_volume: CountStruct;

  invoice_total: MoneyStruct;
  vendor_total: MoneyStruct;
  price_per_usg: MoneyStruct;
  margin_per_usg: MoneyStruct;

  margin: MoneyStruct;
  gross_profit: MoneyStruct;
  invoice_profit: MoneyStruct;
  margin_percent: number;

  commission_sales: MoneyStruct;
  commission_customer: MoneyStruct;
  commission_platform: MoneyStruct;

  aircraft: AircraftStruct;
  ip_agent: IPAgentStruct;
  vendor: VendorStruct;
  location: LocationStruct;
  division: DivisionShortcutStruct;
  platform: PlatformStruct;
}

export class Report extends Model<ReportStruct> {

  constructor (
    public uuid: string,
    public tripNumber: string,
    public dateUplift: Moment,
    public dateBilled: Moment,
    public invoiceVolume: Count,
    public invoiceTotal: Money,
    public vendorTotal: Money,
    public pricePerUsg: Money,
    public margin: Money,
    public marginPerUsg: Money,
    public grossProfit: Money,
    public invoiceProfit: Money,
    public marginPercent: Percent,
    public commissionSales: Money,
    public commissionCustomer: Money,
    public commissionPlatform: Money,
    public aircraft: Aircraft,
    public intoPlaneAgent: IPAgent,
    public vendor: Vendor,
    public location: Location,
    public division: DivisionShortcut,
    public platform: Platform
  ) {
    super();
  }

  public static create(data?: ReportStruct) {
    if (!data) {
      data = {} as ReportStruct;
    }
    return new Report(
      data.uuid || null,
      data.trip_number || null,
      data.date_uplift ? moment(data.date_uplift) : null,
      data.date_billed ? moment(data.date_billed) : null,
      data.invoice_volume ? Count.create(data.invoice_volume) : null,
      data.invoice_total ? Money.create(data.invoice_total) : null,
      data.vendor_total ? Money.create(data.vendor_total) : null,
      data.price_per_usg ? Money.create(data.price_per_usg) : null,
      data.margin ? Money.create(data.margin) : null,
      data.margin_per_usg ? Money.create(data.margin_per_usg) : null,
      data.gross_profit ? Money.create(data.gross_profit) : null,
      data.invoice_profit ? Money.create(data.invoice_profit) : null,
      isNumber(data.margin_percent) ? Percent.create(data.margin_percent) : null,
      data.commission_sales ? Money.create(data.commission_sales) : null,
      data.commission_customer ? Money.create(data.commission_customer) : null,
      data.commission_platform ? Money.create(data.commission_platform) : null,
      data.aircraft ? Aircraft.create(data.aircraft) : null,
      data.ip_agent ? IPAgent.create(data.ip_agent) : null,
      data.vendor ? Vendor.create(data.vendor) : null,
      data.location ? Location.create(data.location) : null,
      data.division ? DivisionShortcut.create(data.division) : null,
      data.platform ? Platform.create(data.platform) : null
    );
  }

  public override toString(): string {
    return `${this.uuid.slice(24)} | ${this.location.code} | ${this.intoPlaneAgent.name}`;
  }

  public toJSON(): ReportStruct {
    return {
      uuid: this.uuid,
      trip_number: this.tripNumber,
      date_uplift: this.dateUplift ? this.dateUplift.toISOString() : null,
      date_billed: this.dateBilled ? this.dateBilled.toISOString() : null,
      location: this.location ? this.location.toJSON() : null,
      aircraft: this.aircraft ? this.aircraft.toJSON() : null,
      ip_agent: this.intoPlaneAgent ? this.intoPlaneAgent.toJSON() : null,
      vendor: this.vendor ? this.vendor.toJSON() : null,
      invoice_volume: this.invoiceVolume ? this.invoiceVolume.toJSON() : null,
      invoice_total: this.invoiceTotal ? this.invoiceTotal.toJSON() : null,

      vendor_total: this.vendorTotal ? this.vendorTotal.toJSON() : null,
      price_per_usg: this.pricePerUsg ? this.pricePerUsg.toJSON() : null,
      margin: this.margin ? this.margin.toJSON() : null,
      margin_per_usg: this.marginPerUsg ? this.marginPerUsg.toJSON() : null,
      gross_profit: this.grossProfit ? this.grossProfit.toJSON() : null,
      invoice_profit: this.invoiceProfit ? this.invoiceProfit.toJSON() : null,
      margin_percent: this.marginPercent ? this.marginPercent.toJSON() : null,

      commission_sales: this.commissionSales ? this.commissionSales.toJSON() : null,
      commission_customer: this.commissionCustomer ? this.commissionCustomer.toJSON() : null,
      commission_platform: this.commissionPlatform ? this.commissionPlatform.toJSON() : null,

      division: this.division ? this.division.toJSON() : null,
      platform: this.platform ? this.platform.toJSON() : null
    };
  }

  public update(data: ReportStruct): this {
    return this;
  }
}
