import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-layout-access',
  templateUrl: './layout-access.component.html',
  styleUrls: ['./layout-access.component.scss']
})
export class LayoutAccessComponent implements OnInit, OnDestroy {

  constructor(private renderer: Renderer2) {

  }

  public ngOnInit(): void {
    this.renderer.addClass(document.body, 'auth');
  }

  public ngOnDestroy() {
    this.renderer.removeClass(document.body, 'auth');
  }
}
