import { Count, CountStruct, Money, MoneyStruct } from '@common/models';
import { isNumber, isObject, isString } from '@evo/utils/types';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface ReportChartItemStruct {
  date: string | number;
  invoice_volume: CountStruct;
  invoice_total: MoneyStruct;
  vendor_total: MoneyStruct;
  gross_profit: MoneyStruct;
  invoice_profit: MoneyStruct;
  margin: MoneyStruct;
  uplifts: number;
}

export class ReportChartItem extends Model<ReportChartItemStruct> {

  constructor (
    public date: Moment,
    public invoiceVolume: Count,
    public invoiceTotal: Money,
    public vendorTotal: Money,
    public grossProfit: Money,
    public invoiceProfit: Money,
    public margin: Money,
    public uplifts: number
  ) {
    super();
  }

  public static create(data: ReportChartItemStruct) {
    if (!data) {
      data = [] as any;
    }
    return new ReportChartItem(
      isString(data.date) || isNumber(data.date) ? moment(data.date) : null,
      isObject(data.invoice_volume) ? Count.create(data.invoice_volume) : null,
      isObject(data.invoice_total) ? Money.create(data.invoice_total) : null,
      isObject(data.vendor_total) ? Money.create(data.vendor_total) : null,
      isObject(data.gross_profit) ? Money.create(data.gross_profit) : null,
      isObject(data.invoice_profit) ? Money.create(data.invoice_profit) : null,
      isObject(data.margin) ? Money.create(data.margin) : null,
      isNumber(data.uplifts) ? data.uplifts : null
    );
  }

  public override toString(): string {
    return `${this.date} / ${this.invoiceTotal.toString()} / ${this.margin.toString()}`;
  }

  public toJSON(): ReportChartItemStruct {
    return {
      date: this.date ? this.date.toISOString() : null,
      invoice_volume: this.invoiceVolume ? this.invoiceVolume.toJSON() : null,
      invoice_total: this.invoiceTotal ? this.invoiceTotal.toJSON() : null,
      vendor_total: this.vendorTotal ? this.vendorTotal.toJSON() : null,
      gross_profit: this.grossProfit ? this.grossProfit.toJSON() : null,
      invoice_profit: this.invoiceProfit ? this.invoiceProfit.toJSON() : null,
      margin: this.margin ? this.margin.toJSON() : null,
      uplifts: this.uplifts
    };
  }

  public update(data: ReportChartItemStruct): this {
    return this;
  }
}
