import { isNumber, isString } from '@evo/utils/types';
import { Model } from '@evo/models';

export interface DepartmentStruct {
  uuid: string;
  name: string;
  level: number;
  description: string;
  parent?: DepartmentStruct;
}

export class Department extends Model<DepartmentStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public level: number,
    public description: string,
    public parent: Department
  ) {
    super();
  }

  public static create(data?: DepartmentStruct) {
    if (!data) {
      data = {} as any;
    }
    return new Department(
      isString(data.uuid) ? data.uuid : null,
      isString(data.name) ? data.name : null,
      isNumber(data.level) ? data.level : null,
      isString(data.description) ? data.description : null,
      data.parent ? Department.create(data.parent) : null
    );
  }

  public isParentOrEquals(d: Department) {
    return this.equals(d) || this.isParent(d);
  }

  public isParent(d: Department) {
    return this.uuid == d.parent.uuid;
  }

  public override equals(s: Department) {
    return s.uuid == this.uuid;
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): DepartmentStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      level: this.level,
      description: this.description,
      parent: this.parent ? this.parent.toJSON() : null
    };
  }

  public update(data: DepartmentStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.name = isString(data.name) ? data.name : this.name;
    this.level = isNumber(data.level) ? data.level : this.level;
    this.description = isString(data.description) ? data.description : this.description;
    this.parent = data.parent ? Department.create(data.parent) : this.parent;
    return this;
  }
}
