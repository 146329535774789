export enum COMPANY_TYPES {
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  SUPER_USER = 'SUPER_USER',
  PLATFORM = 'PLATFORM',
  VENDOR = 'VENDOR',
  CLIENT = 'CLIENT',
}

export const ADMIN_COMPANY_TYPES = [COMPANY_TYPES.SERVICE_PROVIDER, COMPANY_TYPES.SUPER_USER];
export const CLIENT_COMPANY_TYPES = [COMPANY_TYPES.CLIENT, COMPANY_TYPES.PLATFORM, COMPANY_TYPES.VENDOR];
