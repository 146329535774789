import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbConfirmConfig } from '@common/models';
import { observer } from '@evo/utils/libs/rxjs';
import { ToastService } from '@main/services';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit, AfterViewInit {

  @ViewChild('body', { static: false })
  public body: ElementRef;

  public title: string;
  public message: string;
  public reject: string;
  public resolve: string;

  public isDisabled = false;

  constructor (
    private config: NgbConfirmConfig,
    private activeModal: NgbActiveModal,
    private toast: ToastService,
  ) {
  }

  public ngOnInit() {
    this.title = this.config.title;
    this.message = this.config.message;
    this.reject = this.config.reject;
    this.resolve = this.config.resolve;
  }

  public ngAfterViewInit(): void {
    (this.body.nativeElement as HTMLDivElement).innerHTML = this.message;
  }

  public close() {
    this.activeModal.dismiss();
  }

  public submit() {
    this.isDisabled = true;
    this.config.callback().subscribe(observer((result) => {
      this.isDisabled = false;
      this.activeModal.close(result);
    }, (e) => {
      this.isDisabled = false;
      if (this.config.showError) {
        this.toast.error(e);
      } else {
        this.activeModal.dismiss(e);
      }
    }));
  }
}
