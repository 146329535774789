import { DivisionShortcut, DivisionShortcutStruct, Employee, EmployeeStruct, Platform, PlatformStruct } from 'src/app/auth/models';
import { IPAgent, IPAgentStruct, Vendor, VendorStruct } from '../../../models';
import { Aircraft, AircraftStruct } from '../../../../aviation/models';
import { environment } from '@environments/environment';
import { Location, LocationStruct } from '@geodata/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class AnalyticsParamsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public searchDivision(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/analytics/search/divisions/?${params.toString()}`).pipe(
      map((resp: DivisionShortcutStruct[]) => {
        return resp.map((d) => DivisionShortcut.create(d));
      })
    );
  }

  public searchAircraft(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/analytics/search/aircraft/?${params.toString()}`).pipe(
      map((resp: AircraftStruct[]) => {
        return resp.map((a) => Aircraft.create(a));
      })
    );
  }

  public searchIPA(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/analytics/search/fbo-ipa/?${params.toString()}`).pipe(
      map((resp: IPAgentStruct[]) => {
        return resp.map((i) => IPAgent.create(i));
      }),
    );
  }

  public searchLocation(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/analytics/search/locations/?${params.toString()}`).pipe(
      map((resp: LocationStruct[]) => {
        return resp.map((l) => Location.create(l));
      })
    );
  }

  public searchVendor(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/analytics/search/vendors/?${params.toString()}`).pipe(
      map((resp: VendorStruct[]) => {
        return resp.map((a) => Vendor.create(a));
      }),
    );
  }

  public searchPlatform(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/analytics/search/platforms/?${params.toString()}`).pipe(
      map((resp: PlatformStruct[]) => {
        return resp.map((p) => Platform.create(p));
      })
    );
  }

  public searchSales(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/analytics/search/sales-agents/?${params.toString()}`).pipe(
      map((resp: EmployeeStruct[]) => {
        return resp.map((p) => Employee.create(p));
      })
    );
  }
}
