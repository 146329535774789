import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventsService, LayoutService } from '@main/services';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.scss']
})
export class LayoutAdminComponent implements OnInit, OnDestroy {

  @ViewChild('container')
  public container: ElementRef;

  public sideBarShowBackdrop = true;
  public sideBarAnimate = true;
  public sideBarCloseOnClickOutside = false;
  public sideBarCloseOnClickBackdrop = true;
  public sideBarMode: any = 'slide';

  private subs: Subscription[];

  constructor (
    public layout: LayoutService,
    public events: EventsService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.subs = [
      this.router.events.pipe(
        filter((e) => e instanceof Scroll)
      ).subscribe(() => {
        this.container.nativeElement.parentElement.scrollTop = 0;
      })
    ];
  }

  public ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
    this.layout.removeContainer();
  }
}
