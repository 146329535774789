import { Money, MoneyStruct, Count, CountStruct } from '@common/models';
import { Month, MonthStruct } from '@common/models/month/month';
import { Platform, PlatformStruct } from '@auth/models';
import { isNumber, isNumberOrNull } from '@evo/utils/types';
import { Model } from '@evo/models';

export interface PlatformMonthlyStatsStruct {
  uuid: string;
  customers: number;

  orders: number;
  invoices: number;
  conversion: number;
  industry_average: number;

  volume: CountStruct;
  invoiced: MoneyStruct;
  commission: MoneyStruct;

  month: MonthStruct;
  platform: PlatformStruct;
}

export class PlatformMonthlyStats extends Model<PlatformMonthlyStatsStruct> {
  constructor (
    public uuid: string,
    public customers: number,
    public orders: number,
    public invoices: number,
    public conversion: number,
    public industryAverage: number,
    public volume: Count,
    public invoiced: Money,
    public commission: Money,
    public month: Month,
    public platform: Platform
  ) {
    super();
  }

  public static create(data: PlatformMonthlyStatsStruct) {
    return new PlatformMonthlyStats(
      data.uuid || null,
      isNumber(data.customers) ? data.customers : null,
      isNumber(data.orders) ? data.orders : null,
      isNumber(data.invoices) ? data.invoices : null,
      isNumber(data.conversion) ? data.conversion : null,
      isNumber(data.industry_average) ? data.industry_average : null,
      data.volume ? Count.create(data.volume) : null,
      data.invoiced ? Money.create(data.invoiced) : null,
      data.commission ? Money.create(data.commission) : null,
      data.month ? Month.create(data.month) : null,
      data.platform ? Platform.create(data.platform) : null
    );
  }

  public override toString(): string {
    let platfrom = this.platform ? this.platform.name : 'Stats',
      month = this.month ? this.month.toString() : '---';
    return `${platfrom} / ${month} / ${this.orders} / ${this.invoices} / ${this.conversion}`;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      customers: this.customers,
      orders: this.orders,
      invoices: this.invoices,
      conversion: this.conversion,
      industry_average: this.industryAverage,
      volume: this.volume ? this.volume.toJSON() : null,
      invoiced: this.invoiced ? this.invoiced.toJSON() : null,
      commission: this.commission ? this.commission.toJSON() : null,
      month: this.month ? this.month.toJSON() : null,
      platform: this.platform ? this.platform.toJSON() : null
    };
  }

  public update(data: PlatformMonthlyStatsStruct) {
    this.uuid = data.uuid || this.uuid;
    this.customers = isNumberOrNull(data.customers) ? data.customers : this.customers;
    this.orders = isNumberOrNull(data.orders) ? data.orders : this.orders;
    this.invoices = isNumberOrNull(data.invoices) ? data.invoices : this.invoices;
    this.conversion = isNumberOrNull(data.conversion) ? data.conversion : this.conversion;
    this.industryAverage = isNumberOrNull(data.industry_average) ? data.industry_average : this.industryAverage;
    this.volume = data.volume ? Count.create(data.volume) : this.volume;
    this.invoiced = data.invoiced ? Money.create(data.invoiced) : this.invoiced;
    this.commission = data.commission ? Money.create(data.commission) : this.commission;
    this.month = data.month ? Month.create(data.month) : this.month;
    this.platform = data.platform ? Platform.create(data.platform) : this.platform;
    return this;
  }
}
