import {AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn} from '@angular/forms';

export class ModelFormControl extends FormControl {

  constructor(
    formState?: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    public key?: string,
  ) {
    super(formState, validatorOrOpts, asyncValidator);
  }

  public getKeyName(name: string){
    return this.key ? this.key : name + '_id';
  }
}
