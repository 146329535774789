import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linebreaks'
})
export class LineBreaksPipe implements PipeTransform {

  public transform(text: string): string {
    text = text + '';
    return text.split('\n').join('<br />');
  }
}
