import { isArray, isBoolean, isBooleanOrNull, isNull, isNumber, isNumberOrNull, isObject, isStringOrNull } from '@evo/utils/types';
import { Country, CountryStruct, State, StateStruct } from '@geodata/models';
import { CompanyType, CompanyTypeStruct } from '../company-type';
import { ORDER_STATUS, ORDER_STATUS_DISABLED } from './meta';
import { Person, PersonStruct } from '../../users/person';
import { Model } from '@evo/models';
import { COMPANY_TYPES } from '../company';


export interface DivisionStruct {
  uuid: string;
  ecp: string;
  fma: string;

  name: string;
  phone: string;
  website: string;
  prices_file: string;

  state: StateStruct;
  country: CountryStruct;

  order_status: number;
  show_prices: boolean;
  has_rebate_as_platform: boolean;
  has_rebate_as_customer: boolean;

  sales_agent?: PersonStruct;
  types?: CompanyTypeStruct[];
  count_cart_orders?: number;
}

export class Division extends Model<DivisionStruct> {

  constructor (
    public uuid: string,
    public ecp: string,
    public fma: string,
    public name: string,
    public phone: string,
    public website: string,
    public pricesFile: string,
    public showPrices: boolean,
    public orderStatus: number,
    public hasRebateAsPlatform: boolean,
    public hasRebateAsCustomer: boolean,
    public country: Country,
    public state: State,
    public salesAgent: Person,
    public types: CompanyType[] = [],
    public countCartOrders: number
  ) {
    super();
  }

  public static create(data?: DivisionStruct) {
    if (!data) {
      data = {} as DivisionStruct;
    }
    return new Division(
      data.uuid || null,
      data.ecp || null,
      data.fma || null,
      data.name || null,
      data.phone || '',
      data.website || '',
      data.prices_file || null,
      isBoolean(data.show_prices) ? data.show_prices : false,
      isNumber(data.order_status) ? data.order_status : null,
      isBoolean(data.has_rebate_as_platform) ? data.has_rebate_as_platform : false,
      isBoolean(data.has_rebate_as_customer) ? data.has_rebate_as_customer : false,
      data.country ? Country.create(data.country) : null,
      data.state ? State.create(data.state) : null,
      data.sales_agent ? Person.create(data.sales_agent) : null,
      data.types ? data.types.map((t) => CompanyType.create(t)) : [],
      isNumber(data.count_cart_orders) ? data.count_cart_orders : null
    );
  }

  public get ordersEnabled() {
    return this.orderStatus == ORDER_STATUS.ALLOWED;
  }

  public get ordersDisabled() {
    return this.orderStatus != ORDER_STATUS.ALLOWED;
  }

  public get isPlaidConnected() {
    return true;
  }

  public is(code: COMPANY_TYPES) {
    let type = this.types.find((t) => {
      return t.code == code;
    });
    return !!type;
  }

  public isSomeOf(...types: COMPANY_TYPES[]) {
    for (let i = 0; i < types.length; i++) {
      if (this.is(types[i])) {
        return true;
      }
    }
    return false;
  }

  public isServiceProvider() {
    return this.is(COMPANY_TYPES.SERVICE_PROVIDER);
  }

  public isSuperUser() {
    return this.is(COMPANY_TYPES.SUPER_USER);
  }

  public isPlatform() {
    return this.is(COMPANY_TYPES.PLATFORM);
  }

  public isVendor() {
    return this.is(COMPANY_TYPES.VENDOR);
  }

  public isClient() {
    return this.is(COMPANY_TYPES.CLIENT);
  }

  public isDemo() {
    return this.fma == '12761311151658015';
  }


  public override toString(): string {
    return this.name;
  }

  public toJSON(): DivisionStruct {
    return {
      uuid: this.uuid,
      ecp: this.ecp,
      fma: this.fma,
      name: this.name,
      phone: this.phone,
      website: this.website,
      prices_file: this.pricesFile,
      show_prices: this.showPrices,
      order_status: this.orderStatus,
      has_rebate_as_platform: this.hasRebateAsPlatform,
      has_rebate_as_customer: this.hasRebateAsCustomer,
      country: this.country ? this.country.toJSON() : null,
      state: this.state ? this.state.toJSON() : null,
      sales_agent: this.salesAgent ? this.salesAgent.toJSON() : null,
      types: this.types ? this.types.map((t) => t.toJSON()) : null
    };
  }

  public update(data: DivisionStruct): this {
    this.uuid = isStringOrNull(data.uuid) ? data.uuid : this.uuid;
    this.ecp = isStringOrNull(data.ecp) ? data.ecp : this.ecp;
    this.fma = isStringOrNull(data.fma) ? data.fma : this.fma;
    this.name = isStringOrNull(data.name) ? data.name : this.name;
    this.phone = isStringOrNull(data.phone) ? data.phone : this.phone;
    this.website = isStringOrNull(data.website) ? data.website : this.website;
    this.pricesFile = isStringOrNull(data.prices_file) ? data.prices_file : this.pricesFile;
    this.showPrices = isBooleanOrNull(data.show_prices) ? data.show_prices : this.showPrices;
    this.orderStatus = isNumberOrNull(data.order_status) ? data.order_status : this.orderStatus;
    this.hasRebateAsPlatform = isBooleanOrNull(data.has_rebate_as_platform) ? data.has_rebate_as_platform : this.hasRebateAsPlatform;
    this.hasRebateAsCustomer = isBooleanOrNull(data.has_rebate_as_customer) ? data.has_rebate_as_customer : this.hasRebateAsCustomer;
    this.country = isObject(data.country) ? Country.create(data.country) : isNull(data.country) ? null : this.country;
    this.state = isObject(data.state) ? State.create(data.state) : isNull(data.state) ? null : this.state;
    this.salesAgent = isObject(data.sales_agent) ? Person.create(data.sales_agent) : isNull(data.sales_agent) ? null : this.salesAgent;
    this.types = isArray(data.types) ? data.types.map((t) => CompanyType.create(t)) : isNull(data.types) ? [] : this.types;
    return this;
  }

  public override clear() {
    this.uuid = null;
    this.ecp = null;
    this.fma = null;
    this.name = null;
    this.phone = null;
    this.website = null;
    this.pricesFile = null;
    this.orderStatus = null;
    this.hasRebateAsPlatform = false;
    this.hasRebateAsCustomer = false;
    delete this.country;
    delete this.state;
    delete this.salesAgent;
    this.types = [];
  }
}
