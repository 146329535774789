import { BrowserModule } from '@angular/platform-browser';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { CommonModule } from '../common/common.module';
import { SidebarModule } from 'ngx-angular-sidebar';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


// ******************************************************************************* Providers
import {
  SentryProvider,
  AppInitializerProvider,
  HttpInterceptorsProvider,
  UserProvider,
  DivisionProvider,
  CompanyProvider,
  NgbDateAdapterProvider,
  NgbDateParserFormatterProvider,
  BreadcrumbsProvider,
  TabsProvider
} from './providers';
// ******************************************************************************* Services
import {
  ToastServiceProvider, CeleryService, FilesService,
  LayoutService, LocalStorageService, EventsService,
  JWTServiceProvider, ClipboardService, NavigatorService,
  GoogleAnalyticsService
} from './services';
// ******************************************************************************* Routing
import {
  mainModuleRoutes,
  NotFoundGuard,
  RootRouteGuard,
  CompanyTypeGuard,
  PermissionGuard
} from './routing';
// ******************************************************************************* Components
import {
  AppComponent,
  NavigationComponent,
  ToastsContainerComponent,
  NotFoundComponent,

  LayoutAdminComponent,
  NavbarAdminComponent,
  SidebarAdminComponent,

  LayoutClientComponent,
  NavbarClientComponent,
  SidebarClientComponent,

  LayoutAccessComponent,
} from './components';


@NgModule({
  imports: [
    BrowserModule,
    LoadingBarModule,
    CommonModule.forRoot(),
    RouterModule.forRoot(mainModuleRoutes),
    SidebarModule.forRoot()
  ],
  declarations: [
    AppComponent,
    NavigationComponent,
    ToastsContainerComponent,
    NotFoundComponent,

    LayoutClientComponent,
    NavbarClientComponent,
    SidebarClientComponent,

    LayoutAdminComponent,
    NavbarAdminComponent,
    SidebarAdminComponent,

    LayoutAccessComponent,
  ],
  exports: [
    LayoutClientComponent,
    LayoutAdminComponent,
    LayoutAccessComponent,
  ],
  providers: [
    ToastServiceProvider,
    CeleryService,
    FilesService,
    LayoutService,
    LocalStorageService,
    EventsService,
    ClipboardService,
    JWTServiceProvider,
    NavigatorService,
    GoogleAnalyticsService,

    SentryProvider,
    AppInitializerProvider,
    HttpInterceptorsProvider,
    UserProvider,
    DivisionProvider,
    CompanyProvider,
    NgbDateAdapterProvider,
    NgbDateParserFormatterProvider,
    BreadcrumbsProvider,
    TabsProvider,

    NotFoundGuard,
    RootRouteGuard,
    CompanyTypeGuard,
    PermissionGuard
  ],
  bootstrap: [AppComponent]
})
export class MainModule {
}
