import { Person, PersonStruct } from '@auth/models/users/person';
import { Model } from '@evo/models';
import { Quarter, QuarterStruct } from '../quarter';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface NoteStruct {
  uuid: string;
  feedback: string;
  internal: string;
  date_created: string;
  date_updated: string;
  created_by: PersonStruct;
  quarter: QuarterStruct;
}

export class Note extends Model<NoteStruct> {

  constructor (
    public uuid: string,
    public feedback: string,
    public internal: string,
    public dateCreated: Moment,
    public dateUpdated: Moment,
    public createdBy: Person,
    public quarter: Quarter,
  ) {
    super();
  }

  public static create(data?: NoteStruct) {
    if (!data) {
      data = {} as any;
    }
    return new Note(
      data.uuid || null,
      data.feedback || '',
      data.internal || '',
      data.date_created ? moment(data.date_created) : null,
      data.date_updated ? moment(data.date_updated) : null,
      data.created_by ? Person.create(data.created_by) : null,
      data.quarter ? Quarter.create(data.quarter) : null,
    );
  }

  public getUserAvatar() {
    if (!this.createdBy || !this.createdBy.avatar) {
      return 'assets/img/user.png';
    }
    return this.createdBy.avatar;
  }

  public toJSON(): NoteStruct {
    return {
      uuid: this.uuid,
      feedback: this.feedback,
      internal: this.internal,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
      date_updated: this.dateUpdated ? this.dateUpdated.toISOString() : null,
      created_by: this.createdBy ? this.createdBy.toJSON() : null,
      quarter: this.quarter ? this.quarter.toJSON() : null,
    };
  }

  public update(data: NoteStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.feedback = data.feedback || this.feedback;
    this.internal = data.internal || this.internal;
    this.dateCreated = data.date_created ? moment(data.date_created) : this.dateCreated;
    this.dateUpdated = data.date_updated ? moment(data.date_updated) : this.dateUpdated;
    this.createdBy = data.created_by ? Person.create(data.created_by) : this.createdBy;
    this.quarter = data.quarter ? Quarter.create(data.quarter) : this.quarter;
    return this;
  }
}
