/**
 * Base model class
 */
export abstract class Model<T> {

  /**
   * Model can have functionality to get its own clone
   */
  public clone?(): any;

  /**
   * Clear all fields
   */
  public clear?(): void;

  /**
   * Convert model properties back to model json struct
   */
  public abstract toJSON(): T;

  /**
   * Update properties from data which comes from server
   */
  public abstract update(data: Partial<T>): this;

  /**
   * Returns Model Primary Key
   */
  public get pk() {
    return this['uuid'] || this['id'];
  }

  /**
   * Check equality with model of same type
   */
  public equals(m?: Model<any>) {
    return m && m instanceof Model && this.pk == m.pk;
  }

  /**
   * String representation of model
   * By default it will show stringified JSON
   */
  public toString() {
    return JSON.stringify(this.toJSON());
  }
}
