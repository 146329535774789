import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';

@Directive({
  selector: 'input[trim],textarea[trim]',
})
export class TrimDirective implements OnInit, OnDestroy {

  private static RE_EMPTY = /^\s*$/;
  private static RE_SPACES = /\s+/g;
  private static RE_LEFT_SPACES = /^\s+/;
  private static RE_RIGHT_SPACES = /\s+$/;

  private _trim: '' | 'blur' | false;
  @Input('trim')
  public set trim(trimOption: '' | 'blur' | false) {
    if (trimOption !== '' && trimOption !== 'blur' && trimOption !== false) {
      console.warn(`Note: The value ${JSON.stringify(trimOption)} is not assignable to the trim attribute.
        Only blank string (""), "blur" or false is allowed.`);
      this._trim = false;
      return;
    }
    this._trim = trimOption;

    const elem = this.elementRef.nativeElement;
    const eleValue = elem.value;
    if (trimOption !== false && eleValue !== TrimDirective.strip(eleValue)) {
      // initially trim the value if needed
      TrimDirective.dispatchEvent(elem, 'blur');
    }
  }

  public get trim() {
    return this._trim;
  }

  @Input() trimOnWriteValue = true;

  private _valueAccessor: ControlValueAccessor;
  private _writeValue: (value) => void;

  constructor(
    private elementRef: ElementRef,
    @Optional() private ngControl: NgControl,
  ) {
  }

  private static getCaret(el) {
    return {
      start: el.selectionStart,
      end: el.selectionEnd,
    };
  }

  private static setCaret(el, start, end) {
    el.selectionStart = start;
    el.selectionEnd = end;
    el.focus();
  }

  private static dispatchEvent(el: EventTarget, eventType) {
    const event = new Event(eventType, {bubbles: false, cancelable: false});
    el.dispatchEvent(event);
  }

  private static strip(value, rightSpace = false) {
    if (TrimDirective.RE_EMPTY.test(value)) {
      value = '';
    } else {
      value = (value + '').replace(TrimDirective.RE_SPACES, ' ').replace(TrimDirective.RE_LEFT_SPACES, '');
      value = rightSpace ? value : value.replace(TrimDirective.RE_RIGHT_SPACES, '');
    }
    return value;
  }

  private static trimValue(el, value, rightSpace = false) {
    el.value = TrimDirective.strip(value, rightSpace);
    TrimDirective.dispatchEvent(el, 'input');
  }

  public ngOnInit(): void {
    if (!this.ngControl) {
      console.warn('Note: The trim directive should be used with one of ngModel, formControl or formControlName directives.');
      return;
    }
    this._valueAccessor = this.ngControl.valueAccessor;
    this._writeValue = this._valueAccessor.writeValue;
    this._valueAccessor.writeValue = (value) => {
      const _value =
        this.trim === false || !value || 'function' !== typeof value.trim || !this.trimOnWriteValue
          ? value
          : TrimDirective.strip(value);
      if (this._writeValue) {
        this._writeValue.call(this._valueAccessor, _value);
      }
      if (value !== _value) {
        if (this._valueAccessor['onChange']) {
          this._valueAccessor['onChange'](_value);
        }
        if (this._valueAccessor['onTouched']) {
          this._valueAccessor['onTouched']();
        }
      }
    };
  }

  public ngOnDestroy(): void {
    if (this._valueAccessor && this._writeValue) {
      this._valueAccessor.writeValue = this._writeValue;
    }
  }

  @HostListener('blur', ['$event.target', '$event.target.value'])
  public onBlur(el: any, value: string): void {
    if (this.trim === false) {
      return;
    }
    if ((this.trim === '' || 'blur' === this.trim) && 'function' === typeof value.trim && TrimDirective.strip(value, false) !== value) {
      TrimDirective.trimValue(el, value, false);
      TrimDirective.dispatchEvent(el, 'blur'); // in case updateOn is set to blur
    }
  }

  @HostListener('input', ['$event.target', '$event.target.value'])
  public onInput(el: any, value: string): void {
    if (this.trim === false) {
      return;
    }
    if (this.trim === '' && 'function' === typeof value.trim && TrimDirective.strip(value, true) !== value) {
      let {start, end} = TrimDirective.getCaret(el);

      if (value[start - 1] === ' ' && start != value.length - 1) {
        start = start - 1;
        end = end - 1;
      }

      TrimDirective.trimValue(el, value, true);
      TrimDirective.setCaret(el, start, end);
    }
  }

}
