<div class="content-nav d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between bg-white border-bottom">

  <nav *ngIf="layout.breadcrumbs.length" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" class="px-3 py-2">

    <ol class="breadcrumb bg-transparent p-0 mb-0">
      <ng-container *ngFor="let crumb of layout.breadcrumbs.all()">
        <li *ngIf="!crumb.disabled" class="breadcrumb-item" routerLinkActive="active">
          <a [routerLink]="crumb.route" [queryParams]="crumb.params" class="fw-bold">{{ crumb.name }}</a>
        </li>
        <li *ngIf="crumb.disabled" class="breadcrumb-item" routerLinkActive="active">
          <span class="fw-bold">{{ crumb.name }}</span>
        </li>
      </ng-container>
    </ol>

  </nav>

  <div *ngIf="layout.tabs.length" class="d-flex align-items-center tabbable border-top">
    <ul class="nav nav-pills p-2">
      <li *ngFor="let tab of layout.tabs.all()" class="nav-item">
        <a class="nav-link" routerLinkActive="active" [class.disabled]="tab.disabled" [queryParams]="tab.params" [routerLinkActiveOptions]="{exact: tab.exact}"
           [routerLink]="tab.route">
          {{tab.name}}
        </a>
      </li>
    </ul>
  </div>
</div>