import { Collection } from '@evo/models';
import { Email, EmailStruct } from './email';

export class EmailsCollection extends Collection<Email> {
  public static create(emails: EmailStruct[] = []) {
    return new EmailsCollection(emails.map((e) => Email.create(e)));
  }

  public search(term: string, exclude?: string[]) {
    term = term || '';
    term = (term + '').toLowerCase();

    let items: Email[];

    if (exclude && exclude.length) {
      items = this.items.filter((e) => exclude.indexOf(e.email) == -1);
    } else {
      items = this.items.slice(0);
    }

    if (!term) {
      return items;
    }

    return items.filter((e) => {
      return e.email.indexOf(term) > -1;
    });
  }
}
