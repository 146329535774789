import {Route} from '@angular/router';


export const vonageRootRoute: Route = {
  path: 'vonage',
  data: {
    breadcrumb: 'Sales Analytics'
  },
  loadChildren: () => import('../../../../vonage/vonage.module').then(m => m.VonageModule)
};
