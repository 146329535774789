import { Money, MoneyStruct } from '@common/models';
import { Variation, VariationStruct } from '../variation';
import { Model } from '@evo/models';

export interface FuelVariationStruct {
  price: MoneyStruct;
  base_price: MoneyStruct;
  variation: VariationStruct;
  is_highlighted?: boolean;
  margin?: any;
}

export class FuelVariation extends Model<FuelVariationStruct> {
  constructor (
    public price: Money,
    public basePrice: Money,
    public variation: Variation,
    public isHighlighted: boolean,
    public margin: any
  ) {
    super();
  }

  public static create(data: FuelVariationStruct) {
    return new FuelVariation(
      data.price ? Money.create(data.price) : null,
      data.base_price ? Money.create(data.base_price) : null,
      data.variation ? Variation.create(data.variation) : null,
      data.is_highlighted || false,
      data.margin || null
    );
  }

  public toJSON() {
    return {
      price: this.price ? this.price.toJSON() : null,
      base_price: this.basePrice ? this.basePrice.toJSON() : null,
      variation: this.variation ? this.variation.toJSON() : null,
      is_highlighted: this.isHighlighted
    };
  }

  public update(data: FuelVariationStruct) {
    this.price = data.price ? Money.create(data.price) : this.price;
    this.basePrice = data.base_price ? Money.create(data.base_price) : this.price;
    this.variation = data.variation ? Variation.create(data.variation) : this.variation;
    this.isHighlighted = 'boolean' == typeof data.is_highlighted ? data.is_highlighted : this.isHighlighted;
    return this;
  }
}
