import { NgControl } from '@angular/forms';
import { CountControl } from '@evo/forms';

const ERROR_MESSAGES = {
  required: 'This field is required',
  minlength: 'Must have a minimum length of {} characters',
};

export function controlErrors(ngControl: NgControl | CountControl): [boolean, boolean, string[]] {

  let isInvalid = ngControl.dirty && ngControl.invalid,
    isValid = ngControl.dirty && ngControl.valid;

  if (!ngControl.errors) {
    return [isInvalid, isValid, [] as string[]];
  }

  let errors: string[] = [];

  Object.keys(ngControl.errors).map((key) => {
    let value = ngControl.errors[key],
      message = ERROR_MESSAGES[key];

    if ('string' == typeof value) {
      message = value;
    } else if (message) {
      if (key == 'minlength') {
        message = message.replace('{}', value['requiredLength']);
      }
    } else if (!message) {
      if ('boolean' == typeof value) {
        message = (key + '').charAt(0).toUpperCase() + (key + '').slice(1) + ' - ' + value;
      } else {
        message = value;
      }
    }

    errors.push(message);
  });

  return [isInvalid, isValid, errors];

}
