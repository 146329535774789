import { isBoolean, isBooleanOrNull, isNumberOrNull, isStringOrNull } from '@evo/utils/types';
import { Model } from '@evo/models';

export interface CurrencyStruct {
  uuid: number;
  code: string;
  name: string;
  number: number;
  decimals: number;

  symbol: string;
  symbol_is_first: boolean;
  symbol_has_space: boolean;
}

export class Currency extends Model<CurrencyStruct> {
  constructor (
    public uuid: number,
    public code: string,
    public name: string,
    public number: number,
    public decimals: number,
    public symbol: string,
    public symbolIsFirst: boolean,
    public symbolHasSpace: boolean
  ) {
    super();
  }

  public static create(data: CurrencyStruct) {
    return new Currency(
      data.uuid || null,
      data.code || '',
      data.name || '',
      data.number || null,
      data.decimals || null,
      data.symbol || null,
      isBoolean(data.symbol_is_first) ? data.symbol_is_first : null,
      isBoolean(data.symbol_has_space) ? data.symbol_has_space : null
    );
  }

  public override toString(): string {
    return `${this.code} - ${this.name}`;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      code: this.code,
      name: this.name,
      number: this.number,
      decimals: this.decimals,
      symbol: this.symbol,
      symbol_is_first: this.symbolIsFirst,
      symbol_has_space: this.symbolHasSpace
    };
  }

  public update(data: CurrencyStruct) {
    this.uuid = isStringOrNull(data.uuid) && data.uuid ? data.uuid : this.uuid;
    this.code = isStringOrNull(data.code) ? data.code : this.code;
    this.decimals = isNumberOrNull(data.decimals) ? data.decimals : this.decimals;
    this.number = isNumberOrNull(data.number) ? data.number : this.number;
    this.name = isStringOrNull(data.name) ? data.name : this.name;
    this.symbol = isStringOrNull(data.symbol) ? data.symbol : this.symbol;
    this.symbolIsFirst = isBooleanOrNull(data.symbol_is_first) ? data.symbol_is_first : this.symbolIsFirst;
    this.symbolHasSpace = isBooleanOrNull(data.symbol_has_space) ? data.symbol_has_space : this.symbolHasSpace;
    return this;
  }
}
