import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FactoryProvider} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';

export class NgbDateMomentAdapter extends NgbDateAdapter<Moment> {

  public fromModel(m: Moment): NgbDateStruct {
    if (!(m instanceof moment)) {
      return null;
    }
    return {
      year: m.year(),
      month: m.month() + 1,
      day: m.date(),
    };
  }

  public toModel(date: NgbDateStruct): Moment {
    return date ? moment.utc(Date.UTC(date.year, date.month - 1, date.day)) : null;
  }
}

export function dateAdapterFactory() {
  return new NgbDateMomentAdapter();
}

export const NgbDateAdapterProvider: FactoryProvider = {
  provide: NgbDateAdapter,
  useFactory: dateAdapterFactory,
};
