import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ORDER_STATUS } from '@auth/models/companies/division/meta';
import { environment } from '@environments/environment';
import { LayoutService } from '../../../services';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Division, User } from '@auth/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout-client',
  templateUrl: './layout-client.component.html',
  styleUrls: ['./layout-client.component.scss']
})
export class LayoutClientComponent implements OnInit, OnDestroy {

  @ViewChild('container', { static: true })
  public container: ElementRef;

  public sideBarShowBackdrop = true;
  public sideBarAnimate = true;
  public sideBarCloseOnClickOutside = false;
  public sideBarCloseOnClickBackdrop = true;
  public sideBarMode: any = 'slide';

  public ORDER = ORDER_STATUS;
  public SIGNUP_URL = environment.URL.SIGNUP + '/access/website/sign-up/' + this.user.email;

  private subs: Subscription[];

  constructor (
    public user: User,
    public division: Division,
    public layout: LayoutService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.subs = [
      this.router.events.pipe(filter((e) => e instanceof Scroll)).subscribe(() => {
        this.container.nativeElement.parentElement.scrollTop = 0;
      })
    ];
  }

  public ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

}
