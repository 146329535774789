import { isString } from '@evo/utils/types';
import { Model } from '@evo/models';
import { TASK_READY_STATES, TASK_STATES } from './task-states';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface CeleryTaskStruct {
  uuid: string;
  status: string;
  task_name?: string;
  date_done?: string;
  traceback?: string;

  meta?: string;
  result?: string;
  task_args?: string;
  task_kwargs?: string;
}

export class CeleryTask extends Model<CeleryTaskStruct> {
  constructor (
    public uuid: string,
    public name?: string,
    public status?: string,
    public dateDone?: Moment,
    public traceback?: string,
    public meta?: string,
    public result?: string,
    public args?: string,
    public kwargs?: string,
  ) {
    super();
  }

  public static create(data: CeleryTaskStruct) {
    return new CeleryTask(
      isString(data.uuid) ? data.uuid : null as any,
      isString(data.task_name) ? data.task_name : null as any,
      isString(data.status) ? data.status : null as any,
      isString(data.date_done) ? moment(data.date_done) : null as any,
      isString(data.traceback) ? data.traceback : null as any,
      isString(data.meta) ? data.meta : null as any,
      isString(data.result) ? data.result : null as any,
      isString(data.task_args) ? data.task_args : null as any,
      isString(data.task_kwargs) ? data.task_kwargs : null as any,
    );
  }

  public isCompleted() {
    return TASK_READY_STATES.indexOf(this.status) > -1;
  }

  public isSuccessfull() {
    return this.status == TASK_STATES.SUCCESS;
  }

  public override toString(): string {
    return `${this.uuid} - ${this.status}`;
  }

  public toJSON() {
    return {
      date_done: this.dateDone ? this.dateDone.toISOString() : null,
      meta: this.meta,
      result: this.result,
      status: this.status,
      task_args: this.args,
      uuid: this.uuid,
      task_kwargs: this.kwargs,
      task_name: this.name,
      traceback: this.traceback,
    };
  }

  public update(data: CeleryTaskStruct) {
    this.name = data.task_name || this.name;
    this.status = data.status || '';
    this.dateDone = data.date_done ? moment(data.date_done) : this.dateDone;
    this.traceback = data.traceback || this.traceback;
    this.meta = data.meta || this.meta;
    this.result = data.result || this.result;
    this.args = data.task_args || this.args;
    this.kwargs = data.task_kwargs || this.kwargs;
    return this;
  }
}
