import {Component, OnInit, TemplateRef} from '@angular/core';
import {ToastService} from '../../../services';


@Component({
  selector: 'app-toasts-container',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.scss'],
  host: { 'class': 'ngb-toasts position-absolute' }
})
export class ToastsContainerComponent implements OnInit {

  constructor(
    public toast: ToastService,
  ) {
  }

  public ngOnInit() {
  }

  public remove(toast: any) {
    this.toast.remove(toast);
  }

  public isTemplate(message: string | TemplateRef<any>) {
    return message instanceof TemplateRef;
  }
}
