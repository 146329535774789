import { Model } from '@evo/models';

export interface UnitsStruct {
  uuid: string;
  code: string;
  name: string;
  is_default: boolean;
}

export class Units extends Model<UnitsStruct> {
  constructor (
    public uuid: string,
    public code: string,
    public name: string,
    public isDefault: boolean,
  ) {
    super();
  }

  public static create(data: UnitsStruct) {
    return new Units(
      data.uuid || null,
      data.code || null,
      data.name || null,
      data.is_default || false,
    );
  }

  public override toString(): string {
    return `${this.code} - ${this.name}`;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      code: this.code,
      name: this.name,
      is_default: this.isDefault,
    };
  }

  public update(data: UnitsStruct) {
    this.uuid = data.uuid || this.uuid;
    this.code = data.code || this.code;
    this.name = data.name || this.name;
    this.isDefault = 'boolean' == typeof data.is_default ? data.is_default : this.isDefault;
    return this;
  }
}
