import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe as BaseDatePipe} from '@angular/common';
import {Moment} from 'moment';

export enum DATE_FORMATS {
  DATE_DOTS = 'dd.MM.yyyy',
  DATETIME = 'd MMM yyyy HH:mm',
  DATE = 'd MMM yyyy',
  TIME = 'HH:mm',
}

@Pipe({
  name: 'moment',
})
export class DatePipe extends BaseDatePipe implements PipeTransform {

  public readonly dateFormats = {
    date: DATE_FORMATS.DATE,
    dateDots: DATE_FORMATS.DATE_DOTS,
    datetime: DATE_FORMATS.DATETIME,
    time: DATE_FORMATS.TIME,
  };

  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  public override transform(value: Moment | Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  public override transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  public override transform(value: string | number | Date, format = 'date', timezone?: string, locale?: string) {
    format = this.dateFormats[format] || format;
    return super.transform(value as string | number | Date, format, timezone, locale);
  }
}
