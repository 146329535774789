import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ClipboardService {

  constructor () {
  }

  public async write(text: string) {
    try {
      return await this.copyClipboardApi(text);
    } catch (e1) {
      try {
        return await this.copyExecCommand(text);
      } catch (e2) {
        throw new DOMException('Failed both navigator.clipboard.writeText and document.execCommand(\'copy\')', 'NotSupportedError');
      }
    }
  }

  public async copyClipboardApi(text) {
    // Use the Async Clipboard API when available. Requires a secure browsing context (i.e. HTTPS)
    if (!navigator.clipboard) {
      throw new DOMException('Clipboard api is not supported', 'NotSupportedError');
    }
    return navigator.clipboard.writeText(text);
  }

  public async copyExecCommand(text) {

    const span = document.createElement('span');
    span.textContent = text;

    span.style.whiteSpace = 'pre';
    span.style.webkitUserSelect = 'auto';
    span.style.userSelect = 'all';

    document.body.appendChild(span);

    const selection = window.getSelection();
    const range = window.document.createRange();
    selection.removeAllRanges();
    range.selectNode(span);
    selection.addRange(range);

    // Copy text to the clipboard
    let success = false;
    try {
      success = window.document.execCommand('copy');
    } finally {
      // Cleanup
      selection.removeAllRanges();
      window.document.body.removeChild(span);
    }

    if (!success) {
      throw new DOMException('Failed to run document.execCommand(\'copy\')', 'NotSupportedError');
    }
  }

}

