export enum ORDER_PERMISSIONS {
  CREATE = 'fuel.add_order',
  UPDATE = 'fuel.change_order',
  DELETE = 'fuel.delete_order',
  VIEW = 'fuel.view_order'
}

export enum SEARCH_PERMISSIONS {
  CREATE = 'fuel.add_search',
  UPDATE = 'fuel.change_search',
  DELETE = 'fuel.delete_search',
  VIEW = 'fuel.view_search'
}

export enum ANALYTICS_PERMISSIONS {
  VIEW_ALL = 'fuel.view-all_sales_analytics',
  VIEW_OWN = 'fuel.view-own_sales_analytics',
  VIEW_FBO = 'fuel.view-fbo_sales_analytics'
}

export enum REBATE_PERMISSIONS {
  PLATFORM = 'fuel.platform_rebate',
  CUSTOMER = 'fuel.customer_rebate'
}
