import { Pagination, PaginationStruct } from '@common/models';
import { environment } from '@environments/environment';
import { Location, LocationStruct } from '@geodata/models';
import { toPrimaryKey } from '@evo/utils/helpers';
import { AdminStats, AdminStatsStruct, FboDashboardStats, FboDashboardStatsStruct, IPAgent, IPAgentStruct, ReportItem, ReportItemStruct } from '../../../models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class FBODashboardService {

  constructor (
    private http: HttpClient
  ) {
  }

  public airports(params: URLSearchParams, pagination?: Pagination<LocationStruct, Location>) {
    params || (params = new URLSearchParams());
    if (!params.has('page')) {
      params.append('page', '1');
    }
    return this.http.get(`${environment.URL.API}/fuel/admin/fbo-dashboard/airports/?${params.toString()}`).pipe(
      map((data: PaginationStruct<LocationStruct>) => {
        return pagination ? pagination.update(data) : Pagination.create<LocationStruct, Location>(data, Location);
      }),
    );
  }

  public airport(airport: Location | string, params?: URLSearchParams) {
    let uuid = toPrimaryKey(airport),
      url = `${environment.URL.API}/fuel/admin/fbo-dashboard/airports/${uuid}/`;
    params && (url += `?${params.toString()}`);
    return this.http.get(url).pipe(
      map((data: LocationStruct) => {
        return Location.create(data);
      }),
    );
  }

  public airportsNearby(airport: Location | string, params: URLSearchParams, pagination?: Pagination<LocationStruct, Location>) {
    let uuid = toPrimaryKey(airport);
    return this.http.get(`${environment.URL.API}/fuel/admin/fbo-dashboard/airports/${uuid}/nearby/?${params.toString()}`).pipe(
      map((data: PaginationStruct<LocationStruct>) => {
        return pagination ? pagination.update(data) : Pagination.create<LocationStruct, Location>(data, Location);
      }),
    );
  }

  public ipAgents(airport: Location | string, params: URLSearchParams) {
    let uuid = toPrimaryKey(airport);
    return this.http.get(`${environment.URL.API}/fuel/admin/fbo-dashboard/airports/${uuid}/ip-agents/?${params.toString()}`).pipe(
      map((data: ReportItemStruct[]) => {
        return data.map((l) => ReportItem.create(l));
      }),
    );
  }

  public stats(params: URLSearchParams): Observable<FboDashboardStats> {
    return this.http.get(`${environment.URL.API}/fuel/admin/fbo-dashboard/stats/?${params.toString()}`).pipe(
      map((resp: FboDashboardStatsStruct) => {
        return FboDashboardStats.create(resp);
      })
    );
  }

}
