import { Count, CountStruct } from '@common/models';
import { Model } from '@evo/models';

export interface VariationStruct {
  count_from: CountStruct;
  count_to: CountStruct;
}

export class Variation extends Model<VariationStruct> {
  constructor (
    public countFrom: Count,
    public countTo: Count
  ) {
    super();
  }

  public static create(data: VariationStruct) {
    return new Variation(
      data.count_from ? Count.create(data.count_from) : null,
      data.count_to ? Count.create(data.count_to) : null
    );
  }

  public toJSON() {
    return {
      count_from: this.countFrom ? this.countFrom.toJSON() : null,
      count_to: this.countTo ? this.countTo.toJSON() : null
    };
  }

  public update(data: VariationStruct) {
    this.countFrom = data.count_from ? Count.create(data.count_from) : this.countFrom;
    this.countTo = data.count_to ? Count.create(data.count_to) : this.countTo;
    return this;
  }
}
