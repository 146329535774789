import { DestinationType, DestinationTypeStruct, FlightType, FlightTypeStruct } from '../../common';
import { FuelVariation, FuelVariationStruct } from '../fuel-variation';
import { Model } from '@evo/models';

export interface ProposalStruct {
  uuid: string;
  tax_incl: boolean;
  flight_type: FlightTypeStruct;
  destination_type: DestinationTypeStruct;
  variations: FuelVariationStruct[];
}

export class Proposal extends Model<ProposalStruct> {
  constructor (
    public uuid: string,
    public taxIncl: boolean,
    public flightType: FlightType,
    public destinationType: DestinationType,
    public variations: FuelVariation[] = [],
  ) {
    super();
  }

  public static create(data: ProposalStruct) {
    return new Proposal(
      data.uuid || '',
      data.tax_incl || false,
      data.flight_type ? FlightType.create(data.flight_type) : null,
      data.destination_type ? DestinationType.create(data.destination_type) : null,
      data.variations ? data.variations.map((v) => FuelVariation.create(v)) : [],
    );
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      tax_incl: this.taxIncl,
      variations: this.variations.map((v) => v.toJSON()),
      flight_type: this.flightType ? this.flightType.toJSON() : null,
      destination_type: this.destinationType ? this.destinationType.toJSON() : null,
    };
  }

  public update(data: ProposalStruct) {
    return this;
  }
}
