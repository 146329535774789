import {AbstractControl, ValidatorFn, Validators as BaseValidators} from '@angular/forms';

export function minLength(minLength: number, message: string): ValidatorFn {
  return (control: AbstractControl) => {
    let res = BaseValidators.minLength(minLength)(control);
    if (!res) {
      return null;
    }
    return {
      minlength: message.replace('|minLength|', minLength.toString()),
    };
  };
}
