import { Pagination, PaginationStruct } from '@common/models';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { VendorStruct, Vendor } from '../../../models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VendorsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(page: number, orderBy = 'name', pagination?: Pagination<VendorStruct, Vendor>) {
    return this.http.get(`${environment.URL.API}/fuel/admin/vendors/`).pipe(
      map((resp: PaginationStruct<VendorStruct>) => {
        return pagination ? pagination.update(resp) : Pagination.create(resp, Vendor);
      }),
    );
  }

  public get(ipagent: Vendor | string) {
    let uuid = toPrimaryKey(ipagent);
    return this.http.get(`${environment.URL.API}/fuel/admin/vendors/${uuid}/`).pipe(
      map((resp: VendorStruct) => {
        return Vendor.create(resp);
      }),
    );
  }

  public search(term: string) {
    let params = { term };
    return this.http.get(`${environment.URL.API}/fuel/admin/vendors/search/`, { params }).pipe(
      map((resp: VendorStruct[]) => {
        return resp.map((a) => Vendor.create(a));
      }),
    );
  }

}
