<div class="input-group"
     [class.input-group-lg]="size == 'lg'"
     [class.input-group-sm]="size == 'sm'"
     [class.is-invalid]="isInvalid">
  <input #prefixRef
         #typehead="ngbTypeahead"
         type="text"
         class="form-control"
         autocomplete="off"
         random-name
         style="display: inline-block !important; width: 90px !important; flex: unset;"
         [class.invalid]="isInvalid"
         [class.valid]="isValid"
         [disabled]="isDisabled"
         [placeholder]="placeholderPrefix"
         (input)="prefixInput($event)"
         (focus)="prefixFocus($event)"
         (blur)="prefixBlur($event)"
         [inputFormatter]="inputFormatter"
         [resultFormatter]="resultFormatter"
         [(ngModel)]="prefix"
         [ngbTypeahead]="search"
         [focusFirst]="true"
         (selectItem)="prefixSelect($event)">
  <input #phoneRef
         type="text"
         class="form-control"
         autocomplete="off"
         random-name
         [class.is-invalid]="isInvalid"
         [class.is-valid]="isValid"
         [disabled]="isDisabled"
         [placeholder]="placeholderPhone"
         (focus)="phoneFocus($event)"
         (keydown)="phoneKeyDown($event)"
         [(ngModel)]="phone"
         (ngModelChange)="phoneChange($event)"/>
</div>
<small class="invalid-feedback" *ngIf="this.isInvalid"> {{errors[0]}}</small>
