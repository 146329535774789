export class EventLike<T = any> {

  private _defaultPrevented = false;

  constructor(
    public data: T
  ) {
  }

  public static create<T>(data: T) {
    return new EventLike(data);
  }

  public get defaultPrevented() {
    return this._defaultPrevented;
  }

  public preventDefault() {
    this._defaultPrevented = true;
  }
}
