import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isFunction, isString } from '@evo/utils/types';
import { User, Division } from '@auth/models';
import { ToastService } from '@main/services';
import { Redirect } from '../../redirects';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
  constructor (
    private user: User,
    private division: Division,
    private toast: ToastService,
    private router: Router
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let data = route.data || {} as any,
      permissions = data.permissions || [];

    if (this.user.canOneOf(...permissions)) {
      return true;
    }

    this.toast.error(`Permission required ${permissions[0]}`);

    let redirect: Redirect | { [key: string]: Redirect } = route.data['redirect'];
    redirect = (redirect && redirect['permission']) ? redirect['permission'] : false;
    redirect = (isFunction(redirect)) ? redirect(route, state) : redirect;

    if (redirect) {
      if (isString(redirect)) {
        this.router.navigateByUrl(redirect);
      } else {
        if (this.division.isSuperUser() || this.division.isServiceProvider()) {
          this.router.navigateByUrl('/fuel/admin/analytics/dashboard');
        } else if (this.division.isPlatform()) {
          this.router.navigateByUrl('/fuel/platform/dashboard');
        } else if (this.division.isClient()) {
          this.router.navigateByUrl('/fuel/search');
        } else {
          this.toast.error(['Division type is not set.', 'Please contact site admin for further actions.'], 'Redirect failed.');
        }
      }
    }

    return false;
  }
}
