import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ModelControl } from '../model-control';
import { Count } from '@common/models';

export class CountControl extends FormGroup {

  constructor (
    public valueControl: FormControl,
    public unitsControl: ModelControl,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super({ value: valueControl, units: unitsControl }, validatorOrOpts);
  }

  private _updateValue() {
    let method = '_reduceValue',
      value = this[method]();
    Object.assign(this, { value: Count.create(value) });
  }
}
