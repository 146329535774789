import { AfterViewInit, Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DateRange } from '@main/models';
import { DatePipe } from '../../../pipes';
import { Moment } from 'moment';


@Component({
  selector: 'app-date-range-control',
  templateUrl: './date-range-control.component.html',
  styleUrls: ['./date-range-control.component.scss']
})
export class DateRangeControlComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('dropdown', { static: true })
  public dropdown: NgbDropdown;

  @ViewChild('toogle', { static: true })
  public toogle: ElementRef;

  @Input()
  public size: 'md' | 'sm' | 'lg' = 'md';

  @Input()
  public ranges: DateRange[] = [];

  public range: DateRange;
  public custom = new DateRange('Custom', null, null, false, true);

  public isDisabled = false;
  public showDatePicker = false;

  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate;
  public toDate: NgbDate | null = null;

  private pipe: DatePipe;

  private _onTouched = () => {
  };
  private _onChange = (_: any) => {
  };

  constructor (
    private elementRef: ElementRef,
    private ngControl: NgControl,
    private calendar: NgbCalendar,
    private adapter: NgbDateAdapter<Moment>,
    @Inject(LOCALE_ID) public locale: string,
    protected renderer: Renderer2,
  ) {
    ngControl.valueAccessor = this;
  }

  public ngOnInit(): void {
    this.pipe = new DatePipe(this.locale);
  }

  public ngAfterViewInit() {
    let $btn = this.toogle.nativeElement,
      $el = this.elementRef.nativeElement as Element,
      className = $el.className;
    if (className) {
      this.renderer.removeAttribute($el, 'class');
      $btn.className = [className, $btn.className].join(' ');
    }
  }

  public get title() {
    if (this.range) {
      return this.pipe.transform(this.range.dateFrom) + ' - ' + this.pipe.transform(this.range.dateTo);
    }
    return 'Select Date Range ...';
  }

  public select($e: Event, range: DateRange) {
    this.showDatePicker = false;
    (this.toogle?.nativeElement as HTMLButtonElement).focus();
    this.dropdown.isOpen() && this.dropdown.close();
    if (range != this.range) {
      this.range = range;
      this._onChange(range);
    }
  }

  public openDatePicker($e: Event) {
    $e.preventDefault();
    $e.stopPropagation();
    $e.stopImmediatePropagation();
    this.showDatePicker = true;
    this.custom.dateFrom = this.range.dateFrom;
    this.custom.dateTo = this.range.dateTo;
    this.fromDate = NgbDate.from(this.adapter.fromModel(this.range.dateFrom));
    this.toDate = NgbDate.from(this.adapter.fromModel(this.range.dateTo));
  }

  /********************************** Datepicker **********************************************************/
  public onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.toDate = date.after(this.fromDate) ? date : this.fromDate;
      this.fromDate = date.after(this.fromDate) ? this.fromDate : date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate && this.toDate) {
      this.dropdown.isOpen() && this.dropdown.close();
      this.custom.dateFrom = this.adapter.toModel(this.fromDate);
      this.custom.dateTo = this.adapter.toModel(this.toDate);
      this.range = this.custom;
      this._onChange(this.range);
    }
  }

  public isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && (
      date.after(this.fromDate) && date.before(this.hoveredDate) ||
      date.before(this.fromDate) && date.after(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  /********************************** Control Value Accessor **********************************************/

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: (value: any) => any) {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => any) {
    this._onTouched = fn;
  }

  public writeValue(range: DateRange | null) {
    this.range = range;
    if (range) {
      this.fromDate = NgbDate.from(this.adapter.fromModel(range.dateFrom));
      this.toDate = NgbDate.from(this.adapter.fromModel(range.dateTo));
      if (range.isCustom) {
        this.custom = range;
        this.showDatePicker = true;
      } else {
        this.custom.dateFrom = range.dateFrom;
        this.custom.dateTo = range.dateTo;
        this.showDatePicker = false;
      }
    } else {
      this.fromDate = this.calendar.getToday();
      this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
      this.custom.dateFrom = this.adapter.toModel(this.fromDate);
      this.custom.dateTo = this.adapter.toModel(this.toDate);
      this.showDatePicker = false;
    }
  }

}
