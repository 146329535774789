import { Salutation, SalutationStruct } from '@common/models';
import { Department, DepartmentStruct } from '../../../../hr/models';
import { isArray, isNull } from '@evo/utils/types';
import { Model } from '@evo/models';
import { Group, GroupStruct } from '../../permissions';

export interface UserStruct {
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
  avatar: string;
  salutation: SalutationStruct;
  is_superuser: boolean;
  is_staff: boolean;
  is_active: boolean;
  department: DepartmentStruct;
  permissions: string[];
  group: GroupStruct;
}

export class User extends Model<UserStruct> {

  constructor (
    public uuid: string,
    public email: string,
    public firstName: string,
    public lastName: string,
    public avatar: string,
    public salutation: Salutation,
    private _isSuperUser: boolean = false,
    private _isStaff: boolean = false,
    public isActive: boolean = true,
    public department?: Department,
    public group?: Group,
    public permissions: string[] = [],
  ) {
    super();
  }

  public static create(data?: UserStruct) {
    if (!data) {
      data = {} as UserStruct;
    }

    return new User(
      data.uuid || null,
      data.email,
      data.first_name,
      data.last_name,
      data.avatar,
      data.salutation ? Salutation.create(data.salutation) : null,
      !!data.is_superuser,
      !!data.is_staff,
      !!data.is_active,
      data.department ? Department.create(data.department) : null,
      data.group ? Group.create(data.group) : null,
      isArray(data.permissions) ? data.permissions : [],
    );

  }

  /**************************************** Permissions ************************************************/
  /**
   * Check if user is in some of provided groups
   */
  public is(...args: string[] | string[][]) {
    if (!this.group) {
      return false;
    }
    if (args.length == 1 && Array.isArray(args[0])) {
      args = args[0] as string[];
    }
    for (let i = 0; i < args.length; i++) {
      let name = args[i] as string;
      if (name == this.group.name) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check if user has permission
   */
  public can(permission: string) {
    return this.permissions.indexOf(permission) > -1;
  }

  /**
   * Check if user has not permission
   */
  public cannot(...permissions: string[]) {
    for (let i = 0; i < permissions.length; i++) {
      if (this.can(permissions[i])) {
        return false;
      }
    }
    return true;
  }

  /**
   * Check if user has at least one permission from provided
   */
  public some(...permissions: string[]) {
    for (let i = 0; i < permissions.length; i++) {
      if (this.can(permissions[i])) {
        return true;
      }
    }
    return false;
  }

  public canOneOf(...permissions: string[]) {
    return this.some(...permissions);
  }

  /**
   * Django related functionality, check if user is superuser
   */
  public isSuperUser() {
    return this._isSuperUser;
  }

  /**
   * Django related functionality, check if user is staff
   */
  public isStaff() {
    return this._isStaff;
  }

  /**
   * Check if user is logged in
   */
  public isAuthenticated() {
    return !!this.uuid;
  }

  /********************************************************************************************************/

  public getFullName() {
    let salutation = this.salutation ? this.salutation.name : '',
      firstName = this.firstName || '',
      lastName = this.lastName || '';

    return [salutation, firstName, lastName].join(' ');
  }

  public abbr() {
    let abbr = '';
    if (this.firstName && this.lastName) {
      abbr = this.firstName.charAt(0) + this.lastName.charAt(0);
    } else if (this.firstName) {
      abbr = this.lastName.slice(0, 2);
    } else if (this.lastName) {
      abbr = this.lastName.slice(0, 2);
    }
    return abbr.toUpperCase();
  }

  /********************************************************************************************************/
  public override clear() {
    this.uuid = null;
    this.email = null;
    this.firstName = null;
    this.lastName = null;
    this.avatar = null;
    delete this.salutation;
    this._isSuperUser = false;
    this._isStaff = false;
    this.isActive = true;
    delete this.department;
    delete this.group;
    this.permissions = [];
  }

  public toJSON(): UserStruct {
    return {
      uuid: this.pk,
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
      avatar: this.avatar,
      salutation: this.salutation ? this.salutation.toJSON() : null,
      is_superuser: this._isSuperUser,
      is_staff: this._isStaff,
      is_active: this.isActive,
      department: this.department ? this.department.toJSON() : null,
      group: this.group ? this.group.toJSON() : null,
      permissions: this.permissions,
    };
  }

  public update(data: UserStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.firstName = data.first_name || this.firstName;
    this.lastName = data.last_name || this.lastName;
    this.email = data.email || this.email;
    this.salutation = data.salutation ? Salutation.create(data.salutation) : isNull(data.salutation) ? null : this.salutation;
    this.avatar = 'string' == typeof data.avatar ? data.avatar : null;
    this._isSuperUser = 'boolean' == typeof data.is_superuser ? !!data.is_superuser : this._isSuperUser;
    this._isStaff = 'boolean' == typeof data.is_staff ? !!data.is_staff : this._isStaff;
    this.department = data.department ? Department.create(data.department) : this.department;
    this.group = data.group ? Group.create(data.group) : this.group;
    this.permissions = isArray(data.permissions) ? data.permissions : this.permissions;
    return this;
  }
}
