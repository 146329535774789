import { debounceTime, distinctUntilChanged, first, skip } from 'rxjs/operators';
import { FactoryProvider, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Toast, TOAST_STATUSES } from '../../models';
import { ModelForm } from '@evo/forms';
import { Subject, merge } from 'rxjs';

export declare type ToastMessage = string | string[] | { [key: string]: string | string[] };

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  public static FORM_CODES = [400];
  public static DEFAULT_DELAY = 7000;
  public static MESSAGE_DELAY = 500;

  public toasts: Toast[] = [];
  public errorDelay = new Subject();

  constructor () {
    merge(
      this.errorDelay.pipe(first()),
      this.errorDelay.pipe(
        skip(1),
        debounceTime(ToastService.MESSAGE_DELAY),
        distinctUntilChanged((a: any, b) => {
          if (a instanceof HttpErrorResponse) {
            return b && b['error'] && a.error == b['error'];
          }
          return a == b;
        }),
      )).subscribe((e) => this.error(e));
  }

  public processErrorResponse(e: HttpErrorResponse, form: ModelForm, codes = ToastService.FORM_CODES) {
    if (codes.indexOf(e.status) > -1) {
      return form.processErrorResponse(e);
    }
    return this.error(e);
  }

  public success(message: ToastMessage, title?: string, delay = ToastService.DEFAULT_DELAY) {
    this.showAll(Toast.create(message, TOAST_STATUSES.SUCCESS, title, delay));
  }

  public primary(message: ToastMessage, title?: string, delay = ToastService.DEFAULT_DELAY) {
    this.showAll(Toast.create(message, TOAST_STATUSES.PRIMARY, title, delay));
  }

  public info(message: ToastMessage, title?: string, delay = ToastService.DEFAULT_DELAY) {
    this.showAll(Toast.create(message, TOAST_STATUSES.INFO, title, delay));
  }

  public warning(message: ToastMessage, title?: string, delay = ToastService.DEFAULT_DELAY) {
    this.showAll(Toast.create(message, TOAST_STATUSES.WARNING, title, delay));
  }

  public error(message: ToastMessage | HttpErrorResponse, title?: string, delay = ToastService.DEFAULT_DELAY) {
    if (!message) {
      return;
    }
    let toasts = (message instanceof HttpErrorResponse) ? Toast.fromErrorResponse(message, title, delay)
      : Toast.create(message, TOAST_STATUSES.ERROR, title, delay);
    this.showAll(toasts);
  }

  private showAll(toasts: Toast[], delayBetween = 0) {
    toasts.forEach((t, i) => {
      setTimeout(() => {
        this.show(t);
      }, i * delayBetween);
    });
  }

  public show(toast: Toast) {
    this.toasts.push(toast);
    setTimeout(() => {
      this.remove(toast);
    }, toast.delay || ToastService.DEFAULT_DELAY);
  }

  public remove(toast: Toast) {
    let index = this.toasts.indexOf(toast);
    this.toasts.splice(index, 1);
  }

}

export let TOAST_SERVICE: ToastService;

export function toastServiceFactory(http, storage) {
  if (!TOAST_SERVICE) {
    TOAST_SERVICE = new ToastService();
  }
  return TOAST_SERVICE;
}

export const ToastServiceProvider: FactoryProvider = {
  provide: ToastService,
  useFactory: toastServiceFactory,
  deps: []
};
