import { Aircraft, AircraftStruct } from '@aviation/models';
import { Location, LocationStruct } from '@geodata/models';
import { environment } from '@environments/environment';
import { IPAgent, IPAgentStruct } from '@fuel/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AnalyticsParamsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public searchAircraft(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/analytics/search/aircraft/?${params.toString()}`).pipe(
      map((resp: AircraftStruct[]) => {
        return resp.map((a) => Aircraft.create(a));
      })
    );
  }

  public searchIPA(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/analytics/search/fbo-ipa/?${params.toString()}`).pipe(
      map((resp: IPAgentStruct[]) => {
        return resp.map((i) => IPAgent.create(i));
      })
    );
  }

  public searchLocation(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/analytics/search/location/?${params.toString()}`).pipe(
      map((resp: LocationStruct[]) => {
        return resp.map((l) => Location.create(l));
      })
    );
  }
}
