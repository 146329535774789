import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isFunction, isString } from '@evo/utils/types';
import { COMPANY_TYPES, Division } from '@auth/models';
import { ToastService } from '@main/services';
import { Injectable } from '@angular/core';
import { Redirect } from '../../redirects';


@Injectable({ providedIn: 'root' })
export class CompanyTypeGuard implements CanActivate {
  constructor (
    private router: Router,
    private division: Division,
    private toast: ToastService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const types = (route.data['companyTypes'] || []) as COMPANY_TYPES[];

    if (this.division.isSomeOf(...types)) {
      return true;
    }

    let redirect: Redirect | { [key: string]: Redirect } = route.data['redirect'];
    redirect = (redirect && redirect['companyType']) ? redirect['companyType'] : false;
    redirect = (isFunction(redirect)) ? redirect(route, state) : redirect;

    if (redirect) {
      if (isString(redirect)) {
        this.router.navigateByUrl(redirect);
      } else {
        if (this.division.isSuperUser() || this.division.isServiceProvider()) {
          this.router.navigateByUrl('/fuel/admin/analytics/dashboard');
        } else if (this.division.isPlatform()) {
          this.router.navigateByUrl('/fuel/platform/dashboard');
        } else if (this.division.isClient()) {
          this.router.navigateByUrl('/fuel/search');
        } else {
          this.toast.error(['Division type is not set.', 'Please contact site admin for further actions.'], 'Redirect failed.');
        }
      }
    }

    return false;
  }
}
