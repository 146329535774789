import { Model } from '@evo/models';
import { isBooleanOrNull, isNumberOrNull, isStringOrNull } from '@evo/utils/types';

export interface PermissionStruct {
  id: number;
  name: string;
  codename: string;
  description: string;
  is_client_permission: boolean;
  model: string;
  module: string;
}


export class Permission extends Model<PermissionStruct> {

  constructor (
    public id: number,
    public name: string,
    public codename: string,
    public description: string,
    public model: string,
    public module: string,
    public isClientPermission: boolean,
  ) {
    super();
  }

  public static create(data: PermissionStruct) {
    if (!data) {
      data = {} as PermissionStruct;
    }
    return new Permission(
      data.id || null,
      data.name || null,
      data.codename || null,
      data.description || null,
      data.model || null,
      data.module || null,
      !!data.is_client_permission,
    );
  }

  public override toString(): string {
    return `${this.module}.${this.codename}`;
  }

  public getSetName() {
    return (this.codename + '').split('_').slice(1).join('_');
  }

  public override clone() {
    return new Permission(
      this.id, this.name, this.codename, this.description,
      this.model, this.module, this.isClientPermission,
    );
  }

  public toJSON(): PermissionStruct {
    return {
      id: this.id,
      name: this.name,
      codename: this.codename,
      description: this.description,
      is_client_permission: this.isClientPermission,
      model: this.model,
      module: this.module,
    };
  }

  public update(data: PermissionStruct): this {
    this.id = isNumberOrNull(data.id) ? data.id : this.id;
    this.name = isStringOrNull(data.name) ? data.name : this.name;
    this.codename = isStringOrNull(data.codename) ? data.codename : this.codename;
    this.description = isStringOrNull(data.description) ? data.description : this.description;
    this.isClientPermission = isBooleanOrNull(data.is_client_permission) ? data.is_client_permission : this.isClientPermission;
    this.model = isStringOrNull(data.model) ? data.model : this.model;
    this.module = isStringOrNull(data.module) ? data.module : this.module;
    return this;
  }
}
