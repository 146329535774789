import { INVOICE_STATUSES } from '../../../accounting/models/invoice/statuses';
import { ORDER_STATUSES } from '../../../fuel/models/orders/order/meta';
import { Status, StatusStruct } from './status';
import { Collection } from '@evo/models';

const CREATED_TOOLTIP = 'Order received - please check your email for a Fuel Release';
const CANCELLED_TOOLTIP = 'You have cancelled this fuel order';
const EXPIRED_TOOLTIP = 'Either fuel release was not used or our local supplier has not yet sent uplift information to us';
const INVOICED_TOOLTIP = 'We have sent you an an invoice for this fuel uplift';
const CREDIT_TOOLTIP = 'Credit invoice';
const PAID_TOOLTIP = 'Invoice is paid';


export class StatusesCollection<T> extends Collection<Status<T>> {

  public static KEYS = { ADMIN: 1, PIPELINE: 0, CLIENT_ORDER: 2, CLIENT_ORDER_FILTERS: 3 };
  public static CACHE: { [key: string]: StatusesCollection<any>; } = {};

  public static create<T>(items: StatusStruct<T>[] = []) {
    return new StatusesCollection(items.map((s) => Status.create(s)));
  }

  public static lazy<T>(key: number, items: StatusStruct<T>[] = []) {
    let collection = StatusesCollection.CACHE[key];
    if (collection) {
      return collection;
    }
    collection = StatusesCollection.create<T>(items);
    StatusesCollection.CACHE[key] = collection;
    return collection;
  }

  public static invoiceStatuses() {
    let collection = StatusesCollection.CACHE['INVOICE_STATUSES'];
    if (!collection) {
      collection = new StatusesCollection<INVOICE_STATUSES>([
        Status.create(INVOICE_STATUSES.INVOICED, 'bg-info', 'Invoiced', INVOICED_TOOLTIP),
        Status.create(INVOICE_STATUSES.CREDIT, 'bg-secondary', 'Credit', CREDIT_TOOLTIP),
        Status.create(INVOICE_STATUSES.PAID, 'bg-primary', 'Paid', PAID_TOOLTIP),
        Status.create(INVOICE_STATUSES.VOID, 'bg-warning', 'Void', EXPIRED_TOOLTIP),
      ]);
      StatusesCollection.CACHE['INVOICE_STATUSES'] = collection;
    }
    return collection;
  }

  public search(term: string) {
    term = (term + '').trim().toLowerCase();
    if (!term) {
      return this.items;
    }
    return this.items.filter((s) => {
      return (s.name + '').toLowerCase().indexOf(term) > -1 ||
        (s.label + '').toLowerCase().indexOf(term) > -1;
    }).slice(0, 10);
  }
}
