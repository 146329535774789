import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { controlErrors } from '@evo/utils/html';
import { fromEvent, Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { Moment } from 'moment';

@Component({
  selector: 'app-date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.sass']
})
export class DateControlComponent implements OnInit, OnDestroy {

  @Input()
  public minDate: Moment;

  @Input()
  public maxDate: Moment;

  public isDisabled = false;
  public isInvalid = false;
  public isValid = false;

  @Input()
  public size: 'md' | 'xl' | 'sm' | 'lg' = 'md';

  @Input()
  public placeholder = '';

  public value: Moment;

  @ViewChild('datepicker', { static: true }) datepicker;

  public errors: string[] = [];

  private subscriptions: Subscription[] = [];

  onChange = (_: any) => {
  };

  onTouched = () => {
  };

  constructor (
    public ngbDateAdapter: NgbDateAdapter<Moment>,
    private eRef: ElementRef,
    private ngControl: NgControl,
  ) {
    ngControl.valueAccessor = this;
  }

  public ngOnInit() {
    // close the date picker on outside click
    this.value = this.ngControl.value;
    let s = fromEvent(document, 'click').subscribe((event: KeyboardEvent) => {
      if (!this.eRef.nativeElement.contains(event.target)) {
        this.datepicker.close();
      }
    });
    this.subscriptions.push(s);
    s = this.ngControl.statusChanges.subscribe((status) => {
      let data = controlErrors(this.ngControl);
      this.isInvalid = data[0];
      this.isValid = data[1];
      this.errors = data[2];
    });
    this.subscriptions.push(s);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public registerOnChange(fn: (value: any) => any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => any) {
    this.onTouched = fn;
  }

  public writeValue(value: Moment) {
    this.onChange(value);
    this.onTouched();
  }

  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

}
