import { Status, StatusesCollection } from '@common/models/status';

export enum ORDER_STATUSES {
  CREATED = 'CREATED', // 0 - Submitted
  CANCELLED = 'CANCELLED', // 1 - Cancelled by user
  EXPIRED = 'EXPIRED',  // 2 - Cancelled by FMA
  BILLED = 'BILLED', // 3 - bill is sent to QBO, but invoice is not attached to order yet

  CART = 'CART', // 4 - Added to batch
  CHANGED = 'CHANGED', // 5 - While order was in cart, price was changed
  ERRORED = 'ERRORED',  // 6 - While order was in cart, price was removed

  INVOICED = 'INVOICED',  // 7 - invoice is attached to order
  CREDIT = 'CREDIT', // 8 - order is credited (when client paid directly to vendor)
  VOID = 'VOID', // 9 - order is void (almost same as CREDIT)
  PAID = 'PAID', // 10 - order is paid
}

export const ORDER_STATUSES_INVOICED = [
  ORDER_STATUSES.BILLED, ORDER_STATUSES.INVOICED, ORDER_STATUSES.CREDIT, ORDER_STATUSES.VOID, ORDER_STATUSES.PAID
];

export enum ORDER_PIPELINE {
  PENDING = 'PENDING',    // 0 - Order is not added to pipeline or is excluded from there (CANCELLED, EXPIRED, VOID, CREDIT)
  PROCESSED = 'PROCESSED',     // 1 - Order went to filemaker process.
  SUBMITTED = 'SUBMITTED',     // 2 - Submitted with local vendor
  AUTHORIZED = 'AUTHORIZED',     // 3 - Fuel release granted
  FBO_CONTACTED = 'FBO_CONTACTED'     // 4 - FBO contacted
}

export const ORDER_PIPELINE_ENUM = [ORDER_PIPELINE.PENDING, ORDER_PIPELINE.PROCESSED, ORDER_PIPELINE.SUBMITTED, ORDER_PIPELINE.AUTHORIZED, ORDER_PIPELINE.FBO_CONTACTED];

export enum ORDER_REFUEL {
  DEPARTURE = 'DEPARTURE',
  ARRIVAL = 'ARRIVAL'
}

export const ORDER_REFUEL_CHOISES = [{
  name: 'Departure',
  value: ORDER_REFUEL.DEPARTURE
}, {
  name: 'Arrival',
  value: ORDER_REFUEL.ARRIVAL
}];

const CREATED_TOOLTIP = 'Order received - please check your email for a Fuel Release';
const CANCELLED_TOOLTIP = 'You have cancelled this fuel order';
const EXPIRED_TOOLTIP = 'Either fuel release was not used or our local supplier has not yet sent uplift information to us';
const INVOICED_TOOLTIP = 'We have sent you an an invoice for this fuel uplift';
const PAID_TOOLTIP = 'Invoice is paid';

export class Statuses {
  public static get Admin() {
    return StatusesCollection.lazy(StatusesCollection.KEYS.ADMIN, [
      Status.create(ORDER_STATUSES.CREATED, 'bg-success', 'Confirmed', 'Order received.'),
      Status.create(ORDER_STATUSES.CANCELLED, 'bg-danger', 'Cancelled', 'You have cancelled this fuel order'),
      Status.create(ORDER_STATUSES.EXPIRED, 'bg-warning', 'Expired', 'Either fuel release was not used or our local supplier has not yet sent uplift information to us'),
      Status.create(ORDER_STATUSES.BILLED, 'bg-info', 'Billed', 'Order went to quickbooks. No invoices yet.'),

      Status.create(ORDER_STATUSES.INVOICED, 'bg-info', 'Invoiced', 'Total amount > 0 and balance > 0'),
      Status.create(ORDER_STATUSES.CREDIT, 'bg-warning', 'Credit', 'Total amount = 0, but separate amounts are different from 0'),
      Status.create(ORDER_STATUSES.VOID, 'bg-warning', 'Void', 'Total amount = 0 and all amounts are 0'),
      Status.create(ORDER_STATUSES.PAID, 'bg-primary', 'Paid', 'Total amount > 0 and balance = 0'),
    ]);
  }

  public static get Client() {
    return StatusesCollection.lazy<ORDER_STATUSES>(StatusesCollection.KEYS.CLIENT_ORDER, [
      Status.create(ORDER_STATUSES.CREATED, 'bg-success', 'Confirmed', CREATED_TOOLTIP),
      Status.create(ORDER_STATUSES.CANCELLED, 'bg-danger', 'Cancelled', CANCELLED_TOOLTIP),
      Status.create(ORDER_STATUSES.EXPIRED, 'bg-warning', 'Expired', EXPIRED_TOOLTIP),
      Status.create(ORDER_STATUSES.BILLED, 'bg-info', 'Invoiced', INVOICED_TOOLTIP),

      Status.create(ORDER_STATUSES.CART, 'bg-dark', 'Pending', ''),
      Status.create(ORDER_STATUSES.CHANGED, 'bg-dark', 'Changed', ''),
      Status.create(ORDER_STATUSES.ERRORED, 'bg-dark', 'Error', ''),

      Status.create(ORDER_STATUSES.INVOICED, 'bg-info', 'Invoiced', INVOICED_TOOLTIP),
      Status.create(ORDER_STATUSES.CREDIT, 'bg-warning', 'Expired', EXPIRED_TOOLTIP),
      Status.create(ORDER_STATUSES.VOID, 'bg-warning', 'Expired', EXPIRED_TOOLTIP),
      Status.create(ORDER_STATUSES.PAID, 'bg-primary', 'Paid', PAID_TOOLTIP),
    ]);
  }

  public static get ClientFilters() {
    return StatusesCollection.lazy<ORDER_STATUSES>(StatusesCollection.KEYS.CLIENT_ORDER_FILTERS, [
      Status.create(ORDER_STATUSES.CREATED, 'bg-success', 'Confirmed', CREATED_TOOLTIP),
      Status.create(ORDER_STATUSES.CANCELLED, 'bg-danger', 'Cancelled', CANCELLED_TOOLTIP),
      Status.create(ORDER_STATUSES.EXPIRED, 'bg-warning', 'Expired', EXPIRED_TOOLTIP),
      Status.create(ORDER_STATUSES.INVOICED, 'bg-info', 'Invoiced', INVOICED_TOOLTIP),
      Status.create(ORDER_STATUSES.PAID, 'bg-primary', 'Paid', PAID_TOOLTIP)
    ]);
  }

  public static get Pipeline() {
    return StatusesCollection.lazy(StatusesCollection.KEYS.PIPELINE, [
      Status.create(ORDER_PIPELINE.PENDING, 'bg-success', 'Pending', 'Order is not added to fuel pipeline.'),
      Status.create(ORDER_PIPELINE.PROCESSED, 'bg-warning', 'Processed', 'Order went to filemaker process.'),
      Status.create(ORDER_PIPELINE.SUBMITTED, 'bg-secondary', 'Submitted', 'Submitted with local vendor.'),
      Status.create(ORDER_PIPELINE.AUTHORIZED, 'bg-info', 'Authorized', 'Fuel release granted.'),
      Status.create(ORDER_PIPELINE.FBO_CONTACTED, 'bg-primary', 'FBO contacted', 'FBO contacted.'),
    ]);
  }
}
