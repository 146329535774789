import { isString } from '@evo/utils/types';
import { Model } from '@evo/models';

export interface StatusStruct<T> {
  name: T;
  css: string;
  label: string;
  tooltip: string;
}

export class Status<T> extends Model<StatusStruct<T>> {

  constructor (
    public name: T,
    public css: string,
    public label: string,
    public tooltip: string
  ) {
    super();
  }


  public static create<T>(data: StatusStruct<T> | T, css?: string, label?: string, tooltip?: string): Status<T> {
    if (isString(data)) {
      return new Status(data as T, css, label, tooltip);
    } else if (!data) {
      data = {} as StatusStruct<T>;
    } else {
      data = data as StatusStruct<T>;
    }
    return new Status(
      data.name || null,
      data.css || null,
      data.label || null,
      data.tooltip || null
    );
  }

  public override get pk() {
    return this.name;
  }

  public override toString(): string {
    return (this.name).toString();
  }

  public toJSON() {
    return {
      name: this.name,
      css: this.css,
      label: this.label,
      tooltip: this.tooltip,
    };
  }

  public update(data: StatusStruct<T>): this {
    return this;
  }
}
