import { isNumber, isString, isStringOrNull, isNumberOrNull } from '@evo/utils/types';
import { Model } from '@evo/models';
import { COMPANY_TYPES } from '../company';

export interface CompanyTypeStruct {
  uuid: string;
  name: string;
  code: COMPANY_TYPES;
  description: string;
  order_number: number;
}

export class CompanyType extends Model<CompanyTypeStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public code: COMPANY_TYPES,
    public description: string,
    public orderNumber: number
  ) {
    super();
  }

  public static create(data?: CompanyTypeStruct) {
    if (!data) {
      data = {} as any;
    }
    return new CompanyType(
      isString(data.uuid) ? data.uuid : null,
      isString(data.name) ? data.name : null,
      isString(data.code) ? data.code : null,
      isString(data.description) ? data.description : null,
      isNumber(data.order_number) ? data.order_number : null
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): CompanyTypeStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      code: this.code,
      description: this.description,
      order_number: this.orderNumber
    };
  }

  public update(data: CompanyTypeStruct): this {
    this.uuid = isStringOrNull(data.uuid) ? data.uuid : this.uuid;
    this.name = isStringOrNull(data.name) ? data.name : this.name;
    this.code = isStringOrNull(data.code) ? data.code : this.code;
    this.description = isStringOrNull(data.description) ? data.description : this.description;
    this.orderNumber = isNumberOrNull(data.order_number) ? data.order_number : this.orderNumber;
    return this;
  }

}
