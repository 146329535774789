import {Route} from '@angular/router';


export const hrRootRoute: Route = {
  path: 'hr',
  data: {
    breadcrumb: 'Human Resources'
  },
  loadChildren: () => import('../../../../hr/hr.module').then(m => m.HrModule)
};
