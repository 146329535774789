import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {empty} from '../../utils';

export function equals(sourceControlName: string, controlName: string, message: string) {
  return (g: FormGroup): ValidationErrors | null => {
    let sourceControl = g.get(sourceControlName) as AbstractControl,
      control = g.get(controlName) as AbstractControl;

    if (!empty(sourceControl.value) && sourceControl.value != control.value) {
      control.setErrors({equals: message});
    } else {
      let errors = control.errors;
      if (errors) {
        delete errors['equal'];
        if (Object.keys(errors).length == 0) {
          errors = null;
        }
      } else {
        errors = null;
      }
      control.setErrors(errors);
    }
    return null;
  };
}
