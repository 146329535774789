import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { FactoryProvider, Injectable } from '@angular/core';
import { EventsService, JwtService } from '@main/services';
import { Company, Division, User } from '@auth/models';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  constructor (
    private jwt: JwtService,
    private router: Router,
    private user: User,
    private division: Division,
    private company: Company,
    private events: EventsService
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let token = this.jwt.getToken(),
      request: HttpRequest<any> = req;

    if (token && req.method != 'OPTIONS') {
      request = req.clone({ headers: req.headers.set('Authorization', 'JWT ' + token) });
    }

    return next.handle(request).pipe(
      catchError((e) => {
        if (e instanceof HttpErrorResponse) {
          switch (e.status) {
            case 401:
              let m = e.error && e.error.detail ? e.error.detail : null;
              if (m == 'Signature has expired.') {
                this.logout();
                this.router.navigateByUrl('/access/sign-in');
                return of(null);
              }
              break;
          }
        }
        return throwError(() => e);
      }),
    );
  }

  private logout() {
    this.jwt.destroyToken();
    this.user.clear();
    this.division.clear();
    this.company.clear();
    this.events.logout.emit();
  }
}

export let AUTH_INTERCEPTOR: AuthInterceptor;

export function AuthInterceptorFactory(jwt, router, user, division, company, events) {
  if (!AUTH_INTERCEPTOR) {
    AUTH_INTERCEPTOR = new AuthInterceptor(jwt, router, user, division, company, events);
  }
  return AUTH_INTERCEPTOR;
}

export const HttpInterceptorsProvider: FactoryProvider = {
  provide: HTTP_INTERCEPTORS,
  useFactory: AuthInterceptorFactory,
  multi: true,
  deps: [JwtService, Router, User, Division, Company, EventsService]
};
