import { Model } from '@evo/models';

export interface SalutationStruct {
  uuid: string;
  name: string;
}

export class Salutation extends Model<SalutationStruct> {
  constructor (
    public uuid: string,
    public name: string,
  ) {
    super();
  }

  public static create(data: SalutationStruct) {
    return new Salutation(
      data.uuid || null,
      data.name || null,
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      name: this.name,
    };
  }

  public update(data: SalutationStruct) {
    this.uuid = data.uuid || this.uuid;
    this.name = data.name || this.name;
    return this;
  }
}
