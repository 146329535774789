export enum GROUP_PERMISSIONS {
  CREATE = 'authentication.add_group',
  UPDATE = 'authentication.change_group',
  DELETE = 'authentication.delete_group',
  VIEW = 'authentication.view_group'
}

export enum PERMISSION_PERMISSIONS {
  CREATE = 'authentication.add_permission',
  UPDATE = 'authentication.change_permission',
  DELETE = 'authentication.delete_permission',
  VIEW = 'authentication.view_permission'
}

export enum USER_PERMISSIONS {
  CREATE = 'authentication.add_user',
  UPDATE = 'authentication.change_user',
  DELETE = 'authentication.delete_user',
  VIEW = 'authentication.view_user'
}

export enum EMAIL_PERMISSIONS {
  CREATE = 'authentication.add_email',
  UPDATE = 'authentication.change_email',
  DELETE = 'authentication.delete_email',
  VIEW = 'authentication.view_email'
}

export enum COMPANY_PERMISSIONS {
  CREATE = 'authentication.add_company',
  UPDATE = 'authentication.change_company',
  DELETE = 'authentication.delete_company',
  VIEW = 'authentication.view_company'
}

export enum DIVISION_PERMISSIONS {
  CREATE = 'authentication.add_division',
  UPDATE = 'authentication.change_division',
  DELETE = 'authentication.delete_division',
  VIEW = 'authentication.view_division'
}

export enum COMPANY_TYPE_PERMISSIONS {
  CREATE = 'authentication.add_companytype',
  UPDATE = 'authentication.change_companytype',
  DELETE = 'authentication.delete_companytype',
  VIEW = 'authentication.view_companytype'
}

export enum EVOCREDENTIALS_PERMISSIONS {
  CREATE = 'authentication.add_evocredentials',
  UPDATE = 'authentication.change_evocredentials',
  DELETE = 'authentication.delete_evocredentials',
  VIEW = 'authentication.view_evocredentials'
}

