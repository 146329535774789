import { Collection } from '@evo/models';
import { Group, GroupStruct } from './group';

export class GroupsCollection extends Collection<Group> {

  public static create(data: GroupStruct[] = []) {
    return new GroupsCollection(data.map((g) => Group.create(g)));
  }

  public search(term: string) {
    term = (term + '').toLowerCase();
    if (!term) {
      return this.items.slice(0, 10);
    }
    let result: Group[] = [];
    for (let i = 0; i < this.items.length; i++) {
      let g = this.items[i];
      if ((g.name).toLowerCase().startsWith(term) || (g.label).toLowerCase().startsWith(term)) {
        result.push(g);
      }
      if (result.length == 10) {
        return result;
      }
    }
    return result;
  }
}
