import {Route} from '@angular/router';


export const fuelRootRoute: Route = {
  path: 'fuel',
  data: {
    breadcrumb: 'Fuel'
  },
  loadChildren: () => import('../../../../fuel/fuel.module').then(m => m.FuelModule)
};
