import { isNull, isNumber, isNumberOrNull } from '@evo/utils/types';
import { Model } from '@evo/models';

export type PointStruct = [number, number] | [number, number, number];

export class Point extends Model<PointStruct> {
  constructor (
    public x: number,
    public y: number,
    public z: number
  ) {
    super();
  }

  public static create(data: PointStruct) {
    if (!data) {
      data = {} as PointStruct;
    }
    return new Point(
      isNumber(data[0]) ? data[0] : 0,
      isNumber(data[1]) ? data[1] : 0,
      isNumber(data[2]) ? data[2] : null,
    );
  }

  public toJSON(): PointStruct {
    return isNull(this.z) ? [this.x, this.y] : [this.x, this.y, this.z];
  }

  public update(data: PointStruct) {
    this.x = isNumberOrNull(data[0]) ? data[0] : this.x;
    this.y = isNumberOrNull(data[1]) ? data[1] : this.y;
    this.z = isNumberOrNull(data[2]) ? data[2] : this.z;
    return this;
  }
}
