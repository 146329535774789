import { isArray, isArrayOrNull, isNull, isNumber, isNumberOrNull, isObject, isString, isStringOrNull } from '@evo/utils/types';
import { Model, Relation } from '@evo/models';
import { Count, CountStruct } from '@common/models';
import { Timezone, TimezoneStruct } from '../timezone';
import { Country, CountryStruct } from '../country';
import { StateStruct, State } from '../state';
import { Point, PointStruct } from '../point';

export type LocationStruct = {
  uuid: string;
  icao: string;
  iata: string;
  faa: string;
  extra: string;
  name: string;
  city: string;
  latitude: number;
  longitude: number;
  altitude: number;
  coordinates: PointStruct;
  count_searches: number;
  count_offers: number;
  dispatcher_notes: string;
  type: string;
  state: Relation<StateStruct>;
  country: Relation<CountryStruct>;
  timezone: Relation<TimezoneStruct>;
  distance: CountStruct;
};

export class Location extends Model<LocationStruct> {

  private _parts: string[];

  constructor (
    public uuid: string,
    public icao: string,
    public iata: string,
    public faa: string,
    public extra: string,
    public name: string,
    public city: string,
    public latitude: number,
    public longitude: number,
    public altitude: number,
    public coordinates: Point,
    public dispatcherNotes: string,
    public type: string,
    public state?: State,
    public country?: Country,
    public timezone?: Timezone,
    public distance?: Count,
    public countSearches?: number,
    public countOffers?: number
  ) {
    super();
  }

  public static create(data?: LocationStruct) {
    if (!data) {
      data = {} as LocationStruct;
    }
    return new Location(
      isString(data.uuid) ? data.uuid : null,
      isString(data.icao) ? data.icao : null,
      isString(data.iata) ? data.iata : null,
      isString(data.faa) ? data.faa : null,
      isString(data.extra) ? data.extra : null,
      isString(data.name) ? data.name : null,
      isString(data.city) ? data.city : null,
      isNumber(data.latitude) ? data.latitude : null,
      isNumber(data.longitude) ? data.longitude : null,
      isNumber(data.altitude) ? data.altitude : null,
      isArray(data.coordinates) ? Point.create(data.coordinates) : null,
      isString(data.dispatcher_notes) ? data.dispatcher_notes : null,
      isString(data.type) ? data.type : null,
      isObject(data.state) ? State.create(data.state as StateStruct) : null,
      isObject(data.country) ? Country.create(data.country as CountryStruct) : null,
      isObject(data.timezone) ? Timezone.create(data.timezone as TimezoneStruct) : null,
      isObject(data.distance) ? Count.create(data.distance) : null,
      isNumber(data.count_searches) ? data.count_searches : null,
      isNumber(data.count_offers) ? data.count_offers : null,
    );
  }

  public get code() {
    if (this.icao) return this.icao;
    if (this.faa) return this.faa;
    if (this.iata) return this.iata;
    return this.extra;
  }

  public get place() {
    if (!this._parts) {
      let parts = [];
      if (this.city) {
        parts.push(this.city);
      }
      if (this.state) {
        parts.push(this.state.name);
      }
      if (this.country) {
        parts.push(this.country.iso);
      }
      this._parts = parts;
    }
    return this._parts.join(', ');
  }

  public get codes() {
    let codes = [];
    if (this.icao) {
      codes.push(this.icao);
    }
    if (this.iata) {
      codes.push(this.iata);
    }
    if (codes.length < 2 && this.faa) {
      codes.push(this.faa);
    }
    if (codes.length < 2 && this.extra) {
      codes.push(this.faa);
    }
    return codes.join(' / ');
  }

  public get shortName() {
    return `${this.code} - ${this.name}, ${this.country.iso}`;
  }

  public get middleName() {
    return `${this.codes} - ${this.name}, ${this.country.iso}`;
  }

  public get longName() {
    let state = this.state ? this.state.name : this.city;
    return `${this.codes} - ${this.name}, ${state}, ${this.country.iso}`;
  }

  public override toString() {
    return this.longName;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      iata: this.iata,
      icao: this.icao,
      faa: this.faa,
      extra: this.extra,
      name: this.name,
      city: this.city,
      latitude: this.latitude,
      longitude: this.longitude,
      altitude: this.altitude,
      coordinates: this.coordinates ? this.coordinates.toJSON() : null,
      dispatcher_notes: this.dispatcherNotes,
      count_searches: this.countSearches,
      count_offers: this.countOffers,
      type: this.type,
      state: this.state ? this.state.pk : null,
      country: this.country ? this.country.pk : null,
      timezone: this.timezone ? this.timezone.pk : null,
      distance: this.distance ? this.distance.toJSON() : null
    };
  }

  public update(data: Partial<LocationStruct>) {
    this.uuid = isStringOrNull(data.uuid) ? data.uuid : this.uuid;
    this.name = isStringOrNull(data.name) ? data.name : this.name;
    this.city = isStringOrNull(data.city) ? data.city : this.city;

    this.icao = isStringOrNull(data.icao) ? data.icao : this.icao;
    this.iata = isStringOrNull(data.iata) ? data.iata : this.iata;
    this.faa = isStringOrNull(data.faa) ? data.faa : this.faa;
    this.extra = isStringOrNull(data.extra) ? data.extra : this.extra;

    this.latitude = isNumberOrNull(data.latitude) ? data.latitude : this.latitude;
    this.longitude = isNumberOrNull(data.longitude) ? data.longitude : this.longitude;
    this.altitude = isNumberOrNull(data.altitude) ? data.altitude : this.altitude;
    this.coordinates = isArrayOrNull(data.coordinates) ? Point.create(data.coordinates) : this.coordinates;

    this.dispatcherNotes = isStringOrNull(data.dispatcher_notes) ? data.dispatcher_notes : this.dispatcherNotes;
    this.type = isStringOrNull(data.dispatcher_notes) ? data.dispatcher_notes : this.type;
    this.countSearches = isNumberOrNull(data.count_searches) ? data.count_searches : this.countSearches;
    this.countOffers = isNumberOrNull(data.count_offers) ? data.count_offers : this.countOffers;

    this.distance = isObject(data.distance) ? Count.create(data.distance) : isNull(data.distance) ? null : this.distance;
    this.state = isObject(data.state) ? State.create(data.state as StateStruct) : isNull(data.state) ? null : this.state;
    this.country = isObject(data.country) ? Country.create(data.country as CountryStruct) : isNull(data.country) ? null : this.country;
    this.timezone = isObject(data.timezone) ? Timezone.create(data.timezone as TimezoneStruct) : isNull(data.timezone) ? null : this.timezone;
    return this;
  }
}
