import { Pagination, PaginationStruct } from '@common/models';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { Order, OrderStruct } from '@fuel/models';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class FuelOrdersService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(params: URLSearchParams, pagination?: Pagination<OrderStruct, Order>) {

    return this.http.get(`${environment.URL.API}/fuel/orders/?${params.toString()}`).pipe(
      map((data: PaginationStruct<OrderStruct>) => {
        return pagination ? pagination.update(data) : Pagination.create<OrderStruct, Order>(data, Order);
      }),
      catchError((e) => {
        return of(Pagination.empty<OrderStruct, Order>(Order));
      })
    );
  }

  public get(order: Order | string) {
    let uuid = toPrimaryKey(order);
    return this.http.get(`${environment.URL.API}/fuel/orders/${uuid}/`).pipe(
      map((data: OrderStruct) => {
        return Order.create(data);
      }),
    );
  }

  public create(data: any) {
    return this.http.post(`${environment.URL.API}/fuel/orders/`, data).pipe(
      map((resp: OrderStruct) => {
        return Order.create(resp);
      }),
    );
  }

  public cancel(order: Order) {
    return this.http.put(`${environment.URL.API}/fuel/orders/${order.pk}/cancel/`, {}).pipe(
      map((resp: OrderStruct) => {
        return order.update(resp);
      }),
    );
  }
}
