import { isNumber, isNumberOrNull, isObject, isString, isStringOrNull } from '@evo/utils/types';
import { Person, PersonStruct } from '@auth/models';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface LogEntryStruct {
  id: number;
  action_time: string;
  action: string;
  user: PersonStruct | string;
  object_id: string;
  object_repr: string;
  change_message: string;
}

export class LogEntry extends Model<LogEntryStruct> {
  constructor (
    public id: number,
    public objectId: string,
    public objectRepr: string,
    public changeMessage: string,
    public action: string,
    public actionTime: Moment,
    public user: Person,
  ) {
    super();
  }

  public static create(data: LogEntryStruct) {
    return new LogEntry(
      isNumber(data.id) ? data.id : null,
      isString(data.object_id) ? data.object_id : null,
      isString(data.object_repr) ? data.object_repr : null,
      isString(data.change_message) ? data.change_message : null,
      isString(data.action) ? data.action : null,
      isString(data.action_time) ? moment(data.action_time) : null,
      isObject(data.user) ? Person.create(data.user as PersonStruct) : null
    );
  }

  public override toString(): string {
    return `${this.changeMessage} - ${this.actionTime.toISOString()}`;
  }

  public toJSON() {
    return {
      id: this.id,
      object_id: this.objectId,
      object_repr: this.objectRepr,
      change_message: this.changeMessage,
      action: this.action,
      action_time: this.actionTime ? this.actionTime.toISOString() : null,
      user: this.user ? this.user.pk : null
    };
  }

  public update(data: Partial<LogEntryStruct>) {
    this.id = isNumberOrNull(data.id) ? data.id : this.id;
    this.objectId = isStringOrNull(data.object_id) ? data.object_id : this.objectId;
    this.objectRepr = isStringOrNull(data.object_repr) ? data.object_repr : this.objectRepr;
    isString(data.change_message) ? data.change_message : null,
      isString(data.action) ? data.action : null,
      isString(data.action_time) ? moment(data.action_time) : null,
      isObject(data.user) ? Person.create(data.user as PersonStruct) : null
    return this;
  }
}
