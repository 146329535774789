export { DatePipe, DATE_FORMATS } from './date';
export { LineBreaksPipe } from './line-breaks';
export { DurationPipe } from './duration';
export { NumericPipe } from './numeric';
export { ArrayPipe } from './array';

import { DatePipe } from './date';
import { LineBreaksPipe } from './line-breaks';
import { DurationPipe } from './duration';
import { NumericPipe } from './numeric';
import { ArrayPipe } from './array';

export const PIPES = [
  DatePipe,
  LineBreaksPipe,
  DurationPipe,
  NumericPipe,
  ArrayPipe
];
