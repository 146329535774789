import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';
import { isBoolean, isString } from '@evo/utils/types';


export interface DateRangeStruct {
  name: string;
  date_from: string;
  date_to: string;
  is_default: boolean;
  is_custom: boolean;
}

export class DateRange extends Model<DateRangeStruct> {

  constructor (
    public name: string,
    public dateFrom: Moment,
    public dateTo: Moment,
    public isDefault: boolean,
    public isCustom = false
  ) {
    super();
  }

  public static create(data: DateRangeStruct) {
    return new DateRange(
      data.name || null,
      data.date_from ? moment.utc(data.date_from) : null,
      data.date_to ? moment.utc(data.date_to) : null,
      isBoolean(data.is_default) ? data.is_default : false,
      isBoolean(data.is_custom) ? data.is_custom : false
    );
  }

  public toJSON(): DateRangeStruct {
    return {
      name: this.name,
      date_to: this.dateTo ? this.dateTo.toISOString() : null,
      date_from: this.dateFrom ? this.dateFrom.toISOString() : null,
      is_default: this.isDefault,
      is_custom: this.isCustom
    };
  }

  public update(data: DateRangeStruct): this {
    this.name = isString(data.name) ? data.name : this.name;
    this.dateFrom = data.date_from ? moment.utc(data.date_from) : this.dateFrom;
    this.dateTo = data.date_to ? moment.utc(data.date_to) : this.dateTo;
    this.isDefault = isBoolean(data.is_default) ? data.is_default : this.isDefault;
    this.isCustom = isBoolean(data.is_custom) ? data.is_custom : this.isCustom;
    return this;
  }

  public override equals(range: DateRange) {
    return this.dateFrom.isSame(range.dateFrom) && this.dateTo.isSame(range.dateTo);
  }

  public isOneDayRange() {
    return this.dateFrom.isSame(this.dateTo);
  }
}
