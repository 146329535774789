import { Search, SearchStruct, FlightType, DestinationType, SearchBatchStruct } from '@fuel/models';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@main/services';
import { Location } from '@geodata/models';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class CurrentFuelSearchesService {

  public searches: Search[] = [];
  public selected: Search;

  constructor (
    private http: HttpClient,
    private events: EventsService
  ) {
  }

  public isSelected(search: Search) {
    return search && search.equals(this.selected);
  }

  public fetch() {
    return this.http.get(`${environment.URL.API}/fuel/searches/`).pipe(
      map((resp: SearchStruct[]) => {
        return resp.map((item) => Search.create(item));
      }),
      tap((searches: Search[]) => {
        this.searches = searches;
        this.selected = this.searches.find((s) => {
          return s.isSelected;
        });
        if (!this.selected && searches.length) {
          this.select(this.searches[0]).subscribe(() => {
          }, (e) => {
          });
        }
      })
    );
  }

  public create(location: Location | string) {
    let uuid = toPrimaryKey(location);
    return this.http.post(`${environment.URL.API}/fuel/searches/`, { location_id: uuid }).pipe(
      map((resp: SearchStruct) => {
        return Search.create(resp);
      }),
      tap((search) => {
        this.searches.push(search);
      })
    );
  }

  public select(search: Search) {
    this.selected = search;
    this.searches.forEach((s) => s.isSelected = s.equals(search));
    return this.http.put(`${environment.URL.API}/fuel/searches/${search.uuid}/select/`, search.toJSON()).pipe(
      map((resp: SearchStruct) => {
        return search.update(resp);
      }),
      tap((search: Search) => {
        this.events.fuel.searchSelection.emit(search);
      })
    );
  }

  public selectFirst() {
    return this.select(this.searches[0]);
  }

  public remove(search: Search) {
    let uuid = toPrimaryKey(search);
    return this.http.delete(`${environment.URL.API}/fuel/searches/${uuid}/`).pipe(
      tap((resp: SearchBatchStruct) => {
        let index = this.searches.findIndex((s) => s.equals(search as Search));
        this.searches.splice(index, 1);
        this.events.fuel.searchRemoval.next(search);
        resp && this.events.fuel.currentBatchData.next(resp);
      })
    );
  }

  public setFlightType(search: Search, type: FlightType) {
    let data = { flight_type_id: toPrimaryKey(type) };
    return this.http.put(`${environment.URL.API}/fuel/searches/${search.uuid}/flight-type/`, data).pipe(
      map((resp: SearchStruct) => {
        return search.update(resp);
      }),
      tap((s: Search) => {
        this.events.fuel.searchFlightTypeChange.emit(s);
      })
    );
  }

  public setDestinationType(search: Search, type: DestinationType) {
    let data = { destination_type_id: toPrimaryKey(type) };
    return this.http.put(`${environment.URL.API}/fuel/searches/${search.uuid}/destination-type/`, data).pipe(
      map((resp: SearchStruct) => {
        return search.update(resp);
      }),
      tap((s: Search) => {
        this.events.fuel.searchDestinationTypeChange.emit(s);
      })
    );
  }

}
