import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ControlValueAccessor, NgControl, NgModel } from '@angular/forms';
import { isString, isUndefinedOrNull } from '@evo/utils/types';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subscription } from 'rxjs';
import { controlErrors } from '@evo/utils/html';
import { Model } from '@evo/models';
import { Options } from '@popperjs/core';


@Component({
  selector: 'app-autocomplete-control',
  templateUrl: './autocomplete.control.component.html',
  styleUrls: ['./autocomplete.control.component.scss'],
})
export class AutocompleteControlComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {

  @ViewChild('inputRef', { static: true })
  public inputRef: ElementRef;

  @ViewChild('typehead', { static: true })
  public typehead: NgbTypeahead;

  @Input()
  public name = '';

  @Input()
  public showRemoveButton = true;

  @Input()
  public size: undefined | 'lg' | 'sm' | 'xl';

  @Input()
  public callback: (term: string) => any;

  @Input()
  public placeholder = '';

  public value: Model<any> | string | null;

  public isDisabled = false;
  public isInvalid = false;
  public isValid = false;

  public isStandAlone = false;

  public errors: string[] = [];
  protected subscriptions: Subscription[] = [];

  @Input()
  public inputFormatter = (item: any) => {
    if (item instanceof Model) {
      return item.toString();
    }
    return item;
  };

  @Input()
  public resultFormatter = (item: any) => {
    if (item instanceof Model) {
      return item.toString();
    }
    return item;
  };

  protected _onTouched = () => {
  };
  protected _onChange = (_: any) => {
  };

  constructor (
    public ngControl: NgControl,
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected cdr: ChangeDetectorRef
  ) {
    ngControl.valueAccessor = this;
    this.isStandAlone = this.ngControl instanceof NgModel;
  }

  public ngOnInit(): void {
    let s: Subscription;
    if (!this.isStandAlone) {
      s = this.ngControl.statusChanges.subscribe((status) => {
        let data = controlErrors(this.ngControl);
        this.isInvalid = data[0];
        this.isValid = data[1];
        this.errors = data[2];
      });
      this.subscriptions.push(s);
    }
  }

  public ngAfterViewInit() {
    let $input = this.inputRef.nativeElement,
      $el = this.elementRef.nativeElement,
      id = $el.getAttribute('id');
    if (id) {
      this.renderer.removeAttribute($el, 'id');
      this.renderer.setAttribute($input, 'id', id);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /***************************************** Control Value Accessor ************************************/
  public registerOnChange(fn: (value: any) => any) {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => any) {
    this._onTouched = fn;
  }

  public writeValue(value: any) {
    this.value = value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /***************************************** NGB Typehead ************************************/
  public popperOptions(options: Partial<Options>) {
    /*options.modifiers || (options.modifiers = []);
    options.modifiers[2].options = {padding: 0};*/
    return options;
  };

  public onInputFocus(e: Event): void {
    if (!this.value) {
      e.stopPropagation();
      setTimeout(() => {
        const inputEvent: Event = new Event('input');
        e.target.dispatchEvent(inputEvent);
      }, 0);
    }
  }

  public onInputBlur(e: Event): void {
    if (isString(this.value) && this.value) {
      if (this.ngControl.value) {
        this.value = this.ngControl.value;
      } else {
        delete this.value;
        this._onChange(null);
      }
    }
  }

  public search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => {
        let results = this.callback(term);
        return results instanceof Observable ? results : of(results);
      })
    );
  };

  public typeHeadChange(value: Model<any> | string) {
    value = (isUndefinedOrNull(value)) ? '' : value;
    let ngValue = isUndefinedOrNull(this.ngControl.value) ? '' : this.ngControl.value;
    if (value instanceof Model && !(value == ngValue)) {
      this._onChange(value);
    } else if (!value && value != ngValue) {
      this._onChange(null);
    }
  }

  public clear() {
    delete this.value;
    if (!!this.ngControl.value) {
      this._onChange(null);
    }
    //this.inputRef.nativeElement.focus();
  }
}
