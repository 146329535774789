import { isString } from '@evo/utils/types';
import { Param } from './param';

export enum PARAMS_ADMIN_TOP_STATS {
  PLATFORM = 'platform',
  DIVISION = 'division',
  AIRCRAFT = 'aircraft',
  IP_AGENT = 'ip_agent',
  VENDOR = 'vendor',
  LOCATION = 'location',
  SALES = 'sales_agent',
}

export const PARAMS_ADMIN_REPORT_TOP_STATS: Param<PARAMS_ADMIN_TOP_STATS>[] = [
  Param.create({ value: PARAMS_ADMIN_TOP_STATS.PLATFORM, name: 'Platforms', label: 'Platform' }),
  Param.create({ value: PARAMS_ADMIN_TOP_STATS.DIVISION, name: 'Divisions', label: 'Division' }),
  Param.create({ value: PARAMS_ADMIN_TOP_STATS.AIRCRAFT, name: 'Aircraft', label: 'Registration' }),
  Param.create({ value: PARAMS_ADMIN_TOP_STATS.IP_AGENT, name: 'FBO/IPA', label: 'FBO/IPA' }),
  Param.create({ value: PARAMS_ADMIN_TOP_STATS.VENDOR, name: 'Vendors', label: 'Vendor' }),
  Param.create({ value: PARAMS_ADMIN_TOP_STATS.LOCATION, name: 'Locations', label: 'Location' }),
  Param.create({ value: PARAMS_ADMIN_TOP_STATS.SALES, name: 'Sales Team', label: 'User' }),
];

export const PARAMS_SALES_REPORT_TOP_STATS = PARAMS_ADMIN_REPORT_TOP_STATS.slice(0, -1);

export function isDivisionParam(param: Param<PARAMS_ADMIN_TOP_STATS> | string) {
  return isString(param) ? param == PARAMS_ADMIN_TOP_STATS.DIVISION : param.value == PARAMS_ADMIN_TOP_STATS.DIVISION;
}
