import { Report, ReportStruct, AdminStats, AdminStatsStruct, IPAgent, IPAgentStruct, VendorStruct, Vendor } from '@fuel/models';
import { DivisionShortcut, DivisionShortcutStruct, Platform, PlatformStruct } from '@auth/models';
import { Pagination, PaginationStruct } from '@common/models';
import { ReportItem, ReportItemStruct } from '@fuel/models';
import { Aircraft, AircraftStruct } from '@aviation/models';
import { Location, LocationStruct } from '@geodata/models';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SalesAnalyticsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(params: URLSearchParams, pagination?: Pagination<ReportStruct, Report>) {
    return this.http.get<PaginationStruct<ReportStruct>>(`${environment.URL.API}/fuel/admin/sales/reports/?${params.toString()}`).pipe(
      map((data: PaginationStruct<ReportStruct>) => {
        return pagination ? pagination.update(data) : Pagination.create<ReportStruct, Report>(data, Report);
      }),
      catchError((e) => {
        return of(Pagination.empty<ReportStruct, Report>(Report));
      })
    );
  }

  public stats(params: URLSearchParams): Observable<AdminStats> {
    return this.http.get<AdminStatsStruct>(`${environment.URL.API}/fuel/admin/sales/stats/?${params.toString()}`).pipe(
      map((resp: AdminStatsStruct) => {
        return AdminStats.create(resp);
      })
    );
  }

  public topStats(params: URLSearchParams, pagination?: Pagination<ReportItemStruct, ReportItem<any>>) {
    return this.http.get<PaginationStruct<ReportItemStruct>>(`${environment.URL.API}/fuel/admin/sales/top-stats/?${params.toString()}`).pipe(
      map((resp: PaginationStruct<ReportItemStruct>) => {
        return pagination ? pagination.update(resp) :
          Pagination.create<ReportItemStruct, ReportItem<any>>(resp, ReportItem);
      })
    );
  }

  public searchDivision(params: URLSearchParams) {
    return this.http.get<DivisionShortcutStruct[]>(`${environment.URL.API}/fuel/admin/sales/search/divisions/?${params.toString()}`).pipe(
      map((resp: DivisionShortcutStruct[]) => {
        return resp.map((d) => DivisionShortcut.create(d));
      })
    );
  }

  public searchAircraft(params: URLSearchParams) {
    return this.http.get<AircraftStruct[]>(`${environment.URL.API}/fuel/admin/sales/search/aircraft/?${params.toString()}`).pipe(
      map((resp: AircraftStruct[]) => {
        return resp.map((a) => Aircraft.create(a));
      })
    );
  }

  public searchIPA(params: URLSearchParams) {
    return this.http.get<IPAgentStruct[]>(`${environment.URL.API}/fuel/admin/sales/search/fbo-ipa/?${params.toString()}`).pipe(
      map((resp: IPAgentStruct[]) => {
        return resp.map((i) => IPAgent.create(i));
      }),
    );
  }

  public searchLocation(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/sales/search/locations/?${params.toString()}`).pipe(
      map((resp: LocationStruct[]) => {
        return resp.map((l) => Location.create(l));
      })
    );
  }

  public searchVendor(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/sales/search/vendors/?${params.toString()}`).pipe(
      map((resp: VendorStruct[]) => {
        return resp.map((a) => Vendor.create(a));
      }),
    );
  }

  public searchPlatform(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/sales/search/platforms/?${params.toString()}`).pipe(
      map((resp: PlatformStruct[]) => {
        return resp.map((p) => Platform.create(p));
      })
    );
  }
}
