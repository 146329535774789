import { Model } from '@evo/models';

export interface StateStruct {
  uuid: string;
  name: string;
  iso: string;
  hasc: string;
  fips: string;
  number: string;
}

export class State extends Model<StateStruct> {
  constructor (
    public uuid: string,
    public name: string,
    public iso: string,
    public hasc: string,
    public fips: string,
    public number: string,
  ) {
    super();
  }

  public static create(data: StateStruct) {
    if (!data) {
      data = {} as StateStruct;
    }
    return new State(
      data.uuid || null,
      data.name || null,
      data.iso || null,
      data.hasc || null,
      data.fips || null,
      data.number || null,
    );
  }

  public override toString(): string {
    return this.name;
  }

  public getLabel() {
    return this.name;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      iso: this.iso,
      hasc: this.hasc,
      fips: this.fips,
      name: this.name,
      number: this.number,
    };
  }

  public update(data: StateStruct) {
    this.uuid = data.uuid || this.uuid;
    this.iso = data.iso || this.iso;
    this.hasc = data.hasc || this.hasc;
    this.fips = data.fips || this.fips;
    this.name = data.name || this.name;
    this.number = data.number || this.number;
    return this;
  }
}
