import { Units, UnitsStruct } from './units';
import { Collection } from '@evo/models';

export class UnitsCollection extends Collection<Units> {

  public static create(data: UnitsStruct[] = []) {
    return new UnitsCollection(data.map((u) => Units.create(u)));
  }

  public getDefault() {
    let item = this.items.find((i) => {
      return i.isDefault;
    });
    return item ? item : this.items[0];
  }

  public getByCode(code: string) {
    return this.items.find((u) => {
      return (u.code + '').toLowerCase() == (code + '').toLowerCase();
    })
  }
}
