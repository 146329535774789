import { LayoutAccessComponent, NotFoundComponent } from '../../components';
import { RootRouteGuard } from '../guards';
import { Routes } from '@angular/router';

import { accountingRootRoute } from './accounting';
import { settingsRootRoute } from './settings';
import { aviationRootRoute } from './aviation';
import { geodataRootRoute } from './geodata';
import { developRootRoute } from './develop';
import { vonageRootRoute } from './vonage';
import { authRootRoute } from './auth';
import { fuelRootRoute } from './fuel';
import { hrRootRoute } from './hr';


export const mainModuleRoutes: Routes = [
  {
    path: '',
    canActivate: [RootRouteGuard],
    children: [],
  },
  accountingRootRoute,
  developRootRoute,
  authRootRoute,
  settingsRootRoute,
  fuelRootRoute,
  aviationRootRoute,
  geodataRootRoute,
  vonageRootRoute,
  hrRootRoute,
  {
    path: '**',
    component: LayoutAccessComponent,
    children: [{
      path: '',
      component: NotFoundComponent,
    }]
  },
];
