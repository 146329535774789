import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { EventEmitter, FactoryProvider } from '@angular/core';
import { LocalStorageService } from '../local-storage';
import { map, tap, catchError } from 'rxjs/operators';
import { Jwt, JwtStruct } from '../../models';
import { throwError } from 'rxjs';

export const STORAGE_TOKEN_NAME = '|JWT_TOKEN|';
export const TIME_BEFORE_END = 60 * 60 * 20 * 1000; // 20 minutes

export class JwtService {

  public onChange = new EventEmitter();

  private jwt: Jwt;
  private timeOutId: any;

  constructor (
    private http: HttpClient,
    private localStorageSerive: LocalStorageService,
  ) {
    this.init();
  }

  public init() {
    let jwtStruct = this.localStorageSerive.getItem(STORAGE_TOKEN_NAME) as JwtStruct;
    if (!(jwtStruct instanceof Object)) {
      jwtStruct = {};
    }
    this.jwt = Jwt.create(jwtStruct);
    this.setRefreshTimeout();
  }

  private setRefreshTimeout() {
    try {
      let delta = this.jwt.getRefreshTimeout() - TIME_BEFORE_END;
      clearTimeout(this.timeOutId);
      if (delta < 0) {
        this.refreshToken();
      } else {
        this.timeOutId = setTimeout(() => {
          this.refreshToken();
        }, delta);
      }
    } catch (e) { }
  }

  public getToken(): string {
    if (!this.jwt) {
      return '';
    }
    return this.jwt.token;
  }

  public setToken(jwt: Jwt): void {
    this.jwt = jwt;
    this.localStorageSerive.setItem(STORAGE_TOKEN_NAME, jwt);
    this.setRefreshTimeout();
    this.onChange.emit(jwt.token);
  }

  public destroyToken(): void {
    this.jwt = Jwt.create(); // empty
    this.localStorageSerive.removeItem(STORAGE_TOKEN_NAME);
    this.onChange.emit('');
  }

  public refreshToken() {
    return this.http.post(`${environment.URL.API}/auth/jwt/refresh/`, { token: this.getToken() })
      .pipe(
        map((data) => this.jwt.update(data['token'])),
      )
      .subscribe((jwt: Jwt) => {
        this.setToken(jwt);
      }, (e) => {
        this.destroyToken();
      });
  }

  public verifyToken() {
    let token = this.getToken();
    if (!token) {
      return throwError(new HttpErrorResponse({
        status: 422,
        error: { detail: 'Token is not set' }
      }));
    }

    return this.http.post(`${environment.URL.API}/auth/jwt/verify/`, { token })
      .pipe(
        tap((data) => {
          this.setToken(Jwt.create(data['token']));
        }),
        catchError((error) => throwError(error)),
      );
  }
}

export let JWT_SERVICE: JwtService;

export function jwtServiceFactory(http, storage) {
  if (!JWT_SERVICE) {
    JWT_SERVICE = new JwtService(http, storage);
  }
  return JWT_SERVICE;
}

export const JWTServiceProvider: FactoryProvider = {
  provide: JwtService,
  useFactory: jwtServiceFactory,
  deps: [HttpClient, LocalStorageService]
};
