import { IPAgent, Order, Search, SearchBatch, SearchBatchStruct, Vendor } from '@fuel/models';
import { Division, Employee, Platform } from '@auth/models';
import { EventEmitter, Injectable } from '@angular/core';
import { Aircraft } from '@aviation/models';
import { Location } from '@geodata/models';
import { Evaluation } from '@hr/models';

@Injectable({ providedIn: 'root' })
export class EventsService {

  constructor () {
  }

  public logout = new EventEmitter();

  public sidebar = {
    closeStart: new EventEmitter(),
    closed: new EventEmitter(),
    openStart: new EventEmitter(),
    opened: new EventEmitter(),
  };

  public auth = {
    divisionAttached: new EventEmitter<Division>(),
  }

  public analytics = {
    aircraftChange: new EventEmitter<Aircraft>(),
    divisionChange: new EventEmitter<Division>(),
    locationChange: new EventEmitter<Location>(),
    ipagentChange: new EventEmitter<IPAgent>(),
    salesAgentChange: new EventEmitter<Employee>(),
    vendorChange: new EventEmitter<Vendor>(),
    platformChange: new EventEmitter<Platform>()
  };

  public hr = {
    evaluationChange: new EventEmitter<Evaluation>(),
    employeeChange: new EventEmitter<Employee>()
  };

  public fuel = {
    batchDivisionChange: new EventEmitter<SearchBatch>(),
    currentBatchData: new EventEmitter<SearchBatchStruct>(),
    searchSelection: new EventEmitter<Search>(),
    searchRemoval: new EventEmitter<Search>(),
    searchFlightTypeChange: new EventEmitter<Search>(),
    searchDestinationTypeChange: new EventEmitter<Search>()
  };

  public cart = {
    initialize: new EventEmitter(),
    add: new EventEmitter<Order>(),
    update: new EventEmitter<Order>(),
    remove: new EventEmitter<Order>(),
    submit: new EventEmitter(),
    clear: new EventEmitter()
  };
}
