import { Model } from '@evo/models';

export interface ParamStruct<T> {
  name: string;
  label: string;
  value: T;
}

export class Param<T> extends Model<ParamStruct<T>> {

  constructor (
    public name: string,
    public label: string,
    public value: T
  ) {
    super();
  }

  public static create<T>(data: ParamStruct<T>) {
    return new Param(data.name, data.label, data.value);
  }

  public override get pk() {
    return this.value;
  }

  public toJSON(): ParamStruct<T> {
    return {
      name: this.name,
      label: this.label,
      value: this.value
    };
  }

  public override toString(): string {
    return this.name;
  }

  public update(data: ParamStruct<T>): this {
    return this;
  }
}
