import { environment } from '@environments/environment';
import { Invoice } from '../../../accounting/models';
import { Order } from '../../../fuel/models';
import { Injectable } from '@angular/core';
import { JwtService } from '../jwt';

@Injectable({ providedIn: 'root' })
export class FilesService {

  constructor (
    private jwt: JwtService,
  ) {
  }

  private donwloadFile(path: string, params: URLSearchParams) {
    params.set('jwt', this.jwt.getToken());
    return window.open(`${environment.URL.API}${path}?${params.toString()}`, '_blank');
  }

  public downloadInvoice(invoice: Invoice) {
    return this.donwloadFile(`/fuel/download/invoice/${invoice.uuid}/`, new URLSearchParams());
  }

  public downloadRelease(order: Order) {
    return this.donwloadFile(`/fuel/download/release/${order.uuid}/`, new URLSearchParams());
  }

  public downloadClientAnalytics(params: URLSearchParams) {
    return this.donwloadFile('/fuel/reports/csv/', params);
  }

  public downloadSearchHistory(params: URLSearchParams) {
    return this.donwloadFile('/fuel/admin/search-history/csv/', params);
  }

  public downloadUsersCsv(params: URLSearchParams) {
    return this.donwloadFile(`/auth/admin/users/csv/`, params);
  }

  public downloadAdminAnalytics(params: URLSearchParams) {
    return this.donwloadFile('/fuel/admin/reports/csv/', params);
  }

  public downloadAdminOrders(params: URLSearchParams) {
    return this.donwloadFile('/fuel/admin/orders/csv/', params);
  }
}
