import {FactoryProvider} from '@angular/core';
import {Link} from '../../models';


export class Tabs {

  private tabs: Link[] = [];

  constructor() {
  }

  public get length() {
    return this.tabs.length;
  }

  public set(tabs: Link[] = []) {
    this.tabs = tabs;
    return this;
  }

  public append(tab: Link) {
    this.tabs.push(tab);
    return this;
  }

  public remove(index: number) {
    this.tabs.splice(index, 1);
    return this;
  }

  public clear() {
    delete this.tabs;
    this.tabs = [];
    return this;
  }

  public get(name: string) {
    return this.tabs.find((c) => {
      return (c.name + '').toLowerCase() == (name + '').toLowerCase();
    })
  }

  public at(index: number) {
    return this.tabs[index];
  }

  public all() {
    return this.tabs;
  }
}

export let TABS: Tabs;

export function TabsFactory() {
  if (!TABS) {
    TABS = new Tabs();
  }
  return TABS;
}

export const TabsProvider: FactoryProvider = {
  provide: Tabs,
  useFactory: TabsFactory
};
