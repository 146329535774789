import {AbstractControl} from '@angular/forms';

export function updateControlError(control: AbstractControl, error: string, message: string, invalid: boolean) {
  let errors = control.errors || {};

  if (invalid) {
    errors[error] = message;
  } else {
    delete errors[error];
    if (Object.keys(errors).length == 0) {
      errors = null;
    }
  }

  control.setErrors(errors);
}
