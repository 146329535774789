import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'array',
})
export class ArrayPipe implements PipeTransform {
  transform(length: number): number[] {
    let a = new Array(length).fill(0);
    a = a.map((v, i) => i);
    return a;
  }
}
