import { Model } from '@evo/models';
import { Stats, StatsStruct } from '..';

export interface PlatformStatsStruct {
  customers: StatsStruct;
  orders: StatsStruct;
  invoices: StatsStruct;
  volume: StatsStruct;
  total: StatsStruct;
  commission: StatsStruct;
  conversion: StatsStruct;
}

export class PlatformStats extends Model<PlatformStatsStruct> {
  constructor (
    public customers: Stats,
    public orders: Stats,
    public invoices: Stats,
    public volume: Stats,
    public total: Stats,
    public commission: Stats,
    public conversion: Stats,
  ) {
    super();
  }

  public static create(data: PlatformStatsStruct) {
    if (!data) {
      data = {} as PlatformStatsStruct;
    }
    return new PlatformStats(
      data.customers ? Stats.create(data.customers) : null,
      data.orders ? Stats.create(data.orders) : null,
      data.invoices ? Stats.create(data.invoices) : null,
      data.volume ? Stats.create(data.volume) : null,
      data.total ? Stats.create(data.total) : null,
      data.commission ? Stats.create(data.commission) : null,
      data.conversion ? Stats.create(data.conversion) : null,
    );
  }

  public toJSON(): PlatformStatsStruct {
    return {
      customers: this.customers ? this.customers.toJSON() : null,
      orders: this.orders ? this.orders.toJSON() : null,
      invoices: this.invoices ? this.invoices.toJSON() : null,
      volume: this.volume ? this.volume.toJSON() : null,
      total: this.total ? this.total.toJSON() : null,
      commission: this.commission ? this.commission.toJSON() : null,
      conversion: this.conversion ? this.conversion.toJSON() : null
    };
  }

  public update(data: any): this {
    return this;
  }
}
