import { Model } from '@evo/models';

export interface DatetimeTypeStruct {
  name: string;
  value: boolean;
}

export class DatetimeType extends Model<DatetimeTypeStruct> {

  constructor (
    public name: string,
    public value: boolean
  ) {
    super();
  }

  public static create(data: DatetimeTypeStruct) {
    if (!data) {
      data = {} as DatetimeTypeStruct;
    }
    return new DatetimeType(data.name || null, !!data.value);
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): DatetimeTypeStruct {
    return {
      name: this.name,
      value: this.value
    };
  }

  public update(data: DatetimeTypeStruct): this {
    return this;
  }
}
