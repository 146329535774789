<ul class="sidebar-menu list-unstyled mb-0">
  <ng-container>
    <li class="menu-separator mt-0"><span>Fuel Web</span></li>

    <li *ngIf="user.can(ORDER.CREATE)" routerLinkActive="active">
      <a routerLink="/fuel/admin/search"><i class="fa-solid fa-search"></i>Fuel Search</a>
    </li>

    <li *ngIf="user.can(ORDER.VIEW)" routerLinkActive="active">
      <a routerLink="/fuel/admin/orders"><i class="fa-solid fa-list-ul"></i>Orders</a>
    </li>

    <li *ngIf="user.some(INVOICE.VIEW, SALES_INVOICE.VIEW)" routerLinkActive="active">
      <a routerLink="/accounting/admin/invoices"><i class="fa-solid fa-money-bills"></i>Invoices</a>
    </li>

    <li *ngIf="user.can(ANALYTICS.VIEW_ALL)" routerLinkActive="active">
      <a routerLink="/fuel/admin/analytics"><i class="fa-solid fa-chart-column"></i>Fuel Analytics</a>
    </li>

    <li *ngIf="user.can(ANALYTICS.VIEW_OWN)" routerLinkActive="active">
      <a routerLink="/fuel/admin/sales/reports"><i class="fa-solid fa-chart-bar"></i>Sales Analytics</a>
    </li>

    <li *ngIf="user.can(ANALYTICS.VIEW_FBO)" routerLinkActive="active">
      <a routerLink="/fuel/admin/fbo-dashboard/airports"><i class="fa-solid fa-chart-simple"></i>FBO Dashboard</a>
    </li>

    <li *ngIf="user.can(SEARCH.VIEW)" routerLinkActive="active">
      <a routerLink="/fuel/admin/search-history"><i class="fa-solid fa-clock-rotate-left"></i>Search History</a>
    </li>

    <li *ngIf="user.can(CALL.VIEW)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/vonage/admin/sales-team"><i class="fa-solid fa-chart-line"></i>Sales Performance</a>
    </li>

    <li *ngIf="user.isSuperUser()" routerLinkActive="active">
      <a routerLink="/settings/admin/quickbooks"><i class="fa-solid fa-key"></i>QuickBooks API</a>
    </li>
  </ng-container>

  <ng-container *ngIf="user.canOneOf(COMPANY.VIEW, USER.VIEW, CODES.VIEW, LOCATION.VIEW, COUNTRY.VIEW)">
    <li class="menu-separator"><span>Fuel CRM</span></li>

    <li *ngIf="user.can(COMPANY.VIEW)" routerLinkActive="active">
      <a routerLink="/access/admin/companies"><i class="fa-solid fa-city"></i>Companies</a>
    </li>

    <li *ngIf="user.can(USER.VIEW)" routerLinkActive="active">
      <a routerLink="/access/admin/users"><i class="fa-solid fa-users"></i>Users</a>
    </li>

    <li *ngIf="user.isSuperUser()" routerLinkActive="active">
      <a routerLink="/access/admin/platforms"><i class="fa-solid fa-layer-group"></i>Platforms</a>
    </li>

    <li *ngIf="user.can(CODES.VIEW)" routerLinkActive="active">
      <a routerLink="/geodata/admin/codes"><i class="fa-solid fa-location-pin"></i>Airport Codes</a>
    </li>

    <li *ngIf="user.can(LOCATION.VIEW)" routerLinkActive="active">
      <a routerLink="/geodata/admin/locations"><i class="fa-solid fa-location-dot"></i>Airports</a>
    </li>

    <!--li *ngIf="user.can(COUNTRY.VIEW)" routerLinkActive="active">
      <a routerLink="/geodata/admin/countries"><i class="fa-solid fa-globe"></i>Countries</a>
    </li-->
  </ng-container>

  <ng-container *ngIf="user.canOneOf(EMPLOYEE.VIEW, DEPARTMENT.VIEW, GROUP.VIEW, FACTORS.VIEW)">
    <li class="menu-separator"><span>Human Resource</span></li>

    <li *ngIf="user.can(EMPLOYEE.VIEW)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/hr/admin/employees">
        <i class="fa-solid fa-users-between-lines"></i>People
      </a>
    </li>

    <li *ngIf="user.can(DEPARTMENT.VIEW)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/hr/admin/departments">
        <i class="fa-solid fa-building-columns"></i>Departments
      </a>
    </li>

    <li *ngIf="user.can(GROUP.VIEW)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/hr/admin/groups">
        <i class="fa-solid fa-briefcase"></i>Positions
      </a>
    </li>

    <li *ngIf="user.can(FACTORS.VIEW)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/hr/admin/factors">
        <i class="fa-regular fa-star"></i>Factors
      </a>
    </li>

    <li *ngIf="user.isSuperUser()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/hr/superuser/permissions">
        <i class="fa-solid fa-unlock-keyhole"></i>Permissions
      </a>
    </li>
  </ng-container>

  <ng-container *ngIf="!user.isSuperUser()">
    <li class="menu-separator"><span>Bonuses</span></li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/hr/me">
        <i class="fa-solid fa-user-gear"></i>My Evaluations
      </a>
    </li>
  </ng-container>

  <li class="menu-separator"><span>EVO Apps</span></li>
  <li><a href="https://www.flight-compass.com/" target="_blank">Flight Compass</a></li>
  <li><a href="https://briefme.aero/handling-estimate" target="_blank">Airport Fee Estimator</a></li>
  <li><a href="https://briefme.aero/" target="_blank">Airport Briefings</a></li>
  <li><a href="https://flyevo.com/airport-briefing-app" target="_blank">3D Airport Briefing</a></li>
  <li><a href="https://notams.evo-jet.com/" target="_blank">NOTAMizer</a></li>

  <li class="menu-separator"><span>24 / 7 Fuel</span></li>

  <li><a href="tel:+14154046133">
      <i class="fa-solid fa-phone"></i>
      <span class="small">+1 415 404 6133</span>
    </a></li>

  <li><a href="mailto:orderfuel&#64;flyevo.com">
      <i class="fa-solid fa-at"></i>
      <span class="small">orderfuel&#64;flyevo.com</span>
    </a></li>

</ul>