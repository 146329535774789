import { Person, PersonStruct } from '@auth/models/users/person';
import { Model } from '@evo/models';
import { Factor, FactorStruct } from '../factor';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface MarkStruct {
  uuid: number;
  feedback: string;
  internal: string;
  mark: number;
  score: number;
  weight: number;
  date_created: string;
  quarterly_score_trend: number;
  overall_score_trend: number;
  quarterly_trend: number;
  overall_trend: number;
  created_by: PersonStruct;
  factor: FactorStruct;
}

export class Mark extends Model<MarkStruct> {

  constructor (
    public uuid: number,
    public feedback: string,
    public internal: string,
    public mark: number,
    public score: number,
    public weight: number,
    public dateCreated: Moment,
    public quarterlyScoreTrend: number,
    public overallScoreTrend: number,
    public quarterlyTrend: number,
    public overallTrend: number,
    public createdBy: Person,
    public factor: Factor
  ) {
    super();
  }

  public static create(data?: MarkStruct) {
    if (!data) {
      data = {} as any;
    }
    return new Mark(
      data.uuid || null,
      data.feedback || '',
      data.internal || '',
      'number' == typeof data.mark ? data.mark : null,
      'number' == typeof data.score ? data.score : null,
      'number' == typeof data.weight ? data.weight : null,
      data.date_created ? moment(data.date_created) : null,
      'number' == typeof data.quarterly_score_trend ? data.quarterly_score_trend : null,
      'number' == typeof data.overall_score_trend ? data.overall_score_trend : null,
      'number' == typeof data.quarterly_trend ? data.quarterly_trend : null,
      'number' == typeof data.overall_trend ? data.overall_trend : null,
      data.created_by ? Person.create(data.created_by) : null,
      data.factor ? Factor.create(data.factor) : null,
    );
  }

  public toJSON(): MarkStruct {
    return {
      uuid: this.uuid,
      feedback: this.feedback,
      internal: this.internal,
      mark: this.mark,
      score: this.score,
      weight: this.weight,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
      quarterly_score_trend: this.quarterlyScoreTrend,
      overall_score_trend: this.overallScoreTrend,
      quarterly_trend: this.quarterlyTrend,
      overall_trend: this.overallTrend,
      created_by: this.createdBy ? this.createdBy.toJSON() : null,
      factor: this.factor ? this.factor.toJSON() : null,
    };
  }

  public update(data: MarkStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.feedback = ('string' == typeof data.feedback) ? data.feedback : this.feedback;
    this.internal = ('string' == typeof data.internal) ? data.internal : this.internal;
    this.mark = 'number' == typeof data.mark ? data.mark : this.mark;
    this.score = 'number' == typeof data.score ? data.score : this.score;
    this.weight = 'number' == typeof data.weight ? data.weight : this.weight;
    this.dateCreated = data.date_created ? moment(data.date_created) : this.dateCreated;
    this.quarterlyScoreTrend = 'number' == typeof data.quarterly_score_trend ? data.quarterly_score_trend : this.quarterlyScoreTrend;
    this.overallScoreTrend = 'number' == typeof data.overall_score_trend ? data.overall_score_trend : this.overallScoreTrend;
    this.quarterlyTrend = 'number' == typeof data.quarterly_trend ? data.quarterly_trend : this.quarterlyTrend;
    this.overallTrend = 'number' == typeof data.overall_trend ? data.overall_trend : this.overallTrend;
    this.createdBy = data.created_by ? Person.create(data.created_by) : this.createdBy;
    this.factor = data.factor ? Factor.create(data.factor) : this.factor;
    return this;
  }
}
