import {Moment} from 'moment';
import * as moment from 'moment';

export function today(): Moment {
  let now = new Date();
  return moment.utc(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
}

export function dateFormat(date: Moment) {
  return date.format('DD MMM, YYYY');
}

export function dateTimeFormat(date: Moment) {
  return date.format('DD MMM, YYYY HH:mm');
}
