import { INVOICE_PERMISSIONS } from '@accounting/permissions';
import { Component, OnInit } from '@angular/core';
import { Division } from '@auth/models';
import { User } from '@auth/models';

@Component({
  selector: 'app-sidebar-client',
  templateUrl: './sidebar-client.component.html',
  styleUrls: ['./sidebar-client.component.scss']
})
export class SidebarClientComponent implements OnInit {

  public INVOICE = INVOICE_PERMISSIONS;

  constructor (
    public user: User,
    public division: Division
  ) {
  }

  public ngOnInit(): void {
  }

}
