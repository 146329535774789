import { Salutation, SalutationStruct } from '@common/models';
import { Model } from '@evo/models';
import { isNull } from '@evo/utils/types';

export interface PersonStruct {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar: string;
  is_active: boolean;
  salutation?: SalutationStruct;
  salutation_id?: string;
}

export class Person extends Model<PersonStruct> {

  constructor (
    public uuid: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public avatar: string,
    public isActive: boolean,
    public salutation: Salutation
  ) {
    super();
  }

  public static create(data?: PersonStruct) {

    if (!data) {
      data = {} as any;
    }

    return new Person(
      data.uuid || null,
      data.first_name || null,
      data.last_name || null,
      data.email || null,
      data.phone || null,
      data.avatar || '',
      'boolean' == typeof data.is_active ? data.is_active : false,
      data.salutation ? Salutation.create(data.salutation) : null
    );
  }

  public getFullName() {
    let salutation = this.salutation ? this.salutation.name : '',
      firstName = this.firstName || '',
      lastName = this.lastName || '';
    let parts = salutation ? [salutation, firstName, lastName] : [firstName, lastName];
    return parts.join(' ');
  }

  public getFullUserName() {
    return `${this.email} (${this.getFullName()})`;
  }

  public override toString(): string {
    return this.getFullUserName();
  }

  public toJSON(): PersonStruct {
    return {
      uuid: this.uuid,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      phone: this.phone,
      avatar: this.avatar,
      is_active: this.isActive,
      salutation_id: this.salutation ? this.salutation.uuid : null,
    };
  }

  public update(data: PersonStruct): this {
    this.uuid = data.uuid || this.uuid;
    this.firstName = data.first_name || this.firstName;
    this.lastName = data.last_name || this.lastName;
    this.email = data.email || this.email;
    this.phone = data.phone || this.phone;
    this.avatar = data.avatar || this.avatar;
    this.isActive = 'boolean' == typeof data.is_active ? data.is_active : this.isActive;
    this.salutation = isNull(data.salutation) ? null : data.salutation ? Salutation.create(data.salutation) : this.salutation;
    return this;
  }

}
