import { UnitsCollection, UnitsStruct } from '@common/models';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UnitsService {

  private cache: { [key: string]: UnitsCollection } = {};

  constructor (
    private http: HttpClient
  ) { }


  public volume() {
    let collection = this.cache['volume'];
    if (collection) {
      return of(collection);
    }
    return this.http.get(`${environment.URL.API}/common/units/volume/`)
      .pipe(
        map((data: UnitsStruct[]) => {
          return UnitsCollection.create(data);
        }),
        tap((collection: UnitsCollection) => {
          this.cache['volume'] = collection;
        }),
      );
  }

  public distance() {
    let collection = this.cache['distance'];
    if (collection) {
      return of(collection);
    }
    return this.http.get(`${environment.URL.API}/common/units/distance/`)
      .pipe(
        map((data: UnitsStruct[]) => {
          return UnitsCollection.create(data);
        }),
        tap((collection: UnitsCollection) => {
          this.cache['distance'] = collection;
        }),
      );
  }
}
