import { Model } from '@evo/models';

export interface ModuleStruct {
  name: string;
}

export class Module extends Model<ModuleStruct> {

  constructor (
    public name: string,
  ) {
    super();
  }

  public static create(data: ModuleStruct) {
    return new Module(
      data.name ? data.name[0].toUpperCase() + data.name.slice(1) : ''
    );
  }

  public toJSON(): ModuleStruct {
    return {
      name: this.name,
    };
  }

  public update(data: ModuleStruct): this {
    this.name = data.name ? data.name[0].toUpperCase() + data.name.slice(1) : this.name;
    return this;
  }
}
