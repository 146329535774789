import { NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule, NgbTypeaheadModule, NgbToastModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { COMMON_COMPONENTS, CONTROLS_COMPONENTS, TABLE_COMPONENTS, DIRECTIVES } from './components';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as NgCommonModule } from '@angular/common';
import { NgxMaskConfig } from '@evo/utils/libs/ngx-mask';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PIPES } from './pipes';

@NgModule({
  imports: [
    NgCommonModule,
    FormsModule, ReactiveFormsModule,
    RouterModule,
    NgxMaskDirective, NgxMaskPipe,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbToastModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
  declarations: [
    ...COMMON_COMPONENTS,
    ...CONTROLS_COMPONENTS,
    ...TABLE_COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  exports: [
    NgCommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective, NgxMaskPipe,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbToastModule,
    NgbDatepickerModule,
    NgbTimepickerModule,

    ...COMMON_COMPONENTS,
    ...CONTROLS_COMPONENTS,
    ...TABLE_COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
})
export class CommonModule {
  static forRoot() {
    return {
      ngModule: CommonModule,
      providers: [ // services that you want to share across modules        
        provideHttpClient(withInterceptorsFromDi()),
        provideNgxMask(NgxMaskConfig),
        ...PIPES
      ]
    };
  }
}
