import { EventsService, LayoutService, ToastService } from '../../../../../services';
import { OrderBatchesService } from '../../../../../../fuel/services/admin';
import { ORDER_PERMISSIONS } from '../../../../../../fuel/permissions';
import { observer } from '@evo/utils/libs/rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Batch } from '../../../../../../fuel/models';
import { User } from '@auth/models';
import { merge, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.scss']
})
export class NavbarAdminComponent implements OnInit, OnDestroy {

  public ORDER = ORDER_PERMISSIONS;

  public batch: Batch;
  private subs: Subscription[];

  constructor (
    public user: User,
    public layout: LayoutService,
    private service: OrderBatchesService,
    private events: EventsService,
    private toast: ToastService
  ) {
  }

  public ngOnInit(): void {
    this.setupCartIndication();
  }

  public ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private setupCartIndication() {
    if (this.user.can(this.ORDER.CREATE)) {
      this.subs = [
        merge(
          this.events.cart.initialize,
          this.events.cart.add,
          this.events.cart.update,
          this.events.cart.remove,
          this.events.cart.submit,
          this.events.cart.clear
        ).pipe(
          switchMap(() => {
            return this.service.cart();
          })
        ).subscribe(observer((batch) => {
          this.batch = batch;
        }, (e) => {
          this.toast.error(e);
        }))
      ];
      this.events.cart.initialize.next(true);
    } else {
      this.subs = [];
    }
  }
}
