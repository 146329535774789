import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Division, User } from '@auth/models';
import { ToastService } from '@main/services';
import { Injectable } from '@angular/core';

@Injectable()
export class RootRouteGuard implements CanActivate {
  constructor (
    private user: User,
    private router: Router,
    private division: Division,
    private toast: ToastService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot) {
    if (!this.user.isAuthenticated()) {
      this.router.navigateByUrl('/access/sign-in');
    } else if (this.division.isServiceProvider() || this.division.isSuperUser()) {
      this.router.navigateByUrl('/fuel/admin/analytics/dashboard');
    } else if (this.division.isPlatform()) {
      this.router.navigateByUrl('/fuel/platform/dashboard');
    } else if (this.division.isClient()) {
      this.router.navigateByUrl('/fuel/search/airports');
    } else {
      this.toast.error(['Division type is not set.', 'Please contact site admin for further actions.'], 'Redirect failed.');
    }
    return false;
  }
}
