export const cookieConsentConfig = {
  current_lang: 'en',
  autoclear_cookies: true,                   // default: false
  page_scripts: true,                        // default: false

  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: '',                      // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 0,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  languages: {
    en: {
      consent_modal: {
        title: 'This Website Uses Cookies',
        description: 'This website uses essential cookies to ensure its proper operation and tracking cookies to improve user experiance. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Review Options</button>',
        primary_btn: {
          text: 'Accept All',
          role: 'accept_all'              // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Accept Necessary',
          role: 'accept_necessary'        // 'settings' or 'accept_necessary'
        }
      },
      settings_modal: {
        title: 'Cookie Preferences',
        save_settings_btn: 'Save Settings',
        accept_all_btn: 'Accept All',
        reject_all_btn: 'Accept Necessary',
        close_btn_label: 'Close',
        cookie_table_headers: [
          {col1: 'Name'},
          {col2: 'Domain'},
          {col3: 'Expiration'},
          {col4: 'Description'}
        ],
        blocks: [
          {
            title: 'Cookie Usage',
            description: 'EVO uses cookies to ensure the basic functionalities of this website and to enhance user experience. You can choose for each category to opt-in/out whenever you want.'
          }, {
            title: 'EVO Privacy Policy and Terms of Service',
            description: 'By using this website you agree with EVO\'s <a target="_blank" href="https://flyevo.com/privacy-policy" class="cc-link">Privacy Policy</a> and <a target="_blank" href="https://flyevo.com/privacy-policy" class="cc-link">Terms of Service</a>',
            toggle: {
              value: 'policy',
              enabled: true,
              readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
            }
          }, {
            title: 'Strictly Necessary Cookies',
            description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
            }
          }, {
            title: 'Performance and Analytics Cookies',
            description: 'These cookies allow the website to remember the choices you have made in the past',
            toggle: {
              value: 'analytics',  // your cookie category
              enabled: false,
              readonly: false
            },
            cookie_table: [  // list of all expected cookies
              {
                col1: '^_ga',  // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2 years',
                col4: 'The GA cookie is set, during the first visit to this website. Google Analytics sends the client ID with each hit in order to associate hits with a user.',
                is_regex: true
              }
            ]
          }, {
            title: 'More Information',
            description: 'For any queries in relation to the above options please <a target="_blank" class="cc-link" href="https://flyevo.com/contact">Contact EVO</a>.',
          }
        ]
      }
    }
  }
};
