<div class="input-wrapper" [class.is-invalid]="isInvalid">
  <input class="form-control"
         [class.form-control-sm]="size == 'sm'"
         autocomplete="off"
         random-name
         ngbDatepicker
         #inputRef
         #datepicker="ngbDatepicker"
         [class.is-invalid]="isInvalid"
         [class.is-valid]="isValid"
         [placeholder]="placeholder"
         [(ngModel)]="date"
         (ngModelChange)="dateTimeChange(true)"
         [disabled]="isDisabled"
         (blur)="onBlur($event)"
         (click)="datepicker.toggle()"
         (keydown.enter)="datepicker.open()"
         [minDate]="ngbDateAdapter.fromModel(minDate)">
</div>
<ngb-timepicker
  #timepicker
  [size]="getTimepickerSize()"
  [class.is-invalid]="isInvalid"
  [class.is-valid]="isValid"
  [disabled]="isDisabled"
  [(ngModel)]="time"
  (ngModelChange)="dateTimeChange(false)">
</ngb-timepicker>
<small class="invalid-feedback">{{errors[0]}}</small>
