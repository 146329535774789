import { Location, LocationStruct } from '@geodata/models';
import { Model } from '@evo/models';
import { Order, OrderStruct } from '../order';

export interface LocationOrdersStruct {
  location: LocationStruct;
  orders?: OrderStruct[];
}

export class LocationOrders extends Model<LocationOrdersStruct> {

  constructor (
    public location: Location,
    public orders: Order[]
  ) {
    super();
  }

  public static create(data?: LocationOrdersStruct) {
    if (!data) {
      data = {} as LocationOrdersStruct;
    }
    return new LocationOrders(
      data.location ? Location.create(data.location) : null,
      data.orders ? data.orders.map((o) => Order.create(o)) : []
    );
  }

  public override toString(): string {
    return `${this.location.code} - ${this.orders.length}`;
  }

  public toJSON(): LocationOrdersStruct {
    return {
      location: this.location ? this.location.toJSON() : null,
      orders: this.orders ? this.orders.map((o) => o.toJSON()) : []
    };
  }

  public update(data: LocationOrdersStruct): this {
    return this;
  }

}
