import { Report, ReportStruct, ReportChartItemStruct, ReportChartItem, AdminStats, AdminStatsStruct } from '../../../models';
import { ReportItem, ReportItemStruct, IPAgent, Vendor } from '../../../models';
import { Pagination, PaginationStruct } from '@common/models';
import { environment } from '@environments/environment';
import { Division, Employee } from '@auth/models';
import { toPrimaryKey } from '@evo/utils/helpers';
import { Aircraft } from '../../../../aviation/models';
import { Location } from '@geodata/models';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(params: URLSearchParams, pagination?: Pagination<ReportStruct, Report>) {
    return this.http.get(`${environment.URL.API}/fuel/admin/reports/?${params.toString()}`).pipe(
      map((data: PaginationStruct<ReportStruct>) => {
        return pagination ? pagination.update(data) : Pagination.create<ReportStruct, Report>(data, Report);
      }),
      catchError((e) => {
        return of(Pagination.empty<ReportStruct, Report>(Report));
      })
    );
  }

  public stats(params: URLSearchParams): Observable<AdminStats> {
    return this.http.get(`${environment.URL.API}/fuel/admin/reports-stats/?${params.toString()}`).pipe(
      map((resp: AdminStatsStruct) => {
        return AdminStats.create(resp);
      })
    );
  }

  public topStats(params: URLSearchParams, pagination?: Pagination<ReportItemStruct, ReportItem<any>>) {
    return this.http.get(`${environment.URL.API}/fuel/admin/reports-top-stats/?${params.toString()}`).pipe(
      map((resp: PaginationStruct<ReportItemStruct>) => {
        return pagination ? pagination.update(resp) :
          Pagination.create<ReportItemStruct, ReportItem<any>>(resp, ReportItem);
      })
    );
  }

  public chartData(
    division?: string | Division, aircraft?: string | Aircraft, location?: string | Location,
    ipagent?: string | IPAgent, sales?: string | Employee, vendor?: string | Vendor
  ) {
    let params = {};
    if (aircraft) {
      params['aircraft'] = toPrimaryKey(aircraft);
    }
    if (division) {
      params['division'] = toPrimaryKey(division);
    }
    if (location) {
      params['location'] = toPrimaryKey(location);
    }
    if (ipagent) {
      params['ip_agent'] = toPrimaryKey(ipagent);
    }
    if (sales) {
      params['sales'] = toPrimaryKey(sales);
    }
    if (vendor) {
      params['vendor'] = toPrimaryKey(vendor);
    }
    return this.http.get(`${environment.URL.API}/fuel/admin/reports-chart/`, { params }).pipe(
      map((resp: ReportChartItemStruct[]) => {
        return resp.map((r) => ReportChartItem.create(r));
      })
    );
  }

  public dataByYear(
    division?: string | Division, aircraft?: string | Aircraft, location?: string | Location,
    ipagent?: string | IPAgent, sales?: string | Employee, vendor?: string | Vendor
  ) {
    let params = {};
    if (aircraft) {
      params['aircraft'] = toPrimaryKey(aircraft);
    }
    if (division) {
      params['division'] = toPrimaryKey(division);
    }
    if (location) {
      params['location'] = toPrimaryKey(location);
    }
    if (ipagent) {
      params['ip_agent'] = toPrimaryKey(ipagent);
    }
    if (sales) {
      params['sales'] = toPrimaryKey(sales);
    }
    if (vendor) {
      params['vendor'] = toPrimaryKey(vendor);
    }
    return this.http.get(`${environment.URL.API}/fuel/admin/reports-by-year/`, { params }).pipe(
      map((resp: ReportChartItemStruct[]) => {
        return resp.map((r) => ReportChartItem.create(r));
      })
    );
  }
}
