import { Collection } from '@evo/models';
import { Department, DepartmentStruct } from './department';

export class DepartmentsCollection extends Collection<Department> {

  static create(data: DepartmentStruct[]) {
    return new DepartmentsCollection(data.map((d) => Department.create(d)));
  }

  public search(term: string) {
    term = (term + '').toUpperCase();
    return this.items.filter((item) => {
      return (item.name + '').toUpperCase().indexOf(term) > -1;
    });
  }
}
