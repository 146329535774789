import { isNumber, isNumberOrNull, isString, isStringOrNull } from '@evo/utils/types';
import { Model } from '@evo/models';
import { ORDER_STATUS } from '../division/meta';

export interface DivisionShortcutStruct {
  uuid: string;
  name: string;
  ecp: string;
  fma: number;
  phone: string;
  website: string;
  order_status: ORDER_STATUS;
  dispatcher_notes: string;
}

export class DivisionShortcut extends Model<DivisionShortcutStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public ecp: string,
    public fma: number,
    public phone: string,
    public website: string,
    public orderStatus: ORDER_STATUS,
    public dispatcherNotes: string
  ) {
    super();
  }

  public static create(data?: DivisionShortcutStruct) {
    if (!data) {
      data = {} as DivisionShortcutStruct;
    }
    return new DivisionShortcut(
      isString(data.uuid) ? data.uuid : null,
      isString(data.name) ? data.name : null,
      isString(data.ecp) ? data.ecp : null,
      isNumber(data.fma) ? data.fma : null,
      isString(data.phone) ? data.phone : null,
      isString(data.website) ? data.website : null,
      isNumber(data.order_status) ? data.order_status : null,
      isString(data.dispatcher_notes) ? data.dispatcher_notes : null
    );
  }

  public get ordersEnabled() {
    return this.orderStatus == ORDER_STATUS.ALLOWED;
  }

  public get ordersDisabled() {
    return this.orderStatus != ORDER_STATUS.ALLOWED;
  }

  public getName(max = 32) {
    return this.name.length <= max ? this.name : this.name.slice(0, max) + '...';
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): DivisionShortcutStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      ecp: this.ecp,
      fma: this.fma,
      phone: this.phone,
      website: this.website,
      order_status: this.orderStatus,
      dispatcher_notes: this.dispatcherNotes
    };
  }

  public update(data: DivisionShortcutStruct): this {
    this.uuid = isStringOrNull(data.uuid) ? data.uuid : this.uuid;
    this.name = isStringOrNull(data.name) ? data.name : this.name;
    this.ecp = isStringOrNull(data.ecp) ? data.ecp : this.ecp;
    this.fma = isNumberOrNull(data.fma) ? data.fma : this.fma;
    this.phone = isStringOrNull(data.phone) ? data.phone : this.phone;
    this.website = isStringOrNull(data.website) ? data.website : this.website;
    this.orderStatus = isNumberOrNull(data.order_status) ? data.order_status : this.orderStatus;
    this.dispatcherNotes = isStringOrNull(data.dispatcher_notes) ? data.dispatcher_notes : this.dispatcherNotes;
    return this;
  }

  public override clear() {
    this.uuid = null;
    this.name = null;
    this.ecp = null;
    this.fma = null;
    this.phone = null;
    this.website = null;
    this.orderStatus = null;
    this.dispatcherNotes = null;
    return this;
  }
}
