import { CompanyType, CompanyTypeStruct } from './company-type';
import { Collection } from '@evo/models';

export class CompanyTypesCollection extends Collection<CompanyType> {

  public static create(data: CompanyTypeStruct[] = []) {
    return new CompanyTypesCollection(data.map((t) => CompanyType.create(t)));
  }

  public search(term: string, exclude: CompanyType[] = []) {
    term = term.toLowerCase();
    let found = this.items.filter((t) => {
      return (t.name + '').toLowerCase().indexOf(term) > -1 ||
        (t.code + '').toLowerCase().indexOf(term) > -1;
    });
    return exclude.length ? found.filter((t) => {
      return !exclude.find((e) => e.code == t.code);
    }) : found;
  }
}
