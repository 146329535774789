import { Model } from '@evo/models';
import { Stats, StatsStruct } from '..';
import { Location } from '@geodata/models';

export interface FboDashboardStatsStruct {
  orders: StatsStruct;
  invoices: StatsStruct;
  conversion: StatsStruct;
  customers: StatsStruct;
  invoice_volume: StatsStruct;
  invoice_total: StatsStruct;
}

export class FboDashboardStats extends Model<FboDashboardStatsStruct> {
  constructor (
    public orders: Stats,
    public invoices: Stats,
    public conversion: Stats,
    public customers: Stats,
    public invoiceVolume: Stats,
    public invoiceTotal: Stats,
    public lookups?: Stats
  ) {
    super();
  }

  public static create(data: FboDashboardStatsStruct) {
    if (!data) {
      data = {} as FboDashboardStatsStruct;
    }
    return new FboDashboardStats(
      data.orders ? Stats.create(data.orders) : null,
      data.invoices ? Stats.create(data.invoices) : null,
      data.conversion ? Stats.create(data.conversion) : null,
      data.customers ? Stats.create(data.customers) : null,
      data.invoice_volume ? Stats.create(data.invoice_volume) : null,
      data.invoice_total ? Stats.create(data.invoice_total) : null
    );
  }

  public setLocation(location: Location) {
    this.lookups = Stats.create({ value: location.countSearches || 0, label: 'Lookups', decimals: 0 });
  }

  public toJSON(): FboDashboardStatsStruct {
    return {
      orders: this.orders ? this.orders.toJSON() : null,
      invoices: this.invoices ? this.invoices.toJSON() : null,
      conversion: this.conversion ? this.conversion.toJSON() : null,
      customers: this.customers ? this.customers.toJSON() : null,
      invoice_volume: this.invoiceVolume ? this.invoiceVolume.toJSON() : null,
      invoice_total: this.invoiceTotal ? this.invoiceTotal.toJSON() : null,
    };
  }

  public update(data: any): this {
    return this;
  }
}
