import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService, GoogleAnalyticsService} from '../../../services';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {cookieConsentConfig} from '../../../config';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private loading: LoadingBarService,
    private layout: LayoutService,
    private ga: GoogleAnalyticsService
  ) {
  }

  public ngOnInit(): void {

    let bar = this.loading.useRef('router');

    this.subscriptions = [
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationStart) {
          bar.start();
        } else if (e instanceof NavigationEnd) {
          this.layout.breadcrumbsClear();
          this.layout.tabsClear();
          this.layout.closeSidebar();
          // dirty hack, but page title is set in component AFTER! navigation end
          setTimeout(() => {
            this.ga.pageView(e.urlAfterRedirects, this.layout.getPageTitle());
          }, 100);
        }
        if (e instanceof NavigationError || e instanceof NavigationEnd || e instanceof NavigationCancel) {
          bar.complete();
        }
      })
    ];
  }

  public ngAfterViewInit() {
    try {
      this.cookieConsent();
    } catch (e) {
      // .error(e);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private cookieConsent() {
    let cc: any = window['initCookieConsent']();

    cc.run(Object.assign({
      onFirstAction: (user_preferences, cookie) => {
        cookie.level && cookie.level.length && cookie.level.indexOf('analytics') > -1 && this.ga.startTracking();
        this.ga.pageView(this.router.routerState.snapshot.url, this.layout.getPageTitle());
      },
      onAccept: (cookie) => {
        cookie.level && cookie.level.length && cookie.level.indexOf('analytics') > -1 && this.ga.startTracking();
      },
      onChange: (cookie, changed_categories) => {
        // .log('change', cookie, changed_categories);
      },
    }, cookieConsentConfig));
  }


}
