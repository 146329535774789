import { DestinationType, DestinationTypeStruct, FlightType, FlightTypeStruct } from '../../common';
import { Client, DivisionShortcut, DivisionShortcutStruct } from '@auth/models';
import { isArray, isBoolean, isNull } from '@evo/utils/types';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';


export interface SearchBatchStruct {
  uuid: string;
  date_created: string;
  flight_type: FlightTypeStruct;
  destination_type: DestinationTypeStruct;
  is_active: boolean;
  division: DivisionShortcutStruct;
  emails?: string[];
}

export class SearchBatch extends Model<SearchBatchStruct> {
  constructor (
    public uuid: string,
    public dateCreated: Moment,
    public flightType: FlightType,
    public destinationType: DestinationType,
    public isActive: boolean,
    public division?: DivisionShortcut,
    public emails: string[] = []
  ) {
    super();
  }

  public static create(data: SearchBatchStruct) {
    return new SearchBatch(
      data.uuid || null,
      data.date_created ? moment(data.date_created) : null,
      data.flight_type ? FlightType.create(data.flight_type) : null,
      data.destination_type ? DestinationType.create(data.destination_type) : null,
      isBoolean(data.is_active) ? data.is_active : false,
      data.division ? DivisionShortcut.create(data.division) : null,
      isArray(data.emails) ? data.emails : []
    );
  }

  public getUniqueEmails(emails: string[]) {
    return this.emails.slice(1).filter((e) => {
      return emails.indexOf(e) == -1;
    });
  }

  public removeEmail(email: string) {
    let index = this.emails.indexOf(email);
    this.emails.splice(index, 1);
  }

  public addEmail(email: string) {
    this.emails.push(email);
  }

  public override toString(): string {
    return `${this.uuid}`;
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
      flight_type: this.flightType ? this.flightType.toJSON() : null,
      destination_type: this.destinationType ? this.destinationType.toJSON() : null,
      is_active: this.isActive,
      division: this.division ? this.division.toJSON() : null
    };
  }

  public update(data: SearchBatchStruct) {
    this.uuid = data.uuid || this.uuid;
    this.dateCreated = data.date_created ? moment(data.date_created) : this.dateCreated;
    this.flightType = data.flight_type ? FlightType.create(data.flight_type) : this.flightType;
    this.destinationType = data.destination_type ? DestinationType.create(data.destination_type) : this.destinationType;
    this.isActive = isBoolean(data.is_active) ? data.is_active : this.isActive;
    this.division = isNull(data.division) ? null : data.division ? DivisionShortcut.create(data.division) : this.division;
    this.emails = isArray(data.emails) ? data.emails : this.emails;
    return this;
  }
}
