import { HttpErrorResponse } from '@angular/common/http';
import { Model } from '@evo/models';

export function direction(orderBy: string) {
  return (orderBy + '').charAt(0) == '-' ? 'DESC' : 'ASC'
}

export function defaultIfError(value, defaultValue) {
  return value instanceof HttpErrorResponse ? defaultValue : value;
}

export function secondsToHMS(v: number | string) {
  v = parseInt(v.toString(), 10);
  let hours = Math.floor(v / 3600).toString();
  v = v % 3600;
  let minutes = Math.floor(v / 60).toString(),
    seconds = (v % 60).toString(),
    time: string[] = [hours, minutes, seconds];
  time = time.map((item) => {
    if (item.length == 1) {
      item = '0' + item;
    }
    return item;
  });
  return time.join(':');
}

export function toPrimaryKey(value: Model<any> | number | string): string {
  return (value as Model<any>).pk ? (value as Model<any>).pk : value;
}

export function uuid() {
  let result, i, j;
  result = '';
  for (j = 0; j < 32; j++) {
    if (j == 8 || j == 12 || j == 16 || j == 20) {
      result = result + '-';
    }
    i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
    result = result + i;
  }
  return result;
}

export function uniqueName() {
  return Math.random().toString(36).replace(/[^a-z]+/g, '');
}
