import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { PhonenumbersService } from '../../../services';
import { PhoneCountryCode } from '../../../models';
import { Observable, Subscription } from 'rxjs';
import { Model } from '@evo/models';
import { NgxMaskService } from 'ngx-mask';

@Component({
  selector: 'app-phone-control',
  templateUrl: './phone-control.component.html',
  styleUrls: ['./phone-control.component.scss'],
})
export class PhoneControlComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

  @ViewChild('phoneRef', { static: true })
  public phoneRef: ElementRef;

  @ViewChild('prefixRef', { static: true })
  public prefixRef: ElementRef;

  @ViewChild('typehead', { static: true })
  public typehead: NgbTypeahead;

  private prefixEl: HTMLInputElement;
  private phoneEl: HTMLInputElement;

  @Input()
  public placeholderPrefix = 'Prefix';

  @Input()
  public placeholderPhone = 'Number';

  @Input()
  public size: 'sm' | 'lg' | 'md' | undefined = 'md';

  @Input()
  public name = '';

  public value: string;
  public prefix: PhoneCountryCode;
  public phone: string;

  public isDisabled = false;
  public isInvalid = false;
  public isValid = false;

  public errors: string[] = [];
  private subscriptions: Subscription[] = [];

  private _onTouched = () => {
  };
  private _onChange = (_: any) => {
  };

  constructor (
    private ngControl: NgControl,
    private service: PhonenumbersService,
    private ngxMask: NgxMaskService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    ngControl.valueAccessor = this;
  }

  public ngOnInit(): void {
    let s = this.ngControl.statusChanges.subscribe((status) => {
      this.statusChange();
    });
    this.subscriptions.push(s);
  }

  public ngAfterViewInit(): void {
    this.prefixEl = this.prefixRef.nativeElement;
    this.phoneEl = this.phoneRef.nativeElement;

    let $input = this.prefixRef.nativeElement,
      $el = this.elementRef.nativeElement,
      id = $el.getAttribute('id');
    if (id) {
      this.renderer.setAttribute($el, 'id', null);
      this.renderer.setAttribute($input, 'id', id);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /***************************************** NGB Typehead ************************************/
  public search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((term) => {
        return this.service.search(term);
      }),
    );
  };

  public prefixFocus(e: Event): void {
    if (!this.prefix) {
      setTimeout(() => {
        const inputEvent: Event = new Event('input');
        e.target.dispatchEvent(inputEvent);
      }, 0);
    }
  }

  public prefixInput(e: Event) {
    let prefix = this._getPrefix();

    if (prefix instanceof PhoneCountryCode) {
      this.prefix = prefix;
      this.phoneEl.focus();
      this.typehead.isPopupOpen() && this.typehead.dismissPopup();
      this.prefixEl.value = '+' + prefix.number;
    } else {

      if (/^[A-Z]*$/i.test(prefix)) {
        prefix = prefix.toUpperCase();
        prefix = this.ngxMask.applyMask(prefix, 'U');
      } else {
        prefix = parseInt(prefix) + '';
        prefix = prefix == 'NaN' || prefix == '0' ? '' : this.ngxMask.applyMask(prefix, '+99');
      }

      if (!prefix || prefix == '+') {
        delete this.prefix;
      }

      this.prefixEl.value = prefix;
    }
  }

  public prefixBlur(e: Event) {
    if (!(this.prefix instanceof PhoneCountryCode)) {
      let prefix = this.service.getPrefixForce(this.prefixEl.value);
      this.prefixEl.value = '+' + prefix.number;
    }
  }

  private _getPrefix() {
    let el = this.prefixEl,
      value = el.value,
      prefix = this.service.getPrefix(value);
    return prefix ? prefix : value;
  }

  public prefixSelect(e: NgbTypeaheadSelectItemEvent) {
    this.prefix = e.item;
    this.phoneChange(this.phone);
    this.phoneEl.focus();
  }

  public phoneFocus(e: Event) {
    let prefix = this.prefixEl;
    if (!this.prefix) {
      prefix.focus();
    }
  }

  public phoneKeyDown(e: KeyboardEvent) {
    let code = e.key || e.code;
    if (code == 'Backspace' && !this.phone) {
      this.prefixEl.focus();
    }
  }

  public phoneChange(phone: string) {
    let length = this.service.getMaxLength(this.prefix, phone),
      mask = Array.apply(null, Array(length)).map((i) => '9').join('');
    this.phone = this.ngxMask.applyMask(phone, mask);
    this.phoneEl.value = this.phone;
    this.writeEntirePhone();
  }

  private writeEntirePhone() {
    let prefix = this.prefix instanceof PhoneCountryCode ? this.prefix.number : this.prefix + '',
      phone = this.phone || '',
      entire = prefix + phone;
    entire = entire.charAt(0) == '+' ? entire : '+' + entire;
    this.value = entire;
    this._onChange(entire);
  }

  /***************************************** Custom functionality ************************************/
  public inputFormatter(item: PhoneCountryCode | string) {
    if (item instanceof PhoneCountryCode) {
      return item.toPrefix();
    }
    return item;
  }

  public resultFormatter(item: any) {
    if (item instanceof Model) {
      return item.toString();
    }
    return item;
  }

  /***************************************** Control Value Accessor ************************************/
  public registerOnChange(fn: (value: any) => any) {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => any) {
    this._onTouched = fn;
  }

  public writeValue(value: string) {
    value = value ? (value + '') : '';
    value = value[0] == '+' ? value.slice(1) : value;
    this.prefix = this.service.getPrefix(value);
    if (this.prefix) {
      this.phone = value.replace(this.prefix.number + '', '');
    } else {
      this.phone = value;
    }
    this.value = '+' + value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private statusChange() {
    this.isInvalid = this.ngControl.dirty && this.ngControl.invalid;
    this.isValid = this.ngControl.dirty && this.ngControl.valid;

    if (!this.ngControl.errors) {
      return this.errors = [];
    }

    let errors: string[] = [];

    Object.keys(this.ngControl.errors).map((key) => {
      let value = this.ngControl.errors[key],
        message = '';

      if ('string' == typeof value) {
        message = value;
      } else if (message) {
        if (key == 'minlength') {
          message = message.replace('{}', value['requiredLength']);
        }
      } else if (!message) {
        if ('boolean' == typeof value) {
          message = (key + '').charAt(0).toUpperCase() + (key + '').slice(1) + ' - ' + value;
        } else {
          message = value;
        }
      }
      errors.push(message);
    });

    this.errors = errors;
    return void 0;
  }
}
