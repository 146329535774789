import { OfferStruct, Offer, Order, FlightType, DestinationType } from '@fuel/models';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class FuelOrderOffersService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(
    order: Order | string, orderBy = 'ip_agent',
    flightType?: FlightType, destinationType?: DestinationType, uplift?: number
  ) {
    let oid = toPrimaryKey(order),
      params = { order_by: orderBy };
    if (flightType) {
      params['flight_type'] = toPrimaryKey(flightType);
    }
    if (destinationType) {
      params['destination_type'] = toPrimaryKey(destinationType);
    }
    if (uplift) {
      params['uplift'] = uplift;
    }
    return this.http.get(`${environment.URL.API}/fuel/orders/${oid}/offers/`, { params }).pipe(
      map((resp: OfferStruct[]) => {
        return resp.map((item) => Offer.create(item));
      })
    );
  }
}
