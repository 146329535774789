import { Platform, PlatformStruct } from '@auth/models/authentication/platform';
import { isArray, isBoolean, isNumber, isString } from '@evo/utils/types';
import { LocationStruct, Location } from '../location';
import { Model } from '@evo/models';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface CodesStruct {
  uuid: string;
  icao: string;
  iata: string;

  date_created: string;
  date_updated: string;

  is_verified: boolean;
  count_searches: number;

  places: string[];
  platform: PlatformStruct;
  location: LocationStruct;
}

export class Codes extends Model<CodesStruct> {

  private _codes: string[];

  constructor (
    public uuid: string,
    public icao: string,
    public iata: string,
    public dateCreated: Moment,
    public dateUpdated: Moment,
    public isVerified: boolean,
    public countSearches: number,
    public places: string[],
    public platform: Platform,
    public location: Location,
  ) {
    super();
  }

  public static create(data: CodesStruct) {
    if (!data) {
      data = {} as CodesStruct;
    }
    return new Codes(
      isString(data.uuid) ? data.uuid : null,
      isString(data.icao) ? data.icao : null,
      isString(data.iata) ? data.iata : null,
      isString(data.date_created) ? moment(data.date_created) : null,
      isString(data.date_updated) ? moment(data.date_updated) : null,
      isBoolean(data.is_verified) ? data.is_verified : false,
      isNumber(data.count_searches) ? data.count_searches : null,
      isArray(data.places) ? data.places : [],
      data.platform ? Platform.create(data.platform) : null,
      data.location ? Location.create(data.location) : null,
    );
  }

  public get codes() {
    if (!this._codes) {
      this._codes = [];
      if (this.icao) {
        this._codes.push(this.icao);
      }
      if (this.iata) {
        this._codes.push(this.iata);
      }
    }
    return this._codes;
  }

  public override toString(): string {
    return this.codes.join(' / ');
  }

  public toJSON(): CodesStruct {
    return {
      uuid: this.uuid,
      icao: this.icao,
      iata: this.iata,
      date_created: this.dateCreated ? this.dateCreated.toISOString() : null,
      date_updated: this.dateUpdated ? this.dateUpdated.toISOString() : null,
      is_verified: this.isVerified,
      count_searches: this.countSearches,
      places: this.places,
      platform: this.platform ? this.platform.toJSON() : null,
      location: this.location ? this.location.toJSON() : null,
    };
  }

  public update(data: CodesStruct): this {
    this.uuid = isString(data.uuid) ? data.uuid : this.uuid;
    this.icao = isString(data.icao) ? data.icao : this.icao;
    this.iata = isString(data.iata) ? data.iata : this.iata;
    this.dateCreated = isString(data.date_created) ? moment(data.date_created) : this.dateCreated;
    this.dateUpdated = isString(data.date_updated) ? moment(data.date_updated) : this.dateUpdated;
    this.isVerified = isBoolean(data.is_verified) ? data.is_verified : this.isVerified;
    this.countSearches = isNumber(data.count_searches) ? data.count_searches : this.countSearches;
    this.places = isArray(data.places) ? data.places : this.places;
    this.platform = data.platform ? Platform.create(data.platform) : this.platform;
    this.location = data.location ? Location.create(data.location) : this.location;
    return this;
  }
}
