import { isBoolean, isBooleanOrNull, isNull, isNumber, isNumberOrNull, isObject, } from '@evo/utils/types';
import { Department, DepartmentStruct } from '../../../../hr/models/department';
import { isString, isStringOrNull } from '@evo/utils/types';
import { EmployeeType, EmployeeTypeStruct } from '../../../../hr/models';
import { Model } from '@evo/models';


export interface GroupStruct {
  uuid: string;
  name: string;
  label: string;
  description: string;
  welcome_url: string;
  is_client_group: boolean;
  count_users: number;
  department?: DepartmentStruct;
  department_id?: string;
  type?: EmployeeTypeStruct;
  type_id?: string;
}

export class Group extends Model<GroupStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public label: string,
    public description: string,
    public welcomeUrl: string,
    public isClientGroup: boolean,
    public countUsers: number,
    public department?: Department,
    public type?: EmployeeType
  ) {
    super();
  }

  public static create(data?: GroupStruct) {
    if (!data) {
      data = {} as GroupStruct;
    }
    return new Group(
      isString(data.uuid) ? data.uuid : null,
      isString(data.name) ? data.name : null,
      isString(data.label) ? data.label : null,
      isString(data.description) ? data.description : null,
      isString(data.welcome_url) ? data.welcome_url : null,
      isBoolean(data.is_client_group) ? data.is_client_group : null,
      isNumber(data.count_users) ? data.count_users : 0,
      data.department ? Department.create(data.department) : null,
      data.type ? EmployeeType.create(data.type) : null
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): GroupStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      label: this.label,
      description: this.description,
      welcome_url: this.welcomeUrl,
      is_client_group: this.isClientGroup,
      count_users: this.countUsers,
      department_id: this.department ? this.department.uuid : null,
      type_id: this.type ? this.type.uuid : null
    };
  }

  public update(data: GroupStruct): this {
    this.uuid = isStringOrNull(data.uuid) ? data.uuid : this.uuid;
    this.name = isStringOrNull(data.name) ? data.name : this.name;
    this.label = isStringOrNull(data.label) ? data.label : this.label;
    this.description = isStringOrNull(data.description) ? data.description : this.description;
    this.welcomeUrl = isStringOrNull(data.welcome_url) ? data.welcome_url : this.welcomeUrl;
    this.department = isObject(data.department) ? Department.create(data.department) : isNull(data.department) ? null : this.department;
    this.type = isObject(data.type) ? EmployeeType.create(data.type) : isNull(data.type) ? null : this.type;
    this.isClientGroup = isBooleanOrNull(data.is_client_group) ? data.is_client_group : this.isClientGroup;
    this.countUsers = isNumberOrNull(data.count_users) ? data.count_users : this.countUsers;
    return this;
  }

}
