import { Model } from '@evo/models';

export interface CountryStruct {
  uuid: string;
  name: string;
  iso: string;
  iso_3: string;
  has_states: boolean;
}

export class Country extends Model<CountryStruct> {
  constructor (
    public uuid: string,
    public name: string,
    public iso: string,
    public iso3: string,
    public hasStates: boolean
  ) {
    super();
  }

  public static create(data: CountryStruct) {
    return new Country(
      data.uuid || null,
      data.name || '',
      data.iso || '',
      data.iso_3 || '',
      'boolean' == typeof data.has_states ? data.has_states : true
    );
  }

  public toJSON() {
    return {
      uuid: this.uuid,
      name: this.name,
      iso: this.iso,
      iso_3: this.iso3,
      has_states: this.hasStates
    };
  }

  public override toString(): string {
    return this.name;
  }

  public update(data: CountryStruct) {
    this.uuid = data.uuid || this.uuid;
    this.name = data.name || this.name;
    this.iso = data.iso || this.iso;
    this.iso3 = data.iso_3 || this.iso3;
    this.hasStates = 'boolean' == typeof data.has_states ? data.has_states : this.hasStates;
    return this;
  }
}
