import { Count, CountStruct } from '@common/models';
import { Model } from '@evo/models';

export class WakeTurbulenceCategoryStruct {
  uuid: string;
  name: string;
  code: string;
  min_mtow: CountStruct;
  max_mtow: CountStruct;
}

export class WakeTurbulenceCategory extends Model<WakeTurbulenceCategoryStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public code: string,
    public minMTOW: Count,
    public maxMTOW: Count
  ) {
    super();
  }

  public static create(data?: WakeTurbulenceCategoryStruct) {
    if (!data) {
      data = {} as WakeTurbulenceCategoryStruct;
    }
    return new WakeTurbulenceCategory(
      data.uuid || null,
      data.name || null,
      data.code || null,
      data.min_mtow ? Count.create(data.min_mtow) : null,
      data.max_mtow ? Count.create(data.max_mtow) : null
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): WakeTurbulenceCategoryStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      code: this.code,
      min_mtow: this.minMTOW ? this.minMTOW.toJSON() : null,
      max_mtow: this.maxMTOW ? this.maxMTOW.toJSON() : null
    };
  }

  public update(data: WakeTurbulenceCategoryStruct): this {
    this.uuid = 'string' == typeof data.uuid ? data.uuid : this.uuid;
    this.name = 'string' == typeof data.name ? data.name : this.name;
    return this;
  }

}
