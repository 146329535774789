<ul class="sidebar-menu list-unstyled mb-0">

  <ng-container *ngIf="division.isPlatform()">
    <li class="menu-separator mt-0"><span>Platform</span></li>
    <li routerLinkActive="active"><a routerLink="/fuel/platform/dashboard"><i class="fa-solid fa-table-list"></i>Dashboard</a></li>
    <li routerLinkActive="active"><a routerLink="/fuel/platform/performance"><i class="fa-solid fa-arrow-trend-up"></i>Performance</a></li>
  </ng-container>

  <ng-container *ngIf="division.isClient()">
    <li class="menu-separator mt-0"><span>Fuel Web</span></li>
    <li routerLinkActive="active"><a routerLink="/fuel/search"><i class="fa-solid fa-search"></i>Search</a></li>
    <li routerLinkActive="active"><a routerLink="/fuel/orders"><i class="fa-solid fa-list-ul"></i>Orders</a></li>

    <li *ngIf="user.can(INVOICE.VIEW)" routerLinkActive="active">
      <a *ngIf="!division.isDemo()" routerLink="/accounting/invoices"><i class="fa-solid fa-money-bills"></i>Invoices</a>
      <a *ngIf="division.isDemo()" routerLink="/accounting/demo/invoices"><i class="fa-solid fa-money-bills"></i>Invoices</a>
    </li>

    <li routerLinkActive="active"><a routerLink="/aviation/aircraft"><i class="fa-solid fa-plane-up"></i>Aircraft</a></li>
    <li routerLinkActive="active"><a routerLink="/fuel/analytics/reports"><i class="fa-solid fa-chart-bar"></i>Analytics</a></li>
    <li><a [href]="division.pricesFile" target="_blank"><i class="fa-solid fa-download"></i>Price CSV</a></li>
    <li routerLinkActive="active"><a routerLink="/settings/integrations"><i class="fa-solid fa-link"></i>Integrations</a></li>
  </ng-container>

  <li class="menu-separator"><span>EVO Apps</span></li>
  <li><a href="https://www.flight-compass.com/" target="_blank">Flight Compass</a></li>
  <li><a href="https://briefme.aero/handling-estimate" target="_blank">Airport Fee Estimator</a></li>
  <li><a href="https://briefme.aero/" target="_blank">Airport Briefings</a></li>
  <li><a href="https://flyevo.com/airport-briefing-app" target="_blank">3D Airport Briefing</a></li>
  <li><a href="https://notams.evo-jet.com/" target="_blank">NOTAMizer</a></li>

  <li class="menu-separator"><span>24 / 7 Fuel</span></li>
  <!--
  <li class="agent">
      <img class="agent-img img-circle" src="../../../../../assets/img/agent.jpg">
      <p class="my-2">Peter is Online</p>
      <a href="#" class="btn btn-warning m-auto btn-sm text-primary">Chat with OPS Team</a>
  </li>
  -->
  <li><a href="tel:+14154046133">
      <i class="fa-solid fa-phone"></i>
      <span class="small">+1 415 404 6133</span>
    </a></li>
  <li><a href="mailto:orderfuel&#64;flyevo.com">
      <i class="fa-solid fa-at"></i>
      <span class="small">orderfuel&#64;flyevo.com</span>
    </a></li>
</ul>