import { Pagination, PaginationStruct } from '@common/models';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { SearchStruct, Search } from '../../../models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Location, LocationStruct } from '@geodata/models';
import { DivisionShortcut, DivisionShortcutStruct, Person, PersonStruct, Platform, PlatformStruct } from '@auth/models';

@Injectable({
  providedIn: 'root',
})
export class SearchHistoryService {

  constructor (
    private http: HttpClient,
  ) {
  }

  public fetch(params: URLSearchParams, pagination?: Pagination<SearchStruct, Search>) {
    return this.http.get(`${environment.URL.API}/fuel/admin/search-history/?${params.toString()}`).pipe(
      map((data: PaginationStruct<SearchStruct>) => {
        return pagination ? pagination.update(data) : Pagination.create<SearchStruct, Search>(data, Search);
      })
    );
  }

  public get(search: Search | string) {
    let uuid = toPrimaryKey(search);
    return this.http.get(`${environment.URL.API}/fuel/admin/search-history/${uuid}/`).pipe(
      map((data: SearchStruct) => {
        return Search.create(data);
      }),
    );
  }

  public searchLocation(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/search-history/locations/?${params.toString()}`).pipe(
      map((data: LocationStruct[]) => {
        return data.map((l) => Location.create(l));
      })
    );
  }

  public searchPlatform(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/search-history/platforms/?${params.toString()}`).pipe(
      map((data: PlatformStruct[]) => {
        return data.map((p) => Platform.create(p));
      })
    );
  }

  public searchDivision(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/search-history/divisions/?${params.toString()}`).pipe(
      map((data: DivisionShortcutStruct[]) => {
        return data.map((d) => DivisionShortcut.create(d));
      })
    );
  }

  public searchUser(params: URLSearchParams) {
    return this.http.get(`${environment.URL.API}/fuel/admin/search-history/users/?${params.toString()}`).pipe(
      map((data: PersonStruct[]) => {
        return data.map((p) => Person.create(p));
      })
    );
  }
}
