import { Month, MonthStruct } from '@common/models/month/month';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MonthsService {

  constructor (private http: HttpClient) {
  }

  public fetch(page = 1, term = '') {
    let params = term ? { term } : {};
    if (page) {
      params['page'] = page;
    }
    return this.http.get(`${environment.URL.API}/common/months/`).pipe(
      map((resp: MonthStruct) => {
        return Month.create(resp);
      }),
    );
  }

  public get(month: string | Month) {
    let mid = toPrimaryKey(month);
    return this.http.get(`${environment.URL.API}/common/months/${mid}/`).pipe(
      map((resp: MonthStruct) => {
        return Month.create(resp);
      }),
    );
  }

  public current() {
    return this.http.get(`${environment.URL.API}/common/months/current/`).pipe(
      map((resp: MonthStruct) => {
        return Month.create(resp);
      }),
    );
  }

  public previous() {
    return this.http.get(`${environment.URL.API}/common/months/previous/`).pipe(
      map((resp: MonthStruct) => {
        return Month.create(resp);
      }),
    );
  }

  public search(term = '') {
    let params = term ? { term } : {};
    return this.http.get(`${environment.URL.API}/common/months/search/`, { params }).pipe(
      map((resp: MonthStruct[]) => {
        return resp.map((m) => Month.create(m));
      }),
    );
  }

  public analyticsSearch(term = '') {
    let params = term ? { term } : {};
    return this.http.get(`${environment.URL.API}/common/months/analytics/search/`, { params }).pipe(
      map((resp: MonthStruct[]) => {
        return resp.map((m) => Month.create(m));
      }),
    );
  }
}
