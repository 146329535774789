import { CODES_PERMISSIONS, COUNTRY_PERMISSIONS, LOCATION_PERMISSIONS } from '@geodata/permissions';
import { DEPARTMENT_PERMISSIONS, EMPLOYEE_PERMISSIONS, FACTOR_PERMISSIONS } from '@hr/permissions';
import { ANALYTICS_PERMISSIONS, ORDER_PERMISSIONS, SEARCH_PERMISSIONS } from '@fuel/permissions';
import { COMPANY_PERMISSIONS, GROUP_PERMISSIONS, USER_PERMISSIONS } from '@auth/permissions';
import { INVOICE_PERMISSIONS, SALES_INVOICE_PERMISSIONS } from '@accounting/permissions';
import { CALL_PERMISSIONS } from '@vonage/permissions';
import { Component, OnInit } from '@angular/core';
import { User } from '@auth/models';

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['./sidebar-admin.component.scss']
})
export class SidebarAdminComponent implements OnInit {

  // FUEL
  public ORDER = ORDER_PERMISSIONS;
  public SEARCH = SEARCH_PERMISSIONS;
  public ANALYTICS = ANALYTICS_PERMISSIONS;

  //ACCOUNTING
  public INVOICE = INVOICE_PERMISSIONS;
  public SALES_INVOICE = SALES_INVOICE_PERMISSIONS;

  // HR | AUTH
  public EMPLOYEE = EMPLOYEE_PERMISSIONS;
  public DEPARTMENT = DEPARTMENT_PERMISSIONS;
  public FACTORS = FACTOR_PERMISSIONS;
  public GROUP = GROUP_PERMISSIONS;
  public USER = USER_PERMISSIONS;
  public COMPANY = COMPANY_PERMISSIONS;

  // VONAGE
  public CALL = CALL_PERMISSIONS;

  // GEODATA
  public CODES = CODES_PERMISSIONS;
  public LOCATION = LOCATION_PERMISSIONS;
  public COUNTRY = COUNTRY_PERMISSIONS;

  constructor (
    public user: User
  ) {
  }

  public ngOnInit(): void {
  }

}
