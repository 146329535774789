import { DatetimeType } from './datetime-type';
import { Collection } from '@evo/models';

export class DatetimeTypesCollection extends Collection<DatetimeType> {

  static defaultCollection: DatetimeTypesCollection;

  public static createDefault() {
    if (!DatetimeTypesCollection.defaultCollection) {
      DatetimeTypesCollection.defaultCollection = new DatetimeTypesCollection([{
        name: 'Local', value: false,
      }, {
        name: 'UTC', value: true,
      }].map((item) => DatetimeType.create(item)));
    }
    return DatetimeTypesCollection.defaultCollection;
  }
}
