import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class NavigatorService {

  private url: URL;

  constructor (
    private location: Location
  ) {
    this.location.onUrlChange((url, state) => {
      this.url = new URL(this.location.path(), window.location.origin);
    });
    this.url = new URL(this.location.path(), window.location.origin);
  }

  public static searchParamsToDict(params: URLSearchParams) {
    let result = {};
    params.forEach((v, k) => {
      result[k] = v;
    });
    return result;
  }

  public go(path: string, query?: string | URLSearchParams, state?: any) {
    query = query ? query.toString() : null;
    return this.location.go(path, query, state);
  }

  public get URL() {
    return this.url;
  }

  public queryParams() {
    let params = this.url.searchParams;
    return params ? NavigatorService.searchParamsToDict(this.url.searchParams) : null;
  }

}

