import {Component, OnInit} from '@angular/core';
import {LayoutService} from '../../../services';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(
    public layout: LayoutService
  ) { }

  public ngOnInit(): void {

  }

}
