import { isString } from '@evo/utils/types';
import { Model } from '@evo/models';
import { Params } from '@angular/router';

export type LinkType = any[] | string | null | undefined;

export interface LinkStruct {
  name: string;
  route: LinkType;
  exact: boolean;
  disabled: boolean;
  params: Params | URLSearchParams;
}

export class Link extends Model<LinkStruct> {

  public uuid: string;

  constructor (
    public name: string,
    public route: LinkType,
    public exact: boolean,
    public disabled: boolean,
    public params: Params,
  ) {
    super();
    this.uuid = Link.primaryKey(this);
  }

  private static primaryKey(link: Link) {
    return [link.name].toString();
  }

  public static create(data: string | LinkStruct, route: LinkType = '', exact = false, disabled = false, params?: Params | URLSearchParams) {
    if (isString(data)) {
      return new Link(data, route, exact, disabled, Link.params(params));
    }
    return new Link(data.name || null, data.route || null, data.exact, data.disabled, Link.params(data.params));
  }

  private static params(params?: Params | URLSearchParams) {
    if (!params) {
      return null;
    }
    if (params instanceof URLSearchParams) {
      let dict: Params = {};
      params.forEach((v, k) => dict[k] = v);
      return dict;
    }
    return params;
  }

  public toJSON(): LinkStruct {
    return {
      name: this.name,
      route: this.route,
      exact: this.exact,
      disabled: this.disabled,
      params: this.params
    };
  }

  public update(data: Partial<LinkStruct>): this {
    this.params = data.params ? Link.params(data.params) : this.params;
    return this;
  }

}
