import { EventsService, LayoutService, ToastService } from '../../../../../services';
import { BatchesService } from '../../../../../../fuel/services/client';
import { observer } from '@evo/utils/libs/rxjs';
import { Division, User } from '@auth/models';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Batch } from '../../../../../../fuel/models';
import { merge, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-navbar-client',
  templateUrl: './navbar-client.component.html',
  styleUrls: ['./navbar-client.component.scss']
})
export class NavbarClientComponent implements OnInit, OnDestroy {

  public batch: Batch;
  private subs: Subscription[];

  constructor (
    public user: User,
    public division: Division,
    public layout: LayoutService,
    private service: BatchesService,
    private events: EventsService,
    private toast: ToastService
  ) {
  }

  public ngOnInit(): void {
    if (this.division.isClient()) {
      this.subs = [
        merge(
          this.events.cart.initialize,
          this.events.cart.add,
          this.events.cart.update,
          this.events.cart.remove,
          this.events.cart.submit,
          this.events.cart.clear
        ).pipe(
          switchMap(() => {
            return this.service.cart();
          })
        ).subscribe(observer((batch) => {
          this.batch = batch;
        }, (e) => {
          this.toast.error(e);
        }))
      ];
      this.events.cart.initialize.next(true);
    } else {
      this.subs = [];
    }
  }

  public ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
