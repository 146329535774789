import { Injectable } from '@angular/core';
import { isNull, isUndefined } from '@evo/utils/types';


@Injectable({ providedIn: 'root' })
export class LocalStorageService {

  constructor () {
  }

  public setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(key: string, def?: any) {
    let item = localStorage.getItem(key);
    return isNull(item) ? isUndefined(def) ? null : def : JSON.parse(item);
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
