import { isBoolean, isNull, isNumber, isObject, isString } from '@evo/utils/types';
import { EmployeeType, EmployeeTypeStruct } from '../employee-type';
import { Department, DepartmentStruct } from '../department';
import { Model } from '@evo/models';

export interface FactorStruct {
  uuid: string;
  name: string;

  weight: number;
  description: string;

  type: EmployeeTypeStruct;
  department: DepartmentStruct;
  is_deleted: boolean;
}

export class Factor extends Model<FactorStruct> {

  constructor (
    public uuid: string,
    public name: string,
    public weight: number,
    public description: string,
    public type?: EmployeeType,
    public department?: Department,
    public isDeleted = false
  ) {
    super();
  }

  public static create(data?: FactorStruct) {
    if (!data) {
      data = {} as any;
    }
    return new Factor(
      isString(data.uuid) ? data.uuid : null,
      isString(data.name) ? data.name : null,
      isNumber(data.weight) ? data.weight : null,
      isString(data.description) ? data.description : null,
      isObject(data.type) ? EmployeeType.create(data.type) : null,
      isObject(data.department) ? Department.create(data.department) : null,
      isBoolean(data.is_deleted) ? data.is_deleted : false
    );
  }

  public override toString(): string {
    return this.name;
  }

  public toJSON(): FactorStruct {
    return {
      uuid: this.uuid,
      name: this.name,
      type: this.type,
      description: this.description,
      weight: this.weight,
      department: this.department ? this.department.toJSON() : null,
      is_deleted: this.isDeleted
    };
  }

  public update(data: Partial<FactorStruct>): this {
    this.uuid = isString(data.uuid) ? data.uuid : isNull(data.uuid) ? null : this.uuid;
    this.name = isString(data.name) ? data.name : isNull(data.name) ? null : this.name;
    this.weight = isNumber(data.weight) ? data.weight : isNull(data.weight) ? null : this.weight;
    this.description = isString(data.description) ? data.description : isNull(data.description) ? null : this.description;
    this.type = isObject(data.type) ? EmployeeType.create(data.type) : isNull(data.type) ? null : this.type;
    this.department = isObject(data.department) ? Department.create(data.department) : isNull(data.department) ? null : this.department;
    this.isDeleted = isBoolean(data.is_deleted) ? data.is_deleted : isNull(data.is_deleted) ? false : this.isDeleted;
    return this;
  }

  public override clone(): Factor {
    return new Factor(
      this.uuid, this.name, this.weight, this.description,
      this.type, this.department, this.isDeleted
    );
  }
}
