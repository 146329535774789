import { APP_INITIALIZER, FactoryProvider, Injector } from '@angular/core';
import { User, Division, Company } from '@auth/models';
import { ToastService, JwtService } from '../../services';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { of } from 'rxjs';

export const ROUTES: string[] = [
  '/access/sign-in', '/access/password-reset', '/access/registration', '/access/password-reset-confirm',
];

export const ALL_ROUTES = ROUTES.concat([
  '/dev/review-order', '/fuel/fbo-confirm'
]);

export function checkUrl(url, routes) {
  for (let i = 0; i < routes.length; i++) {
    let route = routes[i],
      len = (route + '').length,
      uri = (url + '').slice(0, len);

    if (route == uri) {
      return true;
    }
  }
  return false;
}

export function loadInitialSettings(
  user: User, division: Division, company: Company,
  jwt: JwtService, toast: ToastService, injector: Injector
) {
  return () => {
    return jwt.verifyToken()
      .pipe(
        catchError((e) => {
          return of(e);
        }),
        map((data) => {

          let router = injector.get(Router);
          router.initialNavigation();

          let navigation = router.getCurrentNavigation(),
            url = navigation.extractedUrl.toString();

          if (data instanceof HttpErrorResponse) {
            if (data.status >= 500) {
              toast.error(data);
            } else {
              jwt.destroyToken();
              user.clear();
              division.clear();
              company.clear();
              url = url.split('/').slice(0, 3).join('/');
              if (!checkUrl(url, ALL_ROUTES)) {
                router.navigateByUrl('/access/sign-in');
              }
            }
          } else {
            user.update(data['user']);
            division.update(data['division']);
            company.update(data['company']);
            if (checkUrl(url, ROUTES)) {
              if (division.isSuperUser() || division.isSuperUser()) {
                router.navigateByUrl('/fuel/admin/analytics/dashboard');
              } else if (division.isPlatform()) {
                this.router.navigateByUrl('/fuel/platform/performance');
              } else {
                router.navigateByUrl('/fuel/search');
              }
            }
          }
          return user;
        }),
      ).toPromise();
  };
}

export const AppInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: loadInitialSettings,
  deps: [
    User, Division, Company,
    JwtService, ToastService, Injector
  ],
  multi: true,
};
