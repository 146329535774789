export enum CODES_PERMISSIONS {
  CREATE = 'geodata.add_codes',
  UPDATE = 'geodata.change_codes',
  DELETE = 'geodata.delete_codes',
  VIEW = 'geodata.view_codes'
}

export enum LOCATION_PERMISSIONS {
  CREATE = 'geodata.add_location',
  UPDATE = 'geodata.change_location',
  DELETE = 'geodata.delete_location',
  VIEW = 'geodata.view_location'
}

export enum COUNTRY_PERMISSIONS {
  CREATE = 'geodata.add_country',
  UPDATE = 'geodata.change_country',
  DELETE = 'geodata.delete_country',
  VIEW = 'geodata.view_country'
}

export enum STATE_PERMISSIONS {
  CREATE = 'geodata.add_state',
  UPDATE = 'geodata.change_state',
  DELETE = 'geodata.delete_state',
  VIEW = 'geodata.view_state'
}