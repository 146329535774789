import { Collection } from '@evo/models';
import { State, StateStruct } from './state';

export class StatesCollection extends Collection<State> {

  public static create(data: StateStruct[]) {
    return new StatesCollection(data.map((item) => State.create(item)));
  }

  public search(term: string) {
    term = 'string' == typeof term ? term : term ? (term + '') : '';
    term = term.toLowerCase().trim();
    return term ? this.items.filter((state) => {
      return (state.name.toLowerCase().indexOf(term) > -1)
        || (state.iso.toLowerCase().indexOf(term) > -1)
        || (state.hasc.toLowerCase().indexOf(term) > -1)
        || (state.fips.toLowerCase().indexOf(term) > -1);
    }).slice(0, 10) : this.items.slice(0, 10);
  }
}
