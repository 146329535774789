<div ngbDropdown autoClose="outside" #dropdown="ngbDropdown">
  <button class="btn btn-outline-secondary"
          [class.btn-sm]="size=='sm'"
          [class.btn-lg]="size=='lg'"
          id="dropdownRanges"
          ngbDropdownToggle #toogle
          [title]="title">
    <span class="truncate">
    <i class="fa fa-calendar"></i>&nbsp;{{title}}
    </span>
  </button>

  <div ngbDropdownMenu class="p-0 rounded-sm">
    <div class="d-flex flex-row">
      <div class="ranges">
        <button *ngFor="let r of ranges;" ngbDropdownItem type="button" [class.active]="r == range && !showDatePicker"
                (click)="select($event, r)">
          {{r.name}}
        </button>

        <button ngbDropdownItem type="button" class="d-none d-md-block" [class.active]="showDatePicker" (click)="openDatePicker($event)">
          Custom Range
        </button>
      </div>

      <div *ngIf="showDatePicker">
        <ngb-datepicker #datepicker [startDate]="fromDate" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                        [dayTemplate]="t" outsideDays="hidden" class="rounded-0 border-end-0 border-y-0">
        </ngb-datepicker>

        <ng-template #t let-date let-focused="focused">
          <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>

  </div>

</div>
