import { DestinationType, FlightType, SearchBatch, SearchBatchStruct } from '@fuel/models';
import { environment } from '@environments/environment';
import { toPrimaryKey } from '@evo/utils/helpers';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '@main/services';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchBatchesService {

  public current: SearchBatch;
  private subscription: Subscription;

  constructor (
    private http: HttpClient,
    private events: EventsService
  ) {
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.events.logout.subscribe(() => {
      this.clearCurrentCache();
    });
  }

  public getCurrent() {
    if (this.current) {
      return of(this.current);
    }
    return this.http.get(`${environment.URL.API}/fuel/admin/search-batches/current/`).pipe(
      map((resp?: SearchBatchStruct) => {
        return resp ? SearchBatch.create(resp) : null;
      }),
      tap((searchBatch: SearchBatch) => {
        this.current = searchBatch;
      })
    );
  }

  public activateCurrent() {
    return this.http.put(`${environment.URL.API}/fuel/admin/search-batches/current/activate/`, {}).pipe(
      map((resp: SearchBatchStruct) => {
        return this.current.update(resp);
      })
    );
  }

  public setCurrentDivision(data: any) {
    return this.http.put(`${environment.URL.API}/fuel/admin/search-batches/current/division/`, data).pipe(
      map((resp: SearchBatchStruct) => {
        return this.current.update(resp);
      }),
      tap(() => {
        this.events.fuel.batchDivisionChange.next(this.current);
      })
    );
  }

  public setCurrentFlightType(type: FlightType) {
    let data = { flight_type_id: toPrimaryKey(type) };
    return this.http.put(`${environment.URL.API}/fuel/admin/search-batches/current/flight-type/`, data).pipe(
      map((resp: SearchBatchStruct) => {
        return this.current.update(resp);
      })
    );
  }

  public setCurrentDestinationType(type: DestinationType) {
    let data = { destination_type_id: toPrimaryKey(type) };
    return this.http.put(`${environment.URL.API}/fuel/admin/search-batches/current/destination-type/`, data).pipe(
      map((resp: SearchBatchStruct) => {
        return this.current.update(resp);
      })
    );
  }

  public setCurrentEmails(emails: string[]) {
    let data = { emails };
    return this.http.put(`${environment.URL.API}/fuel/admin/search-batches/current/emails/`, data).pipe(
      map((resp: SearchBatchStruct) => {
        return this.current.update(resp);
      })
    );
  }

  public removeCurrent() {
    this.clearCurrentCache();
    return this.http.delete(`${environment.URL.API}/fuel/admin/search-batches/current/`).pipe(
      tap(() => {
        this.events.cart.clear.emit();
      })
    );
  }

  public clearCurrentCache() {
    delete this.current;
  }
}
